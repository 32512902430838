export default [
    { 
        id: 101,
        url: 'astercucine',
        name: 'Aster Cucine', 
        parent: 'kitchen',
        content: {
            pics: [
                '../assets/img/pics/astercucine/astercucine_1.jpg',
                '../assets/img/pics/astercucine/astercucine_2.jpg',
                '../assets/img/pics/astercucine/astercucine_3.jpg',
                '../assets/img/pics/astercucine/astercucine_4.jpg',
                '../assets/img/pics/astercucine/astercucine_5.jpg',
                '../assets/img/pics/astercucine/astercucine_6.jpg',
                '../assets/img/pics/astercucine/astercucine_7.jpg',
                '../assets/img/pics/astercucine/astercucine_8.jpg',
                '../assets/img/pics/astercucine/astercucine_9.jpg',
                '../assets/img/pics/astercucine/astercucine_10.jpg'
            ],
            title: 'Aster Cucine – ваша идеальная итальянская кухня!',
            about: 'Итальянский бренд Aster Cucine – это кухонная мебель, имеющая особую уникальность и стиль. Изделия обладают классическими чертами с нотками оригинальности. Элитные кухни Aster Cucine пользуются широчайшей известностью во всем мире, украшая элитные особняки и принося в них нотки итальянского шика.',
            website: 'https://www.astercucine.it'
        }
    },
    { 
        id: 102,
        url: 'venetacucine',
        name: 'Veneta Cucine', 
        parent: 'kitchen',
        content: {
            pics: [
                '../assets/img/pics/venetacucine/venetacucine_1.jpg',
                '../assets/img/pics/venetacucine/venetacucine_2.jpg',
                '../assets/img/pics/venetacucine/venetacucine_3.jpg',
                '../assets/img/pics/venetacucine/venetacucine_4.jpg',
                '../assets/img/pics/venetacucine/venetacucine_5.jpg',
                '../assets/img/pics/venetacucine/venetacucine_6.jpg',
                '../assets/img/pics/venetacucine/venetacucine_7.jpg',
                '../assets/img/pics/venetacucine/venetacucine_8.jpg',
                '../assets/img/pics/venetacucine/venetacucine_9.jpg',
                '../assets/img/pics/venetacucine/venetacucine_10.jpg',
            ],
            title: 'Бренд Veneta Cucine – современная мебель премиум-класса!',
            about: 'Широко известная итальянская марка предлагает кухонную мебель в современном и классическом стилях. Это удачный символ безупречного вкуса и смеси разных культур и времен. Компания Veneta Cucine предлагает качественные стильные кухни, соблюдающие баланс между традициями и современностью. Дизайнеры сделали ставку на эксклюзивность и не прогадали.',
            website: 'https://www.venetacucine.com'
        }
    },
    { 
        id: 103,
        url: 'valcucine',
        name: 'Valcucine', 
        parent: 'kitchen',
        content: {
            pics: [
                '../assets/img/pics/valcucine/valcucine_1.jpg',
                '../assets/img/pics/valcucine/valcucine_2.jpg',
                '../assets/img/pics/valcucine/valcucine_3.jpg',
                '../assets/img/pics/valcucine/valcucine_4.jpg',
                '../assets/img/pics/valcucine/valcucine_5.jpg',
                '../assets/img/pics/valcucine/valcucine_6.jpg',
                '../assets/img/pics/valcucine/valcucine_7.jpg',
                '../assets/img/pics/valcucine/valcucine_8.jpg',
                '../assets/img/pics/valcucine/valcucine_9.jpg',
                '../assets/img/pics/valcucine/valcucine_10.jpg',
            ],
            title: 'Бренд Valcucine – совершенный эко-дизайн и новаторство на кухне!',
            about: 'Компания Valcucine свыше 30 лет будоражит мир современного дизайна и проектирования кухонной мебели. Славящиеся необычным дизайном и эргономикой, эти кухни привносят бесценный вклад в защиту окружающей среды. Ведь компания ярко демонстрирует, что экологичные решения идеально сосуществуют с отменным качеством и  новейшими технологиями.',
            website: 'http://www.valcucine.com'
        }
    },
    { 
        id: 104,
        url: 'prestige',
        name: 'Prestige', 
        parent: 'kitchen',
        content: {
            pics: [
                '../assets/img/pics/prestige/prestige_1.jpg',
                '../assets/img/pics/prestige/prestige_2.jpg',
                '../assets/img/pics/prestige/prestige_3.jpg',
                '../assets/img/pics/prestige/prestige_4.jpg',
                '../assets/img/pics/prestige/prestige_5.jpg',
                '../assets/img/pics/prestige/prestige_6.jpg',
            ],
            title: 'Prestige – яркое отражение вкуса и статуса владельца!',
            about: 'Фабрика Prestige – кухонная мебель, являющаяся символом качества и безупречного дизайна. При производстве применяется только натуральное дерево. Кухни Prestige – это шедевры от мастеров, знающих секреты старинных итальянских ремесленных школ. Мебель словно «дышит» оригинальным венецианским стилем, передающимся в изящной резьбе по дереву и плавности линий.',
            website: 'http://www.prestigemobili.com'
        }
    },
    { 
        id: 105,
        url: 'kueppersbusch',
        name: 'Kueppersbusch', 
        parent: 'kitchen',
        content: {
            pics: [
                '../assets/img/pics/kueppersbusch/kueppersbusch_1.jpg',
                '../assets/img/pics/kueppersbusch/kueppersbusch_2.jpg',
                '../assets/img/pics/kueppersbusch/kueppersbusch_3.jpg',
                '../assets/img/pics/kueppersbusch/kueppersbusch_4.jpg',
                '../assets/img/pics/kueppersbusch/kueppersbusch_5.jpg',
                '../assets/img/pics/kueppersbusch/kueppersbusch_6.jpg',
            ],
            title: 'Küppersbusch- ваша дверь в мир стильных кухонь!',
            about: 'Основные ценности этого бренда – отменный дизайн, высочайшее качество, максимальная функциональность и модные инновации. Новейшие технологии вдохновили дизайнеров фабрики на создание уникальной мебели, обладающей как красотой, так и функциональностью. Во многих моделях задействовано интересное решение «кухонный остров» со сдвигающейся столешницей.',
            website: 'https://www.kueppersbusch.ru'
        }
    },
    { 
        id: 106,
        url: 'franke',
        name: 'Franke', 
        parent: 'kitchen',
        content: {
            pics: [
                '../assets/img/pics/franke/franke_1.jpg',
                '../assets/img/pics/franke/franke_2.jpg',
                '../assets/img/pics/franke/franke_3.jpg',
                '../assets/img/pics/franke/franke_4.jpg',
                '../assets/img/pics/franke/franke_5.jpg',
                '../assets/img/pics/franke/franke_6.jpg',
                '../assets/img/pics/franke/franke_7.jpg',
                '../assets/img/pics/franke/franke_8.jpg',
                '../assets/img/pics/franke/franke_9.jpg',
                '../assets/img/pics/franke/franke_10.jpg',
            ],
            title: 'Franke – идеальные кухонные системы для дома!',
            about: 'Девиз бренда – испытывать истинное удовольствие от приготовления и употребления блюд. Абсолютно вся продукция компании – от мойки до кухонной вытяжки – сразу привносит неповторимый итальянский стиль  и создает максимальный комфорт на кухне. Make It Wonderful – давайте вместе  создавать удивительное!',
            website: 'https://www.franke.com'
        }
    }
]