export default [
    { 
        id: 201, 
        url: 'londonart',
        name: 'London Art', 
        parent: 'decor-and-accessories',
        content: {
            pics: [
                '../assets/img/pics/londonart/londonart_1.jpg',
                '../assets/img/pics/londonart/londonart_2.jpg',
                '../assets/img/pics/londonart/londonart_3.jpg',
                '../assets/img/pics/londonart/londonart_4.jpg',
                '../assets/img/pics/londonart/londonart_5.jpg',
                '../assets/img/pics/londonart/londonart_6.jpg',
            ],
            title: 'London Art – материалы, диктующие новое восприятие пространства!',
            about: 'Декоративные материалы для отделки помещений должны сочетать в себе эстетику и функциональность. Именно таким параметрам и отвечает продукция бренда London Art. Эксклюзивные модели обоев в авторском дизайне, в том числе – выполненные на заказ – станут изысканным украшением любого интерьера.',
            website: 'https://www.londonart.it'
        }
    },
    { 
        id: 202, 
        url: 'walldeco',
        name: 'Wall & Deco', 
        parent: 'decor-and-accessories',
        content: {
            pics: [
                '../assets/img/pics/walldeco/walldeco_1.jpg',
                '../assets/img/pics/walldeco/walldeco_2.jpg',
                '../assets/img/pics/walldeco/walldeco_3.jpg',
                '../assets/img/pics/walldeco/walldeco_4.jpg',
                '../assets/img/pics/walldeco/walldeco_5.jpg',
                '../assets/img/pics/walldeco/walldeco_6.jpg',
            ],
            title: 'Wall & Deco – эксклюзивные решения для оформления интерьера!',
            about: 'Продукция этого бренда в корне меняет устаревшие представления об обоях, как о привычном и скучном. Дизайнеры умело балансируют между четким планированием и эмоциональностью, между лаконичностью своих решений и фотографическим реализмом, предлагая уникальные проекты с ярко-выраженным характером.',
            website: 'http://www.wallanddeco.com'
        }
    },
    { 
        id: 203, 
        url: 'loloey',
        name: 'Loloey', 
        parent: 'decor-and-accessories',
        content: {
            pics: [
                '../assets/img/pics/loloey/loloey_1.jpg',
                '../assets/img/pics/loloey/loloey_2.jpg',
            ],
            video: [
                '/assets/img/pics/loloey/loloey.mp4'
            ],
            title: 'Творения Loloey устанавливают тон и поднимают настроение в домах по всему миру!',
            about: 'С 1963 года ковры Loloey радуют энтузиастов итальянского дизайна, которые ищут индивидуальные творения и высочайшее мастерство. Огромное разнообразие технологий и материалов, имеющихся в нашем распоряжении, позволяет нам поставлять целый набор ковровых покрытий или решений для монтажа от стены до стены, специально разработанных для адаптации к их окружению, а также для легкой работы с жилыми помещениями и подрядных проектов.',
            website: 'https://www.loloey.com'
        }
    },
    { 
        id: 204, 
        url: 'mohebban',
        name: 'Mohebban', 
        parent: 'decor-and-accessories',
        content: {
            pics: [
                '../assets/img/pics/mohebban/mohebban_1.jpg',
            ],
            title: 'Ковры Mohebban - это более 200000 узлов на каждом квадратном метре переплетения, гармонично связывающие нити прошлого с нитями настоящего и будущего!',
            about: 'Контраст между тысячелетней техникой и современным дизайном и узорами придает каждому ковру Mohebban уникальную эстетику и глубокий символический смысл. Внутренняя ценность каждого ковра никогда не отделяется от истории, лежащей в основе его производства. Путешествие начинается с выбора и обучения лучших мастеров ковров и продолжается с использованием материалов высшего качества (шерсть, шелк или растительные волокна), пряжи, крашения и ручного ткачества ковров. заключительные этапы стрижки, обрезки, стирки и сушки: каждый ковер, изготовленный таким образом, уникален, с индивидуальностью и историей, отличными от всех остальных.',
            website: 'https://www.mohebbanmilano.com'
        }
    },
    { 
        id: 205, 
        url: 'eichholtz',
        name: 'Eichholtz', 
        parent: 'decor-and-accessories',
        content: {
            pics: [
                '../assets/img/pics/eichholtz/eichholtz_1.jpg',
                '../assets/img/pics/eichholtz/eichholtz_2.jpg',
                '../assets/img/pics/eichholtz/eichholtz_3.jpg',
                '../assets/img/pics/eichholtz/eichholtz_4.jpg',
            ],
            title: 'Eichholtz - роскошь в аксессуарах!',
            about: 'Сегодня бренд Eichholtz - это воплощение роскошного образа жизни. Который постоянно стремится вдохновлять индустрию дизайна интерьеров обширной коллекцией, включающей множество знаковых стилей. Eichholtz известен как лучшее место, где можно купить роскошные предметы интерьера.',
            website: 'https://www.eichholtz.com'
        }
    },
    { 
        id: 206, 
        url: 'ibiagi',
        name: 'IBIAGI', 
        parent: 'decor-and-accessories',
        content: {
            pics: [
                '../assets/img/pics/ibiagi/ibiagi_1.jpg',
            ],
            title: 'Коллекции и аксессуары I BIAGI отражают ценность деталей, элегантность и эксклюзивность музейных произведений искусства',
            about: 'Коллекции I BIAGI отличаются вниманием к деталям и отделке, которые обогащают каждый продукт уникальностью. Эта уникальность является результатом знаний и навыков опытных мастеров, которые подписывают каждое изделие, всегда создавая оригинальное и эксклюзивное сочетание бронзовых элементов и украшений из хрусталя ручной работы. Сегодня компания I BIAGI достигла третьего поколения, работающего со всеми видами драгоценных материалов.',
            website: 'https://www.ibiagi.it'
        }
    },
    { 
        id: 207, 
        url: 'muratto',
        name: 'Muratto', 
        parent: 'decor-and-accessories',
        content: {
            pics: [
                '../assets/img/pics/muratto/muratto_1.jpg',
                '../assets/img/pics/muratto/muratto_2.jpg',
                '../assets/img/pics/muratto/muratto_3.jpg'
            ],
            title: 'Muratto - инновационный бренд по разработке и производстве настенных панелей.',
            about: 'MURATTO один из известных брендов португальского происхождения с натуральным дизайном поверхностей. С профилем, отличающимся динамизмом и новаторством, компания появилась в 2013 году с целью предложить великолепные решения для покрытий. MURATTO руководствуется видением, которое фокусируется на устойчивом росте, опираясь на четыре основные ценности: естественный образ жизни, дизайн, совместное использование и комфорт интерьера.',
            website: 'https://www.muratto.com'
        }
    },
    { 
        id: 208, 
        url: 'studioart',
        name: 'Studioart', 
        parent: 'decor-and-accessories',
        content: {
            pics: [
                '../assets/img/pics/studioart/studioart_1.jpg',
                '../assets/img/pics/studioart/studioart_2.jpg',
                '../assets/img/pics/studioart/studioart_3.jpg',
            ],
            title: 'Кожанные покрытия Studioart выходят за рамки мира моды и становятся главным действующим лицом в дизайне интерьеров',
            about: 'Широкий выбор кожи и настенных покрытий, а также максимальная осторожность при доставке высококачественной продукции сделали Studioart одним из ключевых игроков в индустрии внутренней отделки. Все виды кожи Studioart производятся из высококачественного европейского сырья, которое полностью дубится и обрабатывается в Италии с учетом защиты окружающей среды.',
            website: 'https://www.studioart.it'
        }
    },
    { 
        id: 209, 
        url: 'dedar',
        name: 'Dedar',
        parent: 'decor-and-accessories',
        content: {
            pics: [
                '../assets/img/pics/dedar/dedar_1.jpg',
            ],
            title: 'Ткани от Dedar отличаются привлекательной цветовой гаммой и оригинальным рисунком',
            about: 'Семейная компания Dedar, основанная в 1976 году, создает современные коллекции оригинальных и стильных тканей. Расположенная в центре промышленного района недалеко от Комо, компания Dedar охотно экспериментирует и применяет новые технологии, чтобы изготавливать продукцию высочайшего качества, благодаря постоянной обратной связи с искусными мастерами и специалистами в сфере текстиля, которые в совершенстве владеют методами производства превосходных тканей.',
            website: 'https://dedar.com'
        }
    },
    { 
        id: 210, 
        url: 'bubenzorweg',
        name: 'Buben & Zorweg', 
        parent: 'decor-and-accessories',
        content: {
            pics: [
                '../assets/img/pics/bubenzorweg/bubenzorweg_1.jpg',
                '../assets/img/pics/bubenzorweg/bubenzorweg_2.jpg',
                '../assets/img/pics/bubenzorweg/bubenzorweg_3.jpg',
                '../assets/img/pics/bubenzorweg/bubenzorweg_4.jpg',
            ],
            title: 'Buben & Zorweg Восхищение на протяжении всей жизни',
            about: 'Каждый шедевр Buben & Zorweg обладает редким даром: наши прекрасные и функциональные изделия не только защищают ценное содержимое, но и увеличивает радость от его обладания и демонстрации из года в год.',
            website: 'https://www.buben-zorweg.com'
        }
    }
]