export default class Brand {
  constructor(id, url, name, parent, content) {
    this.id = id;
    this.url = url;
    this.name = name;
    this.parent = parent;
    this.content = content;
  }
  static createFrom(data) {
    const {id, url, name, parent, content} = data;
    return new this(id, url, name, parent, content);
  }
}