<template>
  <div>
    <ListItems :items="items" class="list_brands">
      <template v-slot:default="props">
        <ArticleItem :item="props.item"></ArticleItem>
      </template>
      <template v-slot:empty>
        New brands are creating...
      </template>
    </ListItems>
  </div>
</template>

<script>
import ListItems from '@/components/ListItems.vue'
import ArticleItem from '@/components/ArticleItem.vue'
export default {
  name: 'ArticleItems',
  components: {
    ArticleItem,
    ListItems,
  },
  extends: ListItems
}
</script>
<style scoped>
.list_brands {
  display: block;
}
</style>