export default [
    { 
        id: 76,
        url: 'baxter',
        name: 'Baxter', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/baxter/baxter_1.jpg',
                '../assets/img/pics/baxter/baxter_2.jpg',
                '../assets/img/pics/baxter/baxter_3.jpg',
                '../assets/img/pics/baxter/baxter_4.jpg',
                '../assets/img/pics/baxter/baxter_5.jpg',
                '../assets/img/pics/baxter/baxter_6.jpg',
                '../assets/img/pics/baxter/baxter_7.jpg',
                '../assets/img/pics/baxter/baxter_8.jpg',
            ],
            title: 'Baxter – шикарная итальянская мебель для изысканной публики',
            about: 'Данный бренд с мировым именем был основан в 1990 год. Его выгодно отличает свое особое видение мебельной моды, эксклюзивные разработки и неординарный подход к созданию продукции. Хотя Baxter и является довольно молодым, мебель заслуженно пользуется огромным спросом у многих знаменитостей. ',
            website: 'https://baxter.it'
        }
    },
    { 
        id: 77,
        url: 'pregno',
        name: 'Pregno', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/pregno/pregno_1.jpg',
                '../assets/img/pics/pregno/pregno_2.jpg',
                '../assets/img/pics/pregno/pregno_3.jpg',
                '../assets/img/pics/pregno/pregno_4.jpg',
                '../assets/img/pics/pregno/pregno_5.jpg',
                '../assets/img/pics/pregno/pregno_6.jpg',
                '../assets/img/pics/pregno/pregno_7.jpg',
                '../assets/img/pics/pregno/pregno_8.jpg',
                '../assets/img/pics/pregno/pregno_9.jpg',
            ],
            title: 'Pregno - сглаженные линии и инновационный дизайн',
            about: 'Итальянский бренд Pregno – всемирно известный синоним высочайшего креативности и стиля. Главная ценность – душа в каждом мебельном изделии. Pregno ставит клиента в центр абсолютно на каждом этапе разработки каждого проекта.',
            website: 'http://www.pregno.com'
        }
    },
    { 
        id: 78,
        url: 'arketipo',
        name: 'Arketipo', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/arketipo/arketipo_1.jpg',
                '../assets/img/pics/arketipo/arketipo_2.jpg',
                '../assets/img/pics/arketipo/arketipo_3.jpg',
                '../assets/img/pics/arketipo/arketipo_4.jpg',
                '../assets/img/pics/arketipo/arketipo_5.jpg',
                '../assets/img/pics/arketipo/arketipo_6.jpg',
                '../assets/img/pics/arketipo/arketipo_7.jpg',
                '../assets/img/pics/arketipo/arketipo_8.jpg',
                '../assets/img/pics/arketipo/arketipo_9.jpg',
            ],
            title: 'Arketipo – лучшее для лучших',
            about: 'Удачно превращать смелые идеи в уникальные мебельные объекты. Там, где иные увидят лишь дерево и ткань, профессионалы Arketipo видят будущий шедевр.',
            website: 'http://www.arketipo.com'
        }
    },
    { 
        id: 79,
        url: 'cattelanitalia',
        name: 'Cattelan Italia', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/cattelanitalia/cattelanitalia_1.jpg',
                '../assets/img/pics/cattelanitalia/cattelanitalia_2.jpg',
                '../assets/img/pics/cattelanitalia/cattelanitalia_3.jpg',
                '../assets/img/pics/cattelanitalia/cattelanitalia_4.jpg',
                '../assets/img/pics/cattelanitalia/cattelanitalia_5.jpg',
                '../assets/img/pics/cattelanitalia/cattelanitalia_6.jpg',
                '../assets/img/pics/cattelanitalia/cattelanitalia_7.jpg',
                '../assets/img/pics/cattelanitalia/cattelanitalia_8.jpg',
                '../assets/img/pics/cattelanitalia/cattelanitalia_9.jpg',
                '../assets/img/pics/cattelanitalia/cattelanitalia_10.jpg',
            ],
            title: 'Cattelan Italia – микс коллекция шика, элегантности и роскоши',
            about: 'Это высшее качество и комфорт. Самые ценные породы дерева, изысканное стекло натуральная кожа – все это умело обрабатывается мастерами компании. Данная мебель удачно воплощает нотки современности и классики.',
            website: 'https://www.cattelanitalia.com'
        }
    },
    { 
        id: 80,
        url: 'agostini',
        name: 'Agostini', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/agostini/agostini_1.jpg',
                '../assets/img/pics/agostini/agostini_2.jpg',
                '../assets/img/pics/agostini/agostini_3.jpg',
                '../assets/img/pics/agostini/agostini_4.jpg',
                '../assets/img/pics/agostini/agostini_5.jpg',
                '../assets/img/pics/agostini/agostini_6.jpg',
            ],
            title: '',
            about: '',
            website: 'https://www.agostinihome.com'
        }
    },
    { 
        id: 81,
        url: 'ditreitalia',
        name: 'Ditre Italia', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/ditreitalia/ditreitalia_1.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_2.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_3.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_4.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_5.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_6.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_7.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_8.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_9.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_10.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_11.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_12.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_13.jpg',
                '../assets/img/pics/ditreitalia/ditreitalia_14.jpg',
            ],
            title: 'Ditre Italia – инновационные решения в мире мебели',
            about: 'Мебель бренда Ditre выгодно отличается аристократичным дизайном, лаконизмом и неординарным декором.',
            website: 'https://www.ditreitalia.com'
        }
    },
    { 
        id: 82,
        url: 'twils',
        name: 'Twils', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/twils/twils_1.jpg',
                '../assets/img/pics/twils/twils_2.jpg',
                '../assets/img/pics/twils/twils_3.jpg',
                '../assets/img/pics/twils/twils_4.jpg',
                '../assets/img/pics/twils/twils_5.jpg',
                '../assets/img/pics/twils/twils_6.jpg',
                '../assets/img/pics/twils/twils_7.jpg',
                '../assets/img/pics/twils/twils_8.jpg',
                '../assets/img/pics/twils/twils_9.jpg',
            ],
            title: 'TWILS – высочайшее качество и креативность',
            about: 'Мебельная коллекция бренда TWILS полностью отражает ценности и концепцию данной компании: только эксклюзивный дизайн, самые лучшие материалы, полная гармония материалов и цветовой гаммы, высочайшее мастерство ручной работы.',
            website: 'https://www.twils.it'
        }
    },
    { 
        id: 83,
        url: 'pianca',
        name: 'Pianca', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/pianca/pianca_1.jpg',
                '../assets/img/pics/pianca/pianca_2.jpg',
                '../assets/img/pics/pianca/pianca_3.jpg',
                '../assets/img/pics/pianca/pianca_4.jpg',
                '../assets/img/pics/pianca/pianca_5.jpg',
                '../assets/img/pics/pianca/pianca_6.jpg',
                '../assets/img/pics/pianca/pianca_7.jpg',
                '../assets/img/pics/pianca/pianca_8.jpg',
                '../assets/img/pics/pianca/pianca_9.jpg',
                '../assets/img/pics/pianca/pianca_10.jpg',
                '../assets/img/pics/pianca/pianca_11.jpg',
                '../assets/img/pics/pianca/pianca_12.jpg',
            ],
            title: 'Мебель Pianca – думая, любя, живя ',
            about: 'Минимализм форм, дополненный оригинальным подходом к разработке каждого изделия – это и есть известный итальянский бренд Pianca, являющийся одним из наиболее узнаваемых и успешных. В ассортименте компании представлена мебель для спальни, гостиных комнат и прихожих. Материал для изготовления – высочайшего качества шпон ореха, а также вишни.',
            website: 'https://pianca.com'
        }
    },
    { 
        id: 84,
        url: 'estel',
        name: 'Estel', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/estel/estel_1.jpg',
                '../assets/img/pics/estel/estel_2.jpg',
                '../assets/img/pics/estel/estel_3.jpg',
                '../assets/img/pics/estel/estel_4.jpg',
                '../assets/img/pics/estel/estel_5.jpg',
                '../assets/img/pics/estel/estel_6.jpg',
                '../assets/img/pics/estel/estel_7.jpg',
                '../assets/img/pics/estel/estel_8.jpg',
                '../assets/img/pics/estel/estel_9.jpg',
                '../assets/img/pics/estel/estel_10.jpg',
                '../assets/img/pics/estel/estel_11.jpg',
                '../assets/img/pics/estel/estel_12.jpg',
            ],
            title: 'Estel – 80 лет на самой вершине дизайнерской мебели',
            about: 'Estel – всемирно известный итальянский бренд, начавший свою победоносную историю в тридцатых годах прошлого столетия. Данное семейное предприятие активно развивается из поколения в поколение итальянским семейством Стелло.',
            website: 'https://www.estel.com'
        }
    },
    { 
        id: 85,
        url: 'dolfi',
        name: 'Dolfi', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/dolfi/dolfi_1.jpg',
                '../assets/img/pics/dolfi/dolfi_2.jpg',
                '../assets/img/pics/dolfi/dolfi_3.jpg',
                '../assets/img/pics/dolfi/dolfi_4.jpg',
                '../assets/img/pics/dolfi/dolfi_5.jpg',
                '../assets/img/pics/dolfi/dolfi_6.jpg',
                '../assets/img/pics/dolfi/dolfi_7.jpg',
            ],
            title: 'Dolfi – история небывалого успеха',
            about: 'Элитная мебель Dolfi отличается своими формами, настоящей элегантностью и роскошью оттенков. Долговечность мебельных изделий гарантируется использованием исключительно натуральных материалов: древесного массива, кожи, металла и стекла. Особый шарм этой мебели придает нарочито искусственное состаривание, позолота, а также матирование.',
            website: 'http://www.dolfi.it'
        }
    },
    { 
        id: 86,
        url: 'brunozampa',
        name: 'Bruno Zampa', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/brunozampa/brunozampa_1.jpg',
                '../assets/img/pics/brunozampa/brunozampa_2.jpg',
                '../assets/img/pics/brunozampa/brunozampa_3.jpg',
                '../assets/img/pics/brunozampa/brunozampa_4.jpg',
                '../assets/img/pics/brunozampa/brunozampa_5.jpg',
                '../assets/img/pics/brunozampa/brunozampa_6.jpg',
                '../assets/img/pics/brunozampa/brunozampa_7.jpg',
            ],
            title: 'Bruno Zampa – роскошь, созданная в Вероне',
            about: 'Сегодня фабрика – всемирно известный производитель классической мягкой мебели. Наполнение мебели — смесь натурального пера и полиуретана. Итальянская компания предлагает клиентам огромнейшую палитру лучших тканей. В каждом стиле имеется собственная подборка идеально сочетающихся тканей. Огромный плюс бренда –доступные цены за изделия ТОП-уровня.',
            website: 'https://www.brunozampa.it'
        }
    },
    { 
        id: 87,
        url: 'giorgiocasa',
        name: 'Giorgio Casa', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/giorgiocasa/giorgiocasa_1.jpg',
                '../assets/img/pics/giorgiocasa/giorgiocasa_2.jpg',
                '../assets/img/pics/giorgiocasa/giorgiocasa_3.jpg',
                '../assets/img/pics/giorgiocasa/giorgiocasa_4.jpg',
                '../assets/img/pics/giorgiocasa/giorgiocasa_5.jpg',
                '../assets/img/pics/giorgiocasa/giorgiocasa_6.jpg',
                '../assets/img/pics/giorgiocasa/giorgiocasa_7.jpg',
                '../assets/img/pics/giorgiocasa/giorgiocasa_8.jpg',
                '../assets/img/pics/giorgiocasa/giorgiocasa_9.jpg',
            ],
            title: '',
            about: '',
            website: 'http://www.giorgiocasa.it'
        }
    },
    { 
        id: 88,
        url: 'tumidei',
        name: 'Tumidei', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/tumidei/tumidei_1.jpg',
                '../assets/img/pics/tumidei/tumidei_2.jpg',
                '../assets/img/pics/tumidei/tumidei_3.jpg',
                '../assets/img/pics/tumidei/tumidei_4.jpg',
                '../assets/img/pics/tumidei/tumidei_5.jpg',
                '../assets/img/pics/tumidei/tumidei_6.jpg',
            ],
            title: 'TUMIDEI – инновационные дизайнерские решения для меблировки',
            about: 'Каждый проект бренда продуман да мелочей и создан для удовлетворения потребностей современного клиента. Благодаря оригинальным традициям итальянского дизайна, индивидуализации изделий и тщательным разработкам, компания выражает свой неординарный стиль с помощью качественной мебели для гостиных, спален, детских комнат.',
            website: 'http://www.tumidei.it'
        }
    },
    { 
        id: 89,
        url: 'visionnaire',
        name: 'Visionnaire', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/visionnaire/visionnaire_1.jpg',
                '../assets/img/pics/visionnaire/visionnaire_2.jpg',
                '../assets/img/pics/visionnaire/visionnaire_3.jpg',
                '../assets/img/pics/visionnaire/visionnaire_4.jpg',
                '../assets/img/pics/visionnaire/visionnaire_5.jpg',
                '../assets/img/pics/visionnaire/visionnaire_6.jpg',
                '../assets/img/pics/visionnaire/visionnaire_7.jpg',
                '../assets/img/pics/visionnaire/visionnaire_8.jpg',
                '../assets/img/pics/visionnaire/visionnaire_9.jpg',
                '../assets/img/pics/visionnaire/visionnaire_10.jpg',
                '../assets/img/pics/visionnaire/visionnaire_11.jpg',
                '../assets/img/pics/visionnaire/visionnaire_12.jpg',
                '../assets/img/pics/visionnaire/visionnaire_13.jpg',
            ],
            title: 'VISIONAIRE – приятное погружение в мир гламура итальянской  роскоши!',
            about: 'Модные люксовые вещи в стиле арт-деко и гламур для ваших интерьеров. Бренд VISIONNAIRE специализируется на производстве современной стильной и комфортной мебели, а также всевозможных предметов интерьера. Для отделки используются роскошные материалы (страусиная кожа, натуральные стразы).',
            website: 'http://www.visionnaire-home.com'
        }
    },
    { 
        id: 90,
        url: 'gibus',
        name: 'Gibus', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/gibus/gibus_1.jpg',
                '../assets/img/pics/gibus/gibus_2.jpg',
                '../assets/img/pics/gibus/gibus_3.jpg',
                '../assets/img/pics/gibus/gibus_4.jpg',
                '../assets/img/pics/gibus/gibus_5.jpg',
                '../assets/img/pics/gibus/gibus_6.jpg',
                '../assets/img/pics/gibus/gibus_7.jpg',
                '../assets/img/pics/gibus/gibus_8.jpg',
                '../assets/img/pics/gibus/gibus_9.jpg',
            ],
            title: 'Gibus – ваш оазис благополучия!',
            about: 'Продукция бренда – это качество и удобство, ставшие отличительными чертами данного итальянского производителя. Элегантность пространства, созданного согласно пожеланиям клиента. Изящная легкость и минимализм определяют основу стиля Gibus. Изделия бренда удачно гармонируют с окружением и вашей жизнью!',
            website: 'https://www.gibus.com'
        }
    },
    { 
        id: 91,
        url: 'emu',
        name: 'EMU', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/emu/emu_1.jpg',
                '../assets/img/pics/emu/emu_2.jpg',
                '../assets/img/pics/emu/emu_3.jpg',
                '../assets/img/pics/emu/emu_4.jpg',
                '../assets/img/pics/emu/emu_5.jpg',
                '../assets/img/pics/emu/emu_6.jpg',
                '../assets/img/pics/emu/emu_7.jpg',
                '../assets/img/pics/emu/emu_8.jpg',
            ],
            title: 'Бренд EMU – совершенная уличная дизайнерская мебель!',
            about: 'Итальянский бренд предлагает изящную уличную мебель. Необычный дизайн, внимание к каждой детали, инновационные технологии производства – все это дает возможность компании предлагать ассортимент  практичной, комфортной и эстетически привлекательной мебели.',
            website: 'https://www.emu.it'
        }
    },
    { 
        id: '',
        url: 'unopiu',
        name: 'Unopiu', 
        parent: 'hidden',
        content: {
            pics: [
                '../assets/img/pics/unopiu/unopiu_1.jpg',
                '../assets/img/pics/unopiu/unopiu_2.jpg',
                '../assets/img/pics/unopiu/unopiu_3.jpg',
                '../assets/img/pics/unopiu/unopiu_4.jpg',
                '../assets/img/pics/unopiu/unopiu_5.jpg',
                '../assets/img/pics/unopiu/unopiu_6.jpg',
                '../assets/img/pics/unopiu/unopiu_7.jpg',
                '../assets/img/pics/unopiu/unopiu_8.jpg',
                '../assets/img/pics/unopiu/unopiu_9.jpg',
            ],
            title: 'Unopiu – идеи итальянского совершенства!',
            about: 'Одна из наиболее сильных сторон компании – широчайший ассортимент качественной мебели для улицы. Представленные в каталоге коллекции  удачно поддерживают стиль старинного кустарного промысла, совершенствуясь с инновационными формами и материалами.',
            website: 'https://www.unopiu.com'
        }
    },
    { 
        id: 93,
        url: 'arredoclassic',
        name: 'Arredoclassic', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/arredoclassic/arredoclassic_1.jpg',
            ],
            title: 'Arredoclassic',
            about: 'Arredoclassic - молодая компания, основанная в 1998 году в мебельном районе Пезаро, Марке. Arredoclassic доказала свой новаторский и авантюрный характер, выйдя на международные рынки и став послом ценностей. Эти ценности отражены в коллекциях и продуктах Arredoclassic, а также в философии компании.',
            website: 'https://arredoclassic.it'
        }
    },
    { 
        id: 94,
        url: 'turri',
        name: 'Turri', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/turri/turri_1.jpg',
                '../assets/img/pics/turri/turri_2.jpg',
                '../assets/img/pics/turri/turri_3.jpg',
                '../assets/img/pics/turri/turri_4.jpg',
                '../assets/img/pics/turri/turri_5.jpg',
            ],
            title: 'Красивая вещь – это радость навсегда!',
            about: 'С помощью самых сложных станков и оборудования невозможно достичь уникальности и качества, так как главным творцом конечного результата - является ручная работа, с которой мастера Turri, многие из которых настоящие художники, доводят до совершенства каждую деталь. Только так достигаются чистота форм, качество и престиж и только такая мебель может носить марку Turri',
            website: 'https://turri.it'
        }
    },
    { 
        id: 95,
        url: 'cipriani',
        name: 'Cipriani', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/cipriani/cipriani_1.jpg',
                '../assets/img/pics/cipriani/cipriani_2.jpg',
                '../assets/img/pics/cipriani/cipriani_3.jpg',
                '../assets/img/pics/cipriani/cipriani_4.jpg',
                '../assets/img/pics/cipriani/cipriani_5.jpg',
            ],
            title: 'Cipriani - Силы природы действуют тайной гармонией, которую человек должен открыть.',
            about: 'Найдите тщательно обработанное дерево и идеально сбалансированную отделку, уникальные строчки и инкрустации, драгоценную кожу. Откройте для себя архитектуру, дизайн и искусство, в которых современный стиль прекрасно сочетается с вневременными предметами. Наслаждайтесь простой роскошью, которая гарантирует, что все всегда будет так, как должно быть в Cipriani.',
            website: 'https://www.cprnhomood.it'
        }
    },
    { 
        id: 96,
        url: 'giorgiocollection',
        name: 'Giorgio Collection', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/giorgiocollection/giorgiocollection_1.jpg',
                '../assets/img/pics/giorgiocollection/giorgiocollection_2.jpg',
                '../assets/img/pics/giorgiocollection/giorgiocollection_3.jpg',
                '../assets/img/pics/giorgiocollection/giorgiocollection_4.jpg',
                '../assets/img/pics/giorgiocollection/giorgiocollection_5.jpg',
            ],
            title: 'Превосходство декора становится роскошью',
            about: 'Giorgio Collection - это идеальное сочетание лучших материалов, внимание к деталям и итальянского мастерства. Атмосфера ар-деко сочетается с ультрасовременными формами, привнося свежесть в самые эксклюзивные дома по всему миру.',
            website: 'https://giorgiocollection.com'
        }
    },
    { 
        id: 28,
        url: 'glassdesign',
        name: 'Glass Design', 
        parent: 'furniture',
        content: {
            pics: [
                '../assets/img/pics/glassdesign/furniture/glassdesign_1.jpg',
                '../assets/img/pics/glassdesign/furniture/glassdesign_2.jpg',
                '../assets/img/pics/glassdesign/furniture/glassdesign_3.jpg',
            ],
            title: 'Glass Design - это итальянское превосходство в производстве и дизайне аксессуаров для интерьера!',
            about: 'Компания Glass Design, расположенная в Винчи, сердце Тосканы и города Леонардо да Винчи, была основана в 1984 году семьей Борселлини после многолетнего опыта в производстве художественного стекла.',
            website: 'https://www.glassdesign.it'
        }
    }
]