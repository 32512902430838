<template>
    <svg class="portfolio_main_svg" x="0px" y="0px"
        viewBox="0 0 1920 1080" style="enable-background:new 0 0 1920 1080;" xml:space="preserve">
        <path class="portfolio_main_lines" d="M345.3,639.5c-10.3,5-15.5,10.2-16.9,17.1c1.7-5.4,7.5-4.7,11.1-7c-1.1-2.6-3.2-0.3-4.5-1.6
            C338.2,644.8,344.3,645.1,345.3,639.5c5.5,0.8,11,0.2,16.3-0.9C356.1,635.7,350.7,638,345.3,639.5z"/>
        <path class="portfolio_main_lines" d="M806.8,337.7c1.4-1.6,1.6-3.6,2-5.5c2-9.8,4.9-19.3,7.7-28.9c1.8-6.3,3.6-12.6,5.4-18.9
            c-0.4-0.1-0.9-0.2-1.3-0.4c-4.9,17.8-10.6,35.4-13.6,53.7c-2,1.6-4.5,2.3-7.5,3C806.9,344.2,807.2,344.1,806.8,337.7z"/>
        <path class="portfolio_main_lines" d="M810.1,784c2.7-0.2,3.7-2.6,4.2-4.4c1.3-4.7,5-7.3,7.9-10.6c0.7-0.8,2.3-1,1.7-2.6c-9,0-11.7,1.8-13,8.7
            c-0.5,2.9-0.4,6-0.6,8.9c-5.4,3.4-6.9,7.9-4,11.8C810,792.7,808.5,787.9,810.1,784z"/>
        <path class="portfolio_main_lines" d="M688.5,378.7c0.4-1.4-0.4-2.5-0.8-3.8c-0.9-2.6-0.3-4,2.8-3c1.4,0.4,3.3,1.5,3.6-0.6c0.3-1.6-2-1.2-3.2-1.5
            c-1.2-0.4-2.6-0.6-3.9-0.7c-1.1-0.1-2.4-0.2-3.1,1c-0.8,1.4,0.1,2.5,0.7,3.7C685.7,375.6,688.4,376.1,688.5,378.7
            c1,1.1,2.1,2.7,1.3,3.9c-0.9,1.5-2.7,0.2-4.1,0.1c-3.1-0.3-6.1-1.8-10.3-1.3c5.9,4.3,12.1,4.9,18,5.7c1.9,0.3,4.7,4,6.2-0.8
            c0.8-2.4,3.4-4,3.2-7c-0.1-1.4-0.5-2.7-1.9-3.3c-1.3-0.5-1.8,0.6-2.2,1.5c-0.9,2.4-2.8,1.8-4.6,1.6
            C692.4,378.9,690.5,377.7,688.5,378.7z"/>
        <path class="portfolio_main_lines" d="M773.8,197.3c3,3,4.3,0.9,4.2-1.8c-0.2-7,2.6-12.9,5.7-18.9c0.7-1.3,1.9-2.7,0.6-4.5
            c-2.7,1.7-4.3,3.8-4.9,6.8c-1.1,5.3-4.8,9.9-4,15.8c0.1,0.8-1.2,1.9-1.8,2.8c0.2-1.7-0.1-4.2-2-3.9c-3.8,0.6-3.8-2.2-5-4.1
            c-0.6-1.1-1.1-2.5-3-2.6c-1.3,6.9-2.1,13.8,1.4,20.4c0.6,1.1-0.1,3.1,1.8,3.1c1.9,0,2.7-1.8,3.3-3.3
            C771.6,203.8,771.8,200.2,773.8,197.3z"/>
        <path class="portfolio_main_lines" d="M1081.1,635c0.6,0.1,1.4,0.5,1.9,0.4c2.9-0.8,7.8-9.3,6.5-11.9c-1-2-1.9-5.4-5.7-2.3c-2.2,1.8-5.3,2.6-8,4
            c-5.4,2.8-8.9,7.7-13.7,11.7c2.3,0.9,3.3-1.4,5.3,0c-6,4-9.7,10-15.1,14.2c0.9,2.2,2.8-0.2,3.6,1.2c2.1,3.5,3.8,0.8,4.7-0.7
            c5-8,13.4-11.6,20.8-16.5c-4.9,4.9-8.7,10.8-14.4,14.9C1073.2,649.3,1080.9,640.7,1081.1,635z"/>
        <path class="portfolio_main_lines" d="M849,688.2c-3.6-2-6.9,1.8-10.9,0c2.3,4.7,5.4,6.9,9.9,7.5c1.2,0.2,2.1,0.7,2.9-0.6c0.8-1.2,1.3-2.4,0.5-3.7
            s-1.7-2.4-2.6-3.6c0.6,0.2,1.4,0.7,1.9,0.6c7.9-2.7,13,4.2,19.5,6c1.1,0.3,1.7,2,2.8,2.7c3.9,3,8.3,6.1,13,4.4
            c4.7-1.6,8.6-1.2,13,0.2c3.3,1,6.8,1.4,10.2,2.2c2.4,0.6,2.8-0.6,3.6-2.6c1-2.7-0.7-2.9-2.2-3.4c-2.7-1-5.4-1.2-8.3-0.8
            c-4.4,0.6-8-0.4-11.5-4c-5-5-10.6-9.9-19.7-10.7c5.4,3.8,9.7,6.8,14.2,9.9c-1.9,1.4-3.3,0.8-4.7,0.2c-6.4-2.4-12.7-4.8-19.1-7.3
            C856.8,683.5,852.8,685.1,849,688.2z"/>
        <path class="portfolio_main_lines" d="M1379,165.8c-2.1-0.4-4.2-1-6.3-1.3c-4.5-0.6-10.5,7.2-9.3,11.6c2.4,8.3,9.6,13.3,13.7,20.5
            c0.7,1.2,2.1,1.5,3.5,1.7c3.3,0.5,6.6,0.9,9.8,1.7c3.7,0.9,8.7,3.8,8.1,6.4c-0.8,3.7,0.4,4.7,2.8,5.2c3.1,0.6,4.3-1.7,4.8-4.5
            c1.4-9.5-3.2-17.2-7.3-25c0.1,2.8,0.7,5.5,1.1,8.2c0.3,2.6-0.9,4.5-3.5,4.4c-2.7-0.1-4.8,1.5-7,1.6c-1.9,0.1-3.2-2.7-6.4-1.4
            c-1.7,0.7-3.5-2.8-4.2-5.4c-2-7.9,1.3-15.8,0.1-23.7c0.7,0.5,2,1.9,2.1,1.1c0.3-3.3,3.9-6.9-0.4-10.2c-1-0.8-1.5-2.5-3.1-1.7
            c-1.7,0.9-1.4,2.6-0.8,3.9C1377.8,161.1,1379.1,163.2,1379,165.8z"/>
        <path class="portfolio_main_lines" d="M1013.4,161.9c6.4-1.4,8.9-7,12.1-11.7c0.9-1.3,0.6-3.2-1-3.9c-1.5-0.7-2.3,1-2.9,2.1
            C1019.1,153,1017.2,158,1013.4,161.9c1.9-4.7,3.9-9.5,5.8-14.3c0.5-1.4,1.3-3.8,0-4.1c-3.2-0.8-2.7-3.8-4.7-6.5
            c-2.2,8.8-8.6,8.2-14.8,8.1c-0.8,0-1.6,0.2-2.4,0.4c-1.8,0.5-3,1.5-3,3.5c-0.1,3.7-0.9,7.2-1.8,10.8c-0.6,2.4,0.1,3.9,3,3.6
            c1.5-0.1,3,0,4.5,0c7.4-0.3,7.5,0.2,5,7.3c-2.2,6.2-6.6,10.6-10.2,15.8c-0.6,0.9-1.8,2-0.5,3c1.1,0.9,2.2,0,3.1-0.8
            c0.7-0.6,1.5-1.4,1.9-2.3c3.7-8.1,12.4-13.4,13-23.2C1012.1,162.7,1012.9,162.3,1013.4,161.9z"/>
        <path class="portfolio_main_lines" d="M1500.2,250.2c-3.2,0.1-7.5-1.1-4.9,5c0.5,1.3-0.2,3.3-0.6,4.9c-0.5,1.8-2,3.1-3.8,1.8c-2.9-2.2-5-5.2-4.2-9
            c1.1-5.6,0.9-6-4.7-6.1c-7.9-0.1-15.9,1.3-23.7-3.2c0.1,1.3-0.1,2.2,0.2,2.6c4.4,5.1,5.8,11.7,9,17.4c2.4,4.3,4.3,9,6.4,13.5
            c0.4,0.9,1.1,1.9,2.2,1.7c1.3-0.3,1-1.6,1-2.5c0.1-2.5-0.5-4.9-1.7-7.2c-1.8-3.5-3-7.3-2.6-11.6c3.2,0.9,4.1,2.9,3.9,5.7
            c-0.3,3.1,0.5,6,2.6,8.4c1.1,1.3,2.1,3.5,4.3,2.5c1.8-0.8,1.4-2.9,1-4.5c-1-4.1,0.8-5.6,4.7-5.4c1.5,0.1,3,0,4.5,0.2
            c5.1,0.4,7.1-1.2,7-6.3C1500.7,255.4,1500.4,252.8,1500.2,250.2c0.8-0.4,2-0.9,1.9-2c-0.9-8.1-0.1-16.7-6.5-23.4
            c-0.8-0.8-1.3-2.2-2.7-1.3c-1,0.6-1.5,1.8-1.5,3c0,2.2,1.4,4,2,6c1,3.2,5.2,7.4-1.8,9.3c-1.2,0.3-1.8,1.6-1.4,2.8
            c0.4,1.3,1.8,1.8,2.8,1.5C1497.1,244.8,1498.9,247,1500.2,250.2z"/>
        <path class="portfolio_main_lines" d="M1445.7,187.4c-1.3,0.7-2.9,1-3.6,2.5c-2.1,4.8-6.4,4.6-10.6,4.5c-1.3,0-2.7-0.4-4-0.3
            c-14.3,0.3-14.8-0.2-13.3-14.4c-0.7,0.8-1.3,1.2-1.5,1.7c-3.8,10.1-1.2,20.4-0.5,30.6c0.1,1.1,1.1,1.5,2.1,1.9
            c5.5,2.1,5.9,3.5,2.6,8.7c-1.6-1.2-1-3.9-3.2-4.8c-0.3,0.5-0.7,0.9-0.8,1.3c-0.6,5.1,8.4,22.2,12.9,24.6c2,1.1,3.5,1,4.1-1.5
            c0.5-1.7,1.5-2.8,3.5-3.1c6.2-0.8,8.3-4,6.9-10.3c-0.8-3.3-0.9-6.8-2.4-10c-1.6-3.3-0.1-5.2,3.4-6.2c3.7-1.1,7.4-0.7,11.5-0.6
            c-1.7-2-3.7-1.2-5.4-1.5c-2.5-0.4-4.1-1.5-4.1-4.1c-0.1-6.5-1.7-13.3,2.2-19.3c0.9,1.7,1.8,3.4,3.1,5.9c-0.3-5-0.1-9.1,0.7-13.1
            c0.2-1-0.4-2.7,1.5-2.4c2.9,0.5,5.3-0.5,4.1-3.2c-1-2.3-0.6-4-0.4-6.1c-4.3-0.3-3.2-4.1-4-7.2c-3.5,3.8-2.7,7.9-3.4,11.7
            C1446.5,177.7,1448.3,182.8,1445.7,187.4z"/>
        <path class="portfolio_main_lines" d="M373.2,774.9c-0.5,1.3-2.8,2-1.7,3.6c0.8,1.1,2.5,0.5,3.8,0.2c2.5-0.4,5-1.8,7.5-0.2c-0.2,2.6-4,2.6-3.6,6.4
            c3.5-3,7.2-3,10.8-1.8c2.4,0.8,4.3,0.1,4.6-2.2c0.3-2.3,0.2-5.6-3.2-5.7c-6.1-0.1-12.2-1.2-18.4-0.3c-4.2-3-8.6-5.7-12.4-9
            c-6.9-5.9-15-10.7-19.9-18.6c-1.9-3-3.1-3.1-6-2.3c-18.5,5.1-36.4,11.7-55,16.3c-15.8,3.9-32,6.6-45.9,16.1
            c-1.7,1.2-4,1.6-6.1,2.3c-9.6,3.3-19.5,5.7-28.7,10.2c-17.8,8.7-35.5,17.5-53.8,25.2c-1.3,0.6-2.9,0.7-4,2.4c1.7,0.3,3-0.5,4.4-1
            c14.8-6,29.3-12.7,43.7-19.6c7.9-3.8,15.5-8.6,24.6-9.5c17.2-1.6,33.2-8,49.9-11.7c15.2-3.4,30.3-7.3,45.9-8.5
            c5.9-0.4,12-0.6,18,0.9c-6.9,2.2-13.8,4.3-20.8,5.4c-24.1,3.7-45.7,13.9-66.7,25.5c-4.9,2.7-9.7,4.1-15.2,3.8s-10.9,0.6-16.3,1.9
            c-10.7,2.5-20.8,6.8-31.7,8.8c-8.5,1.6-17.3,1.6-25.6,4.3c-6,1.9-11.9,4-18.5,6.2c3.7,1.9,6.5-0.8,10.2,0.5
            c-5.4,3.3-10.7,4.6-15.9,6.1c-1.2,0.3-3.1,0.5-3.1,2.1c0,1.9,2.1,2,3.4,2.1c4.1,0.3,8.3,0.5,12.4-0.6c3.2-0.8,6.5-1.9,9.7-1.8
            c10.1,0.2,19.6-1.6,27.7-7.9c3.1-2.4,6.6-2.2,8.5-0.1c2.4,2.6-0.9,4.5-3,6.1c-4.4,3.6-3.6,8.1,1.7,10.4c4,1.8,7.8,4,11.5,6.6
            c5.2,3.7,10.4,7.1,18,6c10.4-1.5,20.4-5.1,31-5c6,0,10-2.8,12.2-7.8c2.8-6.1,7.5-10.5,12.4-14.8c3.3-3,6.7-6,9.9-9.1
            c2.2-2.1,3.8-4.2,3.4-7.8c-0.5-4.4,4.3-7.5,8.3-5.8c2.7,1.1,4.8,0.1,7.2-0.5c10.4-2.8,20.8-7.1,31.5-1c0.9,0.5,2.3,0.3,3.4,0.4
            c3.3,0.4,6.6,0.6,9.9,1.3c17.9,3.6,36.1,3.6,54.2,3.5c2.3,0,4.7-0.3,5.2-2.9c0.5-2.5-1.4-5.1-3.5-5.4c-4.1-0.5-7.9-2.3-12.2-1.8
            c-11.2,1.3-22.4,2.1-33.6,3c-2.7,0.2-4.8-0.7-6.7-2.6c-1.5-1.5-1.1-2.6,0.5-2.9c4.8-0.9,7.7-4.5,11.1-7.3c1.3-1.1,4.1-1.9,2.6-3.9
            c-1.5-2.1-3.1-5.6-6.5-4.6c-5.1,1.5-10,3.7-14.9,5.7c-6,2.5-11.4,1.6-14-4.4c-0.6-1.4-0.1-1.9,0.9-2.3c7.8-3.1,15.2-7.3,24.4-5.3
            C358.3,773.8,365.8,774.1,373.2,774.9z"/>
        <path class="portfolio_main_lines" d="M1087.7,603.8c-2.5,1.1-4.3,3.7-7.8,3.2c0.5-3.1,3.4-5.8,1.7-9c-1.5-3-5.1-2.2-7.5-3.5
            c1.1-2.4,2.9-3.6,4.6-4.9c1.3-0.9,2.8-2,2.2-3.5c-1.3-3,0-3.9,2.6-4.3c2.1-0.4,2.5-1.1,1.1-3c-2.5-3.5-1.7-6.4,2.3-7.8
            c4.1-1.4,8.3-2.5,12.7-2.6c4.2,0,5.5,2.3,3.5,6c-0.4,0.6-1.8,0.9-0.4,2.3c2,1.9,1.7,2.8-0.5,4.4c-2.3,1.7-4.8,2.7-7.8,3.5
            c2.2,1.6,4.4,0.8,6.5,0.8c1.7,0,3.6-2,5.1-0.2s2.2,0.5,3.2-0.3c3.7-2.9,6.5-6.5,9-11.1c0.6,1.7,1,2.7,1.5,4.1c2-3.3,2.1-7,4.7-9.4
            c0.8-0.7,1.1-2.4,2.6-1.7c1.1,0.6,1.4,1.9,1.2,3.1c-0.7,3.2-1.7,6.4-2.5,9.6c-2.1,8.2,0.5,12,8.9,12.9c4,0.4,6.2,3.1,4.8,6.7
            c-2.5,6.8-5.7,13.1-12,17.4c-5.4,3.7-10.6,7.4-15.9,11.2c-1.2,0.8-2.8,1.8-1,3.5c1.7,1.6,9.2,1.2,9.9-0.9c1.8-5,6.5-6.9,9.9-10.2
            c2.8-2.8,5.9-3.2,9.4-1.2c-0.5,1.9-2.9,1.7-3.5,3.4c0.8,1.3,2,2.7,3.1,3.6c4.1,3.4,1.6,5.4-1.1,7.5c-2.5,1.9-5.1,3.7-7.7,5.6
            c-4.2,2.9-4.1,4,0.2,6.6c6.5,4,13,8,19.3,12.3c4.1,2.8,4.3,6,0,8.6c-6.3,3.9-13.6,5.5-20.7,7.3c-2.6,0.6-5.4,0.5-8.1,1.8
            c-1.9,1-4.3,1.1-3.3,4.3c0.9,2.9,2.5,4.8,5.7,4.2c3.7-0.7,7.3-0.3,10.9,0.2c1.6,0.2,2.9-0.4,4.3-0.8c8.4-2.4,16.6-5.2,21.7-13.1
            c0.2-0.4,0.8-0.6,1.2-0.8c4.5-2.1,7.3-5.7,9.2-10.1c0.7-1.5,0.9-3.6,3.4-3.6c0.4,5.1-2.5,8.7-4.7,12.4c-1.5,2.4-1.5,4.6-0.8,7.5
            c1.3,5.2,3.4,10,5.9,14.6c0.6,1.1,2.1,2.8,0.7,4.1c-1.4,1.2-3.2,2.2-5.5,1.4c-2.4-0.8-5.3-2-7.6-1.4c-6.7,1.9-13.2,3.2-19.7-0.4
            c-0.6-0.4-1.9-0.5-2.4-0.1c-4.1,3.3-10.4,1.1-14,5.7c-0.9,1.1-2.5,0.4-3.8-0.1c-6.7-2.6-7.5-2.2-9.5,4.9c-0.5,1.8-1,3.6-1.8,5.2
            c-1.9,3.8-1.7,7.5-0.4,11.5c1.3,4.3,4.6,3.6,7.2,3.1c3.9-0.8,0.8-2.8,0.2-4.4c-1.8-4.9,2.5-10.3,7.8-10.1c3.2,0.1,6.1,1.2,9.4,1.2
            c7.1-0.2,13.5-3.4,20.4-4.3c16.1-2.2,31.4-5.8,43.4-18.3c6.4-6.6,10.3-13.9,11.3-22.6c0.7-6.4-1.1-13,2.1-19.3
            c1-1.9,0.4-4.6-0.5-6.8c-1.5-3.5-3.3-6.9-4.7-10.4c-1.6-3.9-3.5-4.9-6.6-1.5c-0.4,0.5-1,0.8-1.5,1.3v0.1c0.3-2.8,2.6-3.6,4.7-4.6
            c4.7-2.3,7.9-6.2,7.8-11.3c-0.1-4.2,1.6-5.6,5-6.4c1.4-0.3,2.9-0.9,4.2-1.5c7.6-3.6,9-11.7,2.8-17.4c-4.4-4-9.2-7.7-13.8-11.5
            c-9.9-8.2-19.2-16.7-23.7-29.4c-1.8-5-3.4-9.6-3-14.9c0.6-7.6,0.9-15.3,1.2-23c0.4-10.4-5.1-17.8-11.9-24.9
            c-7.3-7.6-15.1-15.1-14.8-27c0-0.8-0.4-1.6-0.7-2.4c-6.3-18.4-18.4-33.2-30.3-48.2c-2.5-3.1-5.9-5.3-9.5-7.2
            c-2.6-1.4-4.9-2.8-6-6.1c-1.6-4.5-7.5-6.9-12.5-6.3c-4.8,0.6-9.6,1.3-14.4,1.7c-4,0.3-7.6,1.4-11.4,2.7
            c-5.2,1.8-10.9,6.8-16.7,0.6c-0.3-0.3-0.9-0.5-1.4-0.4c-9.2,0.6-18.1-2.3-27.1-3.3c-5.1-0.6-10.1-0.9-14.9-3.3
            c-6.8-3.4-14.1-4.6-21.8-4.2c-10.9,0.5-21.9-1.9-32.7,1.2c-12.2,3.5-24.8,4.5-37.5,4.4c-7.9-0.1-16.6-2.5-23.7,3.8
            c-0.8,0.7-2.5,0.5-3.9,0.7c-5.8,0.8-6.7-4.5-9.4-7.5c-2.1-2.4-2.4-4.5,1.1-6.1c1.7-0.7,3.3-1.7,3.6-3.8c0.6-4.1,1.3-8.2,2-12.3
            c0.1-0.8,0.5-1.7-0.6-2.1c-0.9-0.3-1.6,0.3-2.2,0.9c-1.3,1.2-1.6,2.8-1.9,4.5c-0.5,3.1-0.9,6.3-2.3,9.2c-1,2-2.3,4.1-4.5,4.2
            c-2.1,0.1-3.2-1.9-4.3-3.7c-4.2-6.7-8.4-13.5-14.2-19.2c-3.9-3.8-7.3-4.2-12-2.5c-2.8,1-3.6,1.8-2.2,4.9
            c4.2,9.2,10.3,17.3,14.8,26.4c-6.2,1.1-10,5.5-5.3,11.6c3.2,4,6.7,7.2,11.4,9.1c1.7,0.7,3.4,1.4,4.3,3.2c-6.5,2.9-12,0.6-16.2-6.8
            c-3.1-5.4-8.3-5.9-13.8-6c-4.2-0.1-8.1,1.4-8.5,5.3c-0.6,6.6-4.7,7.3-9.6,7.8c-1.3,0.1-2.6,0.6-3.9,0.9c-3.6,0.7-3,3.5-2.8,5.9
            c0.2,2.5,1.8,3.3,4.2,2.7c1.2-0.3,2.8-1,3.3,1c0.4,1.5,1.5,3.1,0,4.7c-1.4,1.4-2.6,3.1-4.2,4.2c-1.6,1.2-3,2.1-1.6,4.3
            c1.2,2,2.8,2.8,5,1.7c4.7-2.4,7.8-0.7,10,3.7c1.3,2.4,3.8,3.7,5.6,1.6c2.3-2.7,5.5-5.2,5.9-9.1c0.4-3.8,2.9-5.6,5.9-7.1
            c-1.7,1.8-2.9,4-3.9,6.2c-1.2,2.7-3.8,6.4-2.9,8.1c1.8,3.3-0.1,4.8-1.6,6.3c-2,1.9-4.5,0.8-6.4-0.7c-3.2-2.7-5.4-1.4-7.4,1.5
            c-1.4,2-2.6,4.4-5.7,4.1c-3.8-0.4-7,1.7-8,4.7c-1,2.8,2.3,4.8,4.7,6.4c0.2,0.1,0.2,0.5,0.4,0.9c-5.6,2.6-11.8,4.3-17.2,1.1
            c-5.8-3.5-11.7-6.1-18.1-8c-6.6-2-6.7-3-5-9.9c0.8-3.4,1.7-6.9-2.2-9.3c-8.3-5.2-15-12.7-24.5-16c-1.8-0.6-3.2-2.3-4.8-3.5
            c-2.5-2-2.6-4.2-1.1-7c3.8-7.6,2.5-14.3-3.7-20c-0.9-0.9-1.9-2.3-3.3-1.6c-1.2,0.5-1.2,1.9-1.2,3.1c-0.1,3.1-0.6,6.1-2.4,8.6
            c-2,2.8-1.1,4.9,1,7c2.2,2.4,4.9,4.5,6,8.1c-3-0.7-5.7-1.2-8.4-1.9c-1-0.3-1.5-0.4-2.5,0.5c-3.1,2.7-6,1.8-8.5-1.1
            c-1.3-1.4-2.1-3.5-4.5-3.6c-8.9-0.7-17.8-2.2-26.7-2.3c-3,0-5,1-6.4,3.8c-2.5,5.2-7.3,8-12,10.9c-1.3,0.8-2.7,1.2-4.3,0.9
            c-2.8-0.6-5.6-3.2-8.5-1.6c-3,1.6-3,5.4-4.6,8.1c-0.4,0.7-0.8,1.5-1.4,2.1c-3.3,3.4-4.8,7.5-4.7,12.2c0,1.2,0.5,3-1.1,3.5
            c-1.5,0.4-2.1-1.4-3-2.2c-6-5.4-12.2-10.9-20.7-11c-10.2-0.1-19.5-2.3-27.9-8.2c-7.2,1.5-14.5,2.8-20.6,7.4
            c-2.1,0.5-4.2,0.9-6.2,1.7c-6,2.1-11.2,6.2-13.3,11.7c-1.6,4.2-2.8,3.5-5.3,2.4c-1.7-0.7-3.2-2.1-4.9-0.2
            c-1.8,2.1-4.3,2.3-6.4,1.3c-6.2-2.8-10.9,0.4-15.7,3.5c-3.8,2.5-6.8,5.7-9.8,9c-0.9,1-1.8,2.1-2.7,3.1c-1.5,1.5-2.6,3.5-4.8,4.1
            c-3.1,0.9-3.9,3.4-3.5,6.3c0.2,1.9,0.5,3.8,0.8,6.2c7.3-7.4,16-10.2,25.7-10.2c6.5,0,12,2.5,16.4,7.2c1.1,1.2,3,2.8,1.9,4.8
            c-1.2,2.2-3.1,0.8-4.9,0.5c-2.1-0.4-4.2-0.9-6.3-1.3c-8.1-1.8-15.8-0.9-23.6,3.6c0.9,0.1,1.3,0.1,1.6,0.1c0.8-0.1,1.7-0.2,2.4-0.5
            c10-3.3,17.5,1.4,24.2,7.8c4.2,4.1,1.9,8.2-0.8,12.4c-1.4,2.3-2.2,2.7-4.5,1.7c-8.6-3.5-17-7.6-26.7-7c-2.4,0.1-5-2.2-7.2-0.6
            c-5.8,4.2-5-0.5-4.9-3.6c0.1-2.5-0.3-3.3-2.9-2.8c-2.1,0.4-3.6,1.1-5.1,2.6c-2.9,2.8-5.4,6.2-9.5,7.7c2.4,0.8,4.6,0.3,6.8,0.1
            c1.3-0.2,3,0,3.3,1.5c0.3,1.3-1.3,1.8-2.3,2.3c-4.4,2.3-9.2,1.1-13.8,1.1c-3,0-6,0.1-7.9,2.2c-2.2,2.4-4.9,3.9-7.3,5.9
            c-3,2.6-6.5,4.8-8.6,8.4c7-4.6,12.9-10.7,21.8-11.8c-2.1,1.8-4.6,3.1-6.8,4.7c-2.4,1.6-4.9,3.1-5.8,7.4
            c8.2-5.7,16.5-8.7,24.7-11.7c0.9,2.1-1.6,3-0.5,4.9c1.5-1.7,2.9-3.3,4.4-4.8c0.6-0.5,1.4-1.4,2.5-0.2c2.4,2.9,4,2.7,5.7-0.9
            c2.9-6,8.9-5.9,14.1-7.4c1.9-0.5,4.6-0.5,6.3,3.1c-3.8-2.1-4.8,1.9-7.3,2.1c-5.4,0.4-9.8,2.8-14.1,5.8c-3.1,2.2-5.3,6.9-10.5,3.9
            c-0.3-0.2-1.5,0.7-2,1.3c-4.6,4.9-10,8.9-15.6,13.2c3.2,0.8,4.5-2.4,7.5-2.6c-1.3,4-1.9,7.8-3.1,11.6c-1.7,5.2-4.2,10.7-2.8,16
            c1.9,7.1,0.3,11.4-6.7,13.7c-1,0.3-2.1,1.3-1.1,2.2c1.5,1.4,3.7,1.5,5.7,1.5c1.5,0,3.9-1,3.7-1.9c-1-4.5,2.3-7.9,2.1-12.3
            c-0.4-9,2.8-17,8.4-24.1c1.9-2.4,4.3-2.9,6.5-1.1c3.7,2.9,7.9,4.9,11.6,7.8c1.8,1.4,4,2.8,4.5,5.9c-4.1-0.4-7.9-0.7-11.7-1
            c-0.9-0.1-2.3-0.1-2.6,1c-0.4,1.4,1,2.1,2,2.4c2,0.8,4.2,1.1,6.2,1.9c4.1,1.6,4.6,3.9,1.6,6.9c-1.9,1.9-0.7,2.8,0.7,3.9
            c7,5.8,12.6,13,19.3,19.2c3.9,3.6,7.8,4.1,12.3,3.6c3.5-0.4,3.9-4,4.8-6.2c0.6-1.5-2.8-1.5-4.4-1.7c-9.9-1.4-16-8.3-20.4-16.2
            c-2.7-4.8-2.6-10.9,0.1-16.2c1.4-2.7,3.4-4.3,5.5-0.2c0.9,1.7,2.4,3.2,3.6,4.8c0.6,0.8,1.4,1.6,2.5,1c0.8-0.5,0.7-1.4,0.6-2.2
            c-0.4-3.2-1.2-6.2-2.5-9.1c-2.5-5.5-3.7-11.4-4.3-17.3c0-0.4,0.4-0.8,0.5-1.1c1.5-0.2,1.4,1.9,2.5,1.7c1.6-1.7,0.6-5.7,3.2-5.1
            c2.4,0.6,5.9,1.1,7.1,4.7c1.6,4.9,4.3,9.3,5.7,14.4c2.4,8.6,11.3,14.6,20.2,14.2c7.8-0.4,8.9,1.3,5.6,8.7
            c-0.3,0.6-0.6,1.2-0.9,1.8c-0.4,0.7-0.9,1.6-0.1,2.2c1,0.7,1.9,0,2.6-0.6c1.2-1,0.7-2.4,0.9-3.7c0.5-3.6,0.5-7.4,4-9.8
            c7.3-5.1,16.4-5,24.1,0c2.2,1.5,4.2,2.9,5.9,5.1c2.1,2.9,5.1,3.9,8.8,3.4c4-0.5,6.2-3.6,9-5.8c5.3-4.2,10.9-6.8,17.9-4.4
            c8.5,2.9,15.6,8.2,22.3,13.7c5.1,4.2,5.6,11.1,7.6,17.1c3.1,9.2,8.6,16.8,17.7,20.5c3.8,1.6,7,3.6,10,6.1c2.5,2.1,3.8,4-0.7,5.4
            c-3.5,1.1-8.1,8.9-7.8,12.7c-3.8-1.9-7.9-2.9-10.8-6.2c-2.4-2.9-4.6-0.2-6.6,0.6c-1.8,0.7-1.3,2.7-0.3,3.9
            c7,9.3,14.8,17.6,27.5,18.1c4.1,0.2,4.6,1.4,2.4,4.8c-2.2,3.3-2,3.4-0.5,7.1c0.2,0.4,0,1.1-0.3,1.4c-4,4.5-3.4,10.2-4.3,15.6
            c-1.9,11.3,0.7,20.6,10.5,27.1c10.3,6.7,14.6,17.4,18.8,28.1c2.5,6.1,2.1,12.8,3.7,19c2,7.8,2.5,15.2-2.1,22.3
            c-1.1,1.7-1.3,3.7-1.7,5.7c-1.6,9.4-1,18.9-2,28.3c-1.2,11.1,3.8,20,16.5,21.1c3.5,0.3,6.9,0.9,10.4,1c2.1,0.1,4.3,0.4,6.2-1.1
            c7.2-5.6,10.9-22.8,5.8-30.4c-2.6-3.8-1-5.3,1.8-6.9c1.4-0.8,3.1-1.2,4.6-2c4.9-2.4,6.6-8.7,4-13.8c-2.6-5-6.8-6-12.1-2.9
            c-1,0.6-2,1.9-3,0.7c-1.1-1.2,0-2.6,0.9-3.5c0.8-0.8,1.9-1.3,3-1.6c7.1-1.7,12.8,1.3,16.8,6.6c3,3.9,5.8,5.1,10.2,3.4
            c1.7-0.7,2.8,0.4,3.7,1.8c1.4,2.3-0.9,2.3-1.9,3.1c-2.4,2.1-5,4-7.1,6.2c-5.1,5.4-4,12.4-4.6,18.9c4.3,2.2,5.3,7.7,9.7,9.8
            c0.6,0.3,1.2,0.5,1.9,0.7c7.9,2.1,14.2-2.3,20.5-5.8c1.4-0.8,1.6-2.8,1.3-4.6c-0.2-0.9-0.7-1.5-1.7-1.5c-2-0.1-2.6,1.4-3.6,2.8
            c-2.6,3.9-6.7,6.2-11.3,6.3c-3.9,0.1-7.5-2-7.8-6.3c-0.3-5.1-2.1-10.6,2.3-15.2c0.8-0.8,1-2.6,0.8-3.8c-0.8-4.1,1.6-5.6,4.9-6.8
            c6.2-2.3,12.9-3.7,18-8.4c1.4-1.3,4-2.9,2.5-5.2c-1.3-1.9-3.5-0.6-5.5-0.1c-10.7,2.8-21.2,5.4-31.4-2.3c-6.5-4.9-6.8-4.4-0.3-9.6
            c5.4-4.3,5.5-4.8,1.3-10.1c-1.1-1.4-2.2-2.9-3.8-3.9c-1.6-1.1-2.2-3.1-0.6-4.2c3.9-2.8,6.7-6.9,11.1-8.9c5.5-2.5,11.2-2.8,17.1-2
            c0.2,0,0.4,0.5,0.5,0.7c0.2,1.7-2.3,2-1.7,4.2c6.1,0.9,12.2,0,16.4-4.7c4.1-4.6,2-10-0.4-15c-1.3-2.7-2.6-5.4-4-8
            c-2.9-5.4-4.8-11.3-7-17.1c-0.7-1.7-0.9-3.5,1.4-3.9c2.2-0.4,4.9-1.7,6.5,0.7c3.1,4.5,2.4,9.3-1.6,13.7c5.5,3.3,11.8,4.8,17.2,7.8
            c4.8,2.7,10.5,1.6,15.3,4.2c0.5,0.3,1.2,0,1.8-0.1c-0.9-5.4,2.3-0.6,3.4-1.3c0.8-0.5,2.2,0.5,3.1-0.6c-0.4-1.3-1.7-0.8-2.5-1.3
            c-5.4-3.3-13.2-3-16.3-9.9c-1.6-3.6-3.3-5.3-7-2.7c-0.9,0.6-2.1,1.3-3.2,0.4c-1.2-0.9-0.5-2-0.4-3.2c0.3-2.7,1.5-3.7,4.2-3
            c2,0.5,4.1,1.5,6.4-1.1c-8.7-3.1-17-6-25.1-8.9c5.9-1.4,8.6-2.8,11.9-6.4c1.2,3.8,10.7,9.4,14.6,8.7c1.3-0.3,0.9-3.1,2.8-1.9
            c3.6,2.3,6.1,0.7,8.8-1.8c1.4-1.3,3.5-1.9,5.4-2.3c1.2-0.3,3.5,0.7,2.3,1.9c-3.9,4.1,0,3.9,2.2,5c2.4,1.1,4.8,2.2,6.4,4.5
            c2.1,3.2,4.9,3.2,8.2,1.6c-3.5-5.6-11.8-6.1-13.2-14.5c5.5,3.1,13.5-0.4,15.7,8.5c0.1,0.6,1.3,0.9,2.1,1.2
            c5.2,2,10.5,3.9,15.7,5.9c-3-2.5-5-5.2-4.6-9.2c4.1,1.1,7.6-1.2,10.9-2.7c3.6-1.6,6.7-2.5,10.1-0.3c7.9,5.2,16.7,4.7,25.5,4.4
            c2.4-0.1,4.3-0.6,3-4c-0.8-2.1,2.3-3.1,2.5-5.2c3.8,1.1,5.1-1.8,6.3-4.2c1.5-3.2-3.4-1.8-3.5-4.6c3.5,0.3,6.5-2,10.3-0.3
            c2.4,1,5.5,0.1,6-3.4c0.3-1.9,1.8-3.1,2.4-4.9c-1.3-1.1-2.8-1.5-4.2-1.3c-9.3,1.1-18.5-3.7-28-0.2c-3.5,1.3-7.8,1.4-11.8,1.8
            c-6.3,0.8-12.5,1.2-18.1-3.1c-3.5-2.6-7.9-3.6-12.3-3.1c-3.1,0.3-5.9-1.1-7.5-3c-1.8-2.1,1.4-3.9,2.6-5.8c0.6-1,1.2-2,1.7-3
            c0.3-0.6-0.2-1.8-0.7-1.5c-2.4,1.1-5.9,0.8-7,3.4c-3.8,8.9-9.2,12.8-18.2,12.7c-2.3,0-3.6-0.9-1.8-2.8c4.1-4.5,7.9-9.8,13.7-12.2
            c2.6-1.1,3.7-3.1,5.5-4.7c0.7-0.7,1.5-1.5,0.6-2.5c-0.8-0.9-1.9-0.7-2.7,0c-3,2.4-6.6,2.7-10.3,2.9c-2.8,0.2-5.9,0.7-7.9,2.8
            c-3.2,3.1-6.7,3.1-10.6,2.3c-1.1-0.2-2.6,0.2-2.8-1.4c-0.2-1.2,1-1.6,1.8-2.2c6.5-4.6,14.3-7.7,17.6-16c0.4-1,2-2.1,3.2-2.2
            c8.3-0.4,9.4-8.5,15.1-12.8c-3-0.2-7.4,1.9-4-3.4c1.2-1.8,0.9-3.4-2-2.5c-1,0.3-2.1,0.6-2.7-0.4c-0.9-1.4,0.6-2,1.4-2.8
            c2.3-2.2,4.8-4.1,6.9-6.5c1-1.2,5.2-2.8,0.4-4.9c-1.2-0.5-0.1-1.4,0.3-2c4.3-5.9,15.5-9.8,22.4-7.6c2.5,0.8,4.8,1,7.3,0.5
            c11.3-2.4,21.6-7.5,32.1-12.1c1.9-0.8,4-1.7,4.7-4.6c-2.3,0.6-4.2,1.1-6.3,1.6c0-1.9,4.8-2.3,1.6-4.1c-1.9-1.1-1.5-0.9-0.8-2.3
            c2.1-4.3,6.5-4.9,10.1-6.8c1.1-0.6,3.5-0.5,3-2c-1.3-4.2,1.8-3.5,3.9-4.3c2.5-1,5.1-2.8,7.5-0.7c4.2,3.8,8.6,1.9,12.7,0.5
            c2.3-0.7,4.5-2.2,6.2,0.1c1.6,2.1-0.1,4-1.6,5.6c-0.6,0.6-1.2,1.3-0.7,2.2c0.7,1.1,1.7,0.7,2.7,0.5c3.5-0.5,6.7-2.1,10-3
            c4.1-1.1,8.1-1.8,13-0.6c5.4,1.2,8.9,4.9,13.8,6.4c3.8,1.2,5.1-0.1,6.8-2.5c0.5-0.6,1.2-1.6,1.8-1.6c5.6-0.4,7.8-5,11.1-8.4
            c1.4-1.5,1.3-2.5,0.6-3.9c-0.9-2-2.3-1.3-3.8-0.7c-2.5,0.9-4.9,2.9-7.6,2c-3.6-1.2-7.2-0.9-10.8-0.6c-3.9,0.3-8.1,2.9-10.5-2.9
            c-0.6-1.3-2.9-2.1-4.6-2.6c-3.1-0.9-4.2-2.8-4-5.9c0.2-3.8-1.1-5.4-5.1-3.5c-3.6,1.7-5.2,1-4.4-3.6c1-5.3,3.9-9.5,6.2-14
            c0.6-1.1,1.6-3.3,3.9-1.8l9.5-2.6c0.3-0.7-0.7-1.6,0.1-2.2c7.1,4.7,14.1,9.9,24,4.5c-4.6,7.2,5.4,7.3,4.7,12.3c0.2,0,0.3,0,0.5,0
            c1-1,0.8-2.9,2.7-3.4c0.9,1.2,0.5,4.3,3.2,3.1c1.5-0.7,0.9-3.9,4-4.1c-0.9,3.7-1.3,7,3.8,7.2c0.6,0,1.6,1,1.7,1.6
            c0.2,2.5,1,1.5,2.7,1.2c5-1,10.3,4.2,11,9.4c0.8,6.2-3.2,10.7-4.7,16c-1.2,4.1-5.7,7.7-2.7,13c2-1.6,3.6-3.3,5.6-4.3
            c2.4-1.3,5.5-4.3,7.3-2.8c2.6,2.1,4.2,1,5.4-0.4c4.8-5.4,10.8-9.7,14.8-17.4c-6.1,4.8-10.9,9.4-17.1,12c-2.9,1.2-6.7,3.4-8.9,0.7
            c-2.2-2.6,0.4-5.9,2-8.9c1.2-2.2,4.7-3.2,4.3-4.9c-1-5.1,2.4-8.9,3-13.4c0.2-1.8,2.3-4,3.8-2.9c2.8,2.2,7.3,1,9,3.4
            c4,5.9,8.1,4.1,12.7,1.4c0.3-0.2,0.8,0.1,1.3,0.2c-1.1,1.7-2.1,3.3-3,4.9c3.4-1.6,5.8-4.7,10.2-4.8c-3.7,5.3-5.7,10.8-10.9,14.7
            c-3.9,2.9-7.7,6.2-11.5,9.4c-4.5,3.8-9.5,7-13.4,11.5c-6.3,7-14.2,11.4-23,14.3c-0.8,0.3-1.4,0.7-2.2,0c-4.8-3.9-5.3-7.1-1.8-12.7
            c1.1-1.8,2.6-3.5,3.8-5.2c5.9-8.5,6.2-11,2.1-19.3c-5.2,6.9-11.3,12.9-12.8,22c-0.5,2.9,2,5,0.6,8.1c-2.9,6.7-6.8,12.1-13.7,15.2
            c-1.2,0.5-3.1,0.4-3.4,2.8c1.5-0.1,3-0.2,4.4-0.3c2-0.2,2.8,1.7,1.8,2.2c-5,2.6-5.9,11.1-13.5,9.6c-2.2-0.4-3.4,1-3.5,2.6
            c-0.2,2,1.8,2,3.3,1.9c1.1-0.1,2.8-0.8,3.3,0.3c1.6,3.8,4.3,1.8,6.6,1.5c1.4-0.2,2.9-1,4,1.2c-3.3,1.8-6.9,2.1-10.5,2.3
            c-8.9,0.5-17.4,2-24.5,8.3c-6,5.2-12.1,10.5-20.9,10c-1.8-0.1-4.6,1.7-4.3,2.8c1.3,4.8-3.2,4.8-5,7c-1.7,2.1-4.9,2.6-6.7,6.3
            c3.6-2.6,6.4-2.5,9.4-0.8c2.4,1.4,5.3,1.2,7.8,0.4c5.3-1.7,8.2-7,13.7-9c-2.1-1.6-3.8,0.9-5.7-1.1c4.3-4.8,7.7-10.5,13.5-14.1
            c1.5-1,3.4-1.4,4.6-0.2c1.3,1.2-0.5,2.3-1,3.4c-0.7,1.4-1.5,2.9-0.5,4.2c1,1.4,2.7,0.8,4.2,0.6c1.8-0.3,4.7-1.3,5.1,0.3
            c1.1,4.7,4.5,2.8,7,3c2.2,0.2,4.7,0.2,6,2c1.8,2.5,3.6,1.8,5.6,0.9c2.8-1.2,5.6-2.1,8.7-2.2c1.5,0,3.4-0.3,3.9-1.7
            c1.1-3.2,4.1-4.1,6.3-6c1.3-0.6,2.5-1.2,4-0.9c7-5.2,16-4.4,24.1-8.5c-3.1,4.1-7.2,6.2-9.6,10.2c0.8,0.2,1.2,0.3,1.5,0.3
            c2-0.1,4-2.7,5.9-0.6c2.1,2.2,0,4.2-0.9,6.3c-0.6,1.5-1.8,4.4-0.2,4.8c2.4,0.6,6,2.2,7.7-0.8
            C1080.4,613.4,1085.4,609.5,1087.7,603.8c6.3,0,8.3-5.7,12-9.2c0.5-0.5,0.7-1.3,0-1.7c-0.4-0.3-1.4-0.5-1.8-0.2
            c-3.7,2.6-10.1,2.7-9,9.7C1088.9,602.7,1088.1,603.3,1087.7,603.8z"/>
        <path class="portfolio_main_lines" d="M1519.6,505.7c-2.4-0.5-4.8-0.9-7.2-1.4c-7.2-1.4-14.5-2.7-21.5,0.8c-2.4,1.2-6.7,1.5-6,4.8
            c0.7,3.6,4.8,1.6,7.4,1.9c0.8,0.1,1.7,0,2.5-0.1c12.4-1.7,23.1,3.3,33.7,8.8c4,2,4.4,5.3,2.8,8.1c-1.4,2.5-4.6,1.2-7.2,0.3
            c-7.1-2.4-14.1-5.2-21.6-6.1c-12.4-1.5-23.7,0.6-31.6,11.5c-2.5,3.5-4.8,7.2-6.7,11.1c-16,32.5-29.9,65.9-37.7,101.4
            c-3.7,16.7-12.1,28.8-26.4,37.6c-6.7,4.1-13.2,8.4-19.5,13.1c-15.4,11.7-32,20.9-50.6,26.4c-9.1,2.7-18.1,5.6-27.2,8.4
            c-20.2,6.2-39.3,14.5-56.7,26.7c-1.2,0.8-3,3.2-4,1.5c-1.6-2.9-5.8-5.2-4-9c3.7-7.8-2.3-8.5-6.9-10.4c-0.6-0.3-1.3-0.5-1.8-0.9
            c-3.8-2.7-6.4-1.9-8,2.5c-0.3,0.7,0.1,2.5-1.6,1.5c-2.7-1.7-6.3-0.4-8.8-2.4c-1.7-1.3-2.8-0.7-3.7,0.7c-1.3,2-2.6,3.1-5.3,2.4
            c-2.8-0.8-2.8-2.6-3.2-4.8c-2.4-12-11.5-16-22.4-10.3c-9.6,5-15.7,12.9-20.4,22.8c-0.8-2.7-1.4-5.1-2.2-7.5
            c-0.3-0.9-1-2.5-1.7-1.4c-2,3.2-3.2,0.4-4.1-0.5c-6.7-6.6-14.5-6.6-22.6-3.8c-7.1,2.5-14.1,3.9-21.5,2.2c-1.5-0.4-3.2-0.4-3.7,1.5
            c-0.7,2.9-1.8,5.9-1.5,8.7c0.2,1.8,0.2,2.2-1.5,2.4c-1.1,0.1-2.5,0.3-3.3,1c-2.6,2.4-3.9,1.3-5.1-1.4c-1.2-2.8-3.5-3.2-6.3-2.8
            c-4.6,0.7-6.9,4.3-9.6,7.4c-0.7,0.8-2,2.7-0.5,3.5c1.3,0.7,2.6,3.2,4.7,1c1.1-1.2,1.6,0.2,2.3,0.7c2.3,1.9,0.9,4.7,1.5,7
            c0.2,0.6-1.5,0.6-2.2,0.4c-4.5-1.6-9.4-2.1-13.2-5.2c-2.7-2.2-5.3-0.2-7.9,0.4c-5.6,1.2-0.4,5.3-2,7.8c-1,1.5,1.7,1.3,2.7,2.1
            c3.2,2.8,7-0.1,10.8,0.7c-1.4,2.2-2.6,4.1-4.6,7.3c4.3-2.8,7.7-1.5,10.8-1.5c3.2,0,8,1.3,9,4.7c0.7,2.3-3.7,3.1-6.2,3.9
            c-2.1,0.7-3.1,0-3.4-2.2c-0.2-1.4-0.1-3-1.3-4.5c-3,1.7-6,3.3-9,5c-1.7,1-2.1,2.4,0,3.2c4.2,1.5,8.2,3.7,12.9,2.5
            c4.9-1.2,9.3-2.4,12.2-7.5c2.3-4.1,8.4-5.4,9.2-2.8c1.8,6,4.7,2,7.2,1.7c1.1-0.2,2.5-0.4,3.4,0.1c6.3,3.1,12.5,3.8,19.1,0.4
            c-2.3,1.9-5.2,3.1-3.5,6.9c0.4,0.9-1.2,1.9-2.3,2.3c-2.4,0.9-4.9,1.7-7.3,2.5c-0.6-1.9,2.9-2.8,1-3.8c-1.1-0.6-1.8-2.8-3.5-1.9
            c-3.3,1.7-7.3,2.1-10.5,4.6c9.7,4.9,18.7,1.6,28.4-1.7c-3.1,3.6-1.6,5.2,1.9,6.1c-2,1.3-3.7,1.1-5.3,1.2c-1.2,0.1-3,0.3-2.9,1.4
            c0.5,4.6-3.4,4.8-6,6c-9.5,4.3-19.4,7.7-28.4,13.2c-10.6,6.5-21.2,13.1-32.6,18.3c-5,2.3-5.2,4.8-1.4,8.6c1,1,2.1,2.2,2.8,3.5
            c6.3,11,15.1,20,23.9,29c5.7,5.9,13.2,8.7,19.4,13.8c4.6,3.8,11.1,5.9,16.6,6.2c7.3,0.3,14.3,0.6,21,3.5c1.8,0.8,3.6,0.9,5.3-0.1
            c6.5-4,13-8.1,19.5-12.2c5.3-3.3,11.1-5.2,17.2-5.1c4.5,0.1,8.9,0.8,13.3-0.3c2.8-0.7,5.8-0.8,8.4,0.3c4.5,1.9,10-2.1,14,2.4
            c0.2,0.2,0.9-0.1,1.4-0.3c9.9-3.2,20.2-3.4,30.5-2.7c3.2,0.2,7.3-0.1,9.3,1.7c3.8,3.5,3.6,0.2,4.6-2c3.8,3.9,7.8,4.6,12.5,1.6
            c2-1.3,4.3-1.9,7.1-1.3c7.4,1.5,12.8,8.8,21.3,7.5c0.9-0.1,2.2,1.3,3.2,2.2c7,6.8,15.9,6.7,24,4.8c6-1.4,12.2-5.1,16.3-10.2
            c6.6-8.2,16.1-11.9,24.4-17.6c6-4.1,13-6.1,19.2-10.2c7-4.6,12.7-11.4,21-14c2.3-0.7,4.5,0,6.7-2.2c25.9-25.9,52-51.7,78.2-77.3
            c17.6-17.3,31.8-37.7,49.4-55c7.3-7.2,13.2-15.6,15.8-25.7c2.1-8.1,3.9-16.2,5.4-24.4c1.7-9,0.9-18.3,2.2-27.3
            c3.4-23.3,6.7-46.5,5.7-70.1c-0.9-20.5-2.6-40.9-3.8-61.3c-1.1-20.6-1.8-41.3-7-61.4c-1.6-6-2-12.3-0.8-18.4
            c5.3-27.3,1.5-53.9-6.6-79.9c-7.3-23-11.6-46.4-14.1-70.3c-0.6-5.8-1.5-11.6-2.4-17.3c-1.3-8.6-2.6-17.2,3.2-25
            c0.5-0.6,0.4-1.6,0.7-2.4c2.5-8.2-4.9-11.3-8.8-15.8s-4.5-4.4,0.5-7.6c4.4-2.7,9.3-4.5,13.2-7.9c1.3-1.1,3.7-2.5,1.2-4.3
            c-2.9-2.1-5.9-4.4-10.1-3.4c-8.5,2-17.2,0.5-25.8,1c-1.1,0.1-2.5-0.9-3.2,0.4c-0.6,1.1,0.3,2.2,1,3.2c4.2,6.8,5,14.4,5.2,22.1
            c0.1,2-0.4,4.1-2.8,4.8c-3.2,0.9-4.5,2.1-0.7,4.2c0.8,0.5,0.6,1.5,1,2.2c2.4,4.5-0.9,8-2.1,11.7c-1.3,4.2-2,8.1-0.8,12.6
            c1.3-1.1,1-2.2,0.9-3.1c-0.6-6.1,2.5-10.1,7.2-13.2c2.8-1.8,5.2-1.6,7.4,1.1c2.9,3.6,4.7,7.8,5.3,12.3c1.7,11.9,3.4,23.8,4.3,35.7
            c1,12,0.6,12-10.9,15.5c-0.3,0.1-0.5,0.4-0.9,0.8c3,4.4,7.6,6.9,11.4,10.2c1.3,1.2,2.7,2.1,2.5,4c-0.6,5.1,1.4,9.5,3.5,13.8
            c4,8.1,5.8,16.9,8.5,25.5c3.4,10.8,3,22.1,3.3,33.2c0.2,7.2-0.8,14.5-1.5,21.8c-0.3,2.8-0.8,5.5-5.2,4.2c-2.1-0.6-5.1-0.8-7.1,1.2
            c-3.7,3.4-7.2,0.1-10.8-0.3c-4.4-0.5-7.2-2.2-6.9-7.2c0.1-1.4-1.2-2.3-1.8-3.4c-3-5.7-8.2-9-13.1-12.7c-6.3-4.7-12.8-9-19.7-12.7
            c-1.2-0.7-2.7-1.8-4,0c-0.9,1.3-1.1,2.8-0.3,4.2c0.7,1.1,1.6,2.1,2.4,3.2c3.2,4.5,3.5,8-0.9,10.8c-6,3.9-5.9,10.1-7.7,15.5
            c-1,3.2,1.1,6.6,3.9,7.6s5.2-1.4,6.4-4.3c0.1-0.3,0.2-0.6,0.3-1c2.1-6,2.9-6.5,8.3-2.7c10.2,7.2,21,13.1,33.1,16.7
            c2.8,0.8,6.3,2.2,2.4,6.1c-1,0.9-0.6,2.1-0.1,3.3c1.2,2.6-0.4,3.8-2.6,4.3c-2.6,0.7-5.2,1.1-7.8,1.6c-8.9,1.5-17.4,4.4-25.5,8.5
            c-1.1,0.6-2.8,1.1-2.3,2.8c0.5,1.7,2.4,1.8,3.8,1.5c2.9-0.7,5.7-1.9,8.6-2.5c7.3-1.5,14.2-0.9,20.8,3.4c-1.4,1.3-2.4,0.8-3.4,0.7
            c-15-1.4-27.8,3.7-39.2,13.1c-2.2,1.8-5.9,4.1-4.1,7.1c1.7,2.8,5.1-0.1,7.6-0.9c4.5-1.4,9.7-4.1,13.5-1.8
            c5.4,3.4,11.1,5.7,16.6,8.7c0.1,0.1,0,0.6-0.1,0.9c-1.9,0.9-3.9,0.5-5.9,0.5c-4.3,0-8.7-0.4-12.8,1.3c-3.2,1.3-8.3,2.5-7.8,5.8
            c0.6,3.7,5.7,3.6,9.4,3.6C1508.2,500.6,1514.3,501.3,1519.6,505.7z"/>
        <path class="portfolio_main_lines" d="M752.8,183.5c-9.1-4.5-9.2-4.6-13.4,3.6c-1.3,2.4-2.9,3.7-5.8,4.1c-8.8,1.2-17.4,1-26.3,0.6
            c-10.2-0.5-20.5,0.4-30.7-0.2c-17.7-1.1-35.3,1.3-52.9,1.9c-7.6,0.3-15.2,1.6-22.7,1.6c-11.5,0-22.9-0.1-34.4,0
            c-7.9,0.1-15.9-0.5-23,4.6c-2.4,1.7-6,2.5-9,2.6c-4.3,0.1-6.8,2.3-8.8,5.5c-1.7,2.9-4.6,5.1-7.3,3.9c-7-3.2-12.5,1.5-18.6,2.6
            c-3.4,0.6-6.2,1-8.9-1c-4.1-3-9.3-4.2-13.7-3.5c-8.3,1.4-36.2-2.9-40.6-0.4c-2.4,1.3-4.9,0.2-7.2-0.8c-2.7-1.2-5.1-4-7.9,0.2
            c-0.9,1.3-2.8-0.2-4-0.9c-2.9-1.9-5.7-2.9-9.3-1.4c-2,0.8-67.8-1.6-69.5-2.8c-2-1.5-4.3-3.1-6.9-2.5c-5.6,1.3-11.1-0.7-16.5-1.3
            c-7.5-0.8-15-2.7-22.6-3.9c-4.6-0.7-7.8,0.1-10.3,3.9c-1.5,2.4-3.4,4-6.4,4.2c-3.4,0.2-4.4,2.1-3.8,5.5c0.9,5.3-5.3,13-10.6,12.7
            c-6.3-0.4-13.1,1.7-18.8-3c-0.3-0.3-1-0.4-1.4-0.3c-1.4,0.2-3.1,1.4-3.9-0.6c-0.9-2.1,1.3-2.8,2.3-4c4-4.5,3.7-7.3-1.7-9.8
            c-2.1-1-2.4-2.2-1.3-3.9c1.8-2.8,0.2-3.1-2.1-3.8c-5.7-1.8-10.3,1.7-15.4,2.8c-2.9,0.6-5,0.9-5.8,4.8c-0.4,1.8-2.6,3.1-2.8,6.3
            c-0.3,3.2-1.9,5.7,1.5,8c0.2,0.1-0.1,0.9-0.2,1.4c-1.1,4.3,0.5,6.7,4.8,7c21.1,1.5,42.4-0.1,63.5,2.1c2.7,0.3,5.3-1,8.3-0.6
            c6.9,0.9,14.1,2.1,20.7,0.8c7.5-1.5,14.5,0.2,21.7,0.1c13.3-0.2,106.3,2.1,123.6,1.9c11.5-0.1,22.9,0.9,34.4,0.5
            c15.3-0.6,30.5,1.7,45.7,2c13.3,0.3,26.5,0.7,39.8,0.4c6.6-0.1,33.9,0,41,0.1c14.9,0.3,29.9,2.7,44.8,0.8c3.9-0.5,7,0.8,9.1,4.6
            c2.1,4,3.9,8.4,7.3,11.7c0-2-0.2-3.9-0.9-5.7c-1.3-3.4-1.6-6.8-0.9-10.3c0.2-1.1,0.1-2.6,1.6-2.8c1.7-0.3,2.3,1.3,2.6,2.5
            c0.5,2.4,0.5,5,1.2,7.4c0.4,1.5,1.4,3.4,3.5,2.8c1.8-0.5,1.7-2.3,1.6-3.8c-0.2-2.4-1.4-4.8,0.6-8.4c0.4,6.1,3.3,4.3,6.4,3.5
            c3-0.8,6.2-2.4,9.2,0.9c1.4,1.5,3.5,0.8,4.7-0.9c0.9-1.3,2.5-2.8,1.5-4.4c-1.1-1.7-3-0.8-4.6-0.3c-0.5,0.2-1,1-1.7,0.1
            c1.7-4.7,3.8-9.2,7.1-13c0.8-0.9,1.5-1.9,2.9-1.4c1.2,0.5,1.8,1.5,1.8,2.8c0,2-0.4,4-0.4,6c0,1.4-0.6,3.2,1,4.1
            c1.5,0.9,2.4-0.6,3.4-1.4c2.4-1.8,4.6-1.3,5.9,1.2c1.9,3.8,4.9,7.3,0.6,11.9c-2.6,2.8-3.8,6.9-6.1,10.2c-1.1,1.5-1.4,3.5-0.6,5.3
            c1.5,3.6,3.1,7.2,4.8,10.9c1.8-1.4,2-3.3,2.7-4.9s0.6-4.1,2.8-4.2c2-0.1,2.1,2.2,2.9,3.6c0.2,0.4,0.7,0.6,1.1,0.9
            c3.7-5.7,3.2-13.6-1.3-19c-1.1,2.7-0.9,5.8-3,7.8c-0.5,0.5-1.4,1.2-2,1.1c-1.1,0-1-1.2-0.9-2c0.6-5.7,2.5-10.8,7.9-15.1
            c-0.5,4.3-0.1,8.5,3.7,9.2c4.3,0.8,4.6-3.8,5.9-6.7c1-2.3,2.9-3.2,4.8-1.9c4.7,3.1,9.6,6,14.9,7.9c1.5,0.5,2.2,2.4,1.7,3.8
            c-0.8,2.2-2.5,0.5-3.8,0.4s-2.6-0.5-3.9-0.6c-3.5-0.3-5.8,2.1-7,4.7c-1.3,2.8,1.9,3.7,3.6,5.1c2.4,2,4.2,3.1,6-0.8
            c1.2-2.8,4.3-2.9,6.9-3.1c1.8-0.2,3,0.9,2.7,2.7c-0.8,4.4-0.3,9-1.7,13.4c-0.6,1.8-3.4,4.5-0.2,5.4c2.5,0.7,5.9-0.7,7-4.2
            c0.4-1.4,0.7-3.1,0.4-4.4c-2.3-8.5-2.3-17.5-4.6-26c-0.6-2.1-0.6-4.2-0.3-6.4c0.8-6,0.2-12.1-1.6-17.8c-1-3.3-5.7-6.3-5.3-8.9
            c1.3-7.8-5.4-7.9-8.9-10.8C746.6,193.1,746.3,188.2,752.8,183.5z"/>
        <path class="portfolio_main_lines" d="M1577.8,194c-6-1.6-11.7,2.1-17.9,3.8c-1.1,0.3-2,1.2-1.4,2.2c2,4,3.3,9.4,6.6,11.4
            c5.8,3.5,12.8,5.6,19.6,6.8c3.9,0.7,7.8,1.1,11.4,2.9c5,2.4,10.2,4.1,15.6,4.8c3.4,0.4,6.4,1.6,9.4,3c0.9,0.4,2.3,0.5,2,1.8
            c-0.3,1.5-1.8,1.3-2.9,1.2c-1.2-0.1-2.4-0.1-3.4-0.6c-5.4-2.8-11.1-4.1-17.2-3.8c-8.7,0.5-16.5-3.5-24.9-4.3
            c-3.7-0.4-5.8,1.1-7.7,4.1c-1.8,3-1.1,5.4,0.5,7.8c3,4.7,2.6,6.5-2.5,7.8c-4.7,1.2-6.2,4.2-6.4,8.6c-0.2,4.9-0.8,9.6-4.9,13.1
            c-1.4,1.2-2.3,2.9-2.6,4.8c-0.2,1.1-0.4,2.5,0.8,3.2c1.2,0.6,1.5-0.9,2.3-1.4c4.2-2.8,12.6-0.3,13.2,4.6c1,8.7,5.2,17.4,0.4,26.3
            c-1.9,3.5-3.3,7.2-5.7,10.4c-2.6,3.6-1.4,6.7,2.9,6.3c7.5-0.8,12.6,4.1,18.3,6.9c4.8,2.4,5.3,1.5,6.6-2.6c1.1-3.6,3.6-6.5,7.7-7
            c3.9-0.4,5.8,2.8,7.7,5.5c1.2,1.8,2,3.8,3.6,5.3c7.8,7.3,10.7,16.8,11.4,27.1c0.1,1.5-0.6,3.8,2,3.7c2.5,0,3.8-2.1,3.2-4.1
            c-1.9-6.7,3-10.1,6.6-13.8c4.2-4.2,4.1-7.4-1.2-10.4c-2.2-1.2-4.5-2.2-6.7-3.3c-3.9-1.8-4.3-5.4-4.1-8.9c0.2-3.5,3.1-5.4,6-5.9
            c5.5-0.9,8.9-4.2,11.5-8.7c2.7-4.8,7-6.7,12.3-7.3c7.6-0.8,15.2-1.7,22.6-4c2.2-0.7,4.5-2,6.6-1.9c7.5,0.4,14.9-0.4,22.3-1.3
            c4.5-0.6,9.3-0.9,13,2.1c4.8,3.9,9.6,5.8,16,5.1c6.8-0.8,9-2.8,9.1-10.8c0.1-4.2-2.3-5.8-9-5.9c-5.5-0.1-10.9,0.5-16.2-1.8
            c-5.8-2.5-6.3-3.3-1.6-7.5c2.6-2.3,3.2-4.6,1.8-7.9c-4.2-9.7-10.4-16.8-21.4-18.9c-6.4-1.2-12.6-3-18.9-4.5
            c-1.1-0.3-2.6-0.4-2.6-1.8c0-2.1,1.8-1.5,3-1.5c11.1-0.3,22.2-0.7,33.3-0.2c7.2,0.4,13.7-0.7,18.4-7.4c6.3-9,10.2-19.4,16.5-28.4
            c1.2-1.7,0.4-3.2-1.6-3.9c-1.7-0.6-3.5-1.3-5.3-1.4c-10.2-0.6-20.4-1.4-30.7-1.3c-19.4,0.1-38.8,1.5-58.1,0.7
            c-14.1-0.6-28.1,2.6-42.3,0.2C1596.4,191.7,1587.5,193.8,1577.8,194z"/>
        <path class="portfolio_main_lines" d="M1258.4,202.9c-2.6,2.3-5.4,2.7-7.8,3.7c-5.1,2.2-8.2,1.3-10.3-3.7c-3.4-8.1-8.7-15.5-8.3-24.8
            c0-0.7,0-1.5-0.8-1.5c-0.6,0-1.3,0.4-1.7,0.8c-1.2,1.3-0.8,2.7-0.3,4.2c1,2.8,2.1,5.6,2.8,8.5c1,3.9,0,5.5-4.8,6.1
            c-8.7,1-16.9-2.9-25.6-1.5c-7.3,1.1-14.7,1.9-22.1,2.3c-7.6,0.4-15.2,0-22.8,0.1c-9.6,0.2-19.2,0.4-28.7-1.1
            c-5.3-0.8-10.6-1.8-15.8-0.5c-6.8,1.7-13.3,0.5-19.7-1.1c-2.7-0.7-3.2,0.3-4.5,2.1c-4.2,5.6-6.8,12.7-13.8,16
            c-2.2,1.1-4.6,2.1-6.6,3.5c-5.7,4.1-11.9,6.5-19.1,5.8c-2.8-0.2-4,1.4-3.1,3.6c1.8,4.3,0.9,8.4-0.2,12.6c-0.3,1.2-1,2.8,0.2,3.8
            c1.6,1.4,2.6-0.3,3.8-1.1c1.5-0.9,3.2-0.8,4.9-0.8c8.8,0,17.7,0,26.5,0c13.8,0.1,27.7,1,41.4-0.1c12.8-1,25.6-0.5,38.4-0.5
            c11.4,0,22.8,0.1,34.2,0.5c12.5,0.5,25-0.3,37.4,0.6c6,0.4,12,0.1,18.1,0.1c-0.2-0.4-0.3-1-0.6-1.2c-8.2-4.8-15.4-10.8-22.3-17.2
            c-0.8-0.8-2.2-1.1-2.2-2.5c0-1.3,1.2-1.4,2.2-1.6c6.7-1,13.5-2.3,20.2-3c3.9-0.4,6.8-1.3,8.9-5.1c1.6-2.9,3.7-6.2,7.7-6.5
            c10-0.7,17.1,4.7,21.4,13.9c2.6,5.6,2,8.8-3.9,11.2c7.3,0.3,14.2,0.5,20.6-3.7c-0.8-0.6-1.2-1.1-1.6-1.2c-3.3-1-4.8-3.4-3.6-6.5
            c1.3-3.5,4.5-3.9,7.5-2.6c4.5,1.9,9.2,3.9,13.9,3.3c5.7-0.8,7.9,1.5,9.1,6.3c0.8,3.3,3.6,2.8,5.7,2.2c2.9-0.8,2.1-3,0.8-4.7
            c-1.7-2.4-1.1-4.7,1.3-5.1c5.4-1,10.5-3.2,16.6-1.5c5.7,1.6,11.3,3.8,17.3,4.7c1.2,0.2,2.4,0.7,3.4,0c2.6-1.9,2.5-1.9,4.1,1.2
            c1.7,3.2,3.6,5.7,8.1,4.8c4.2-0.8,6-2.8,7.6-7.4c1.6-4.6-0.2-7-3.3-9.8c-2.3-2-4.8-2.8-7.7-3.2c-3.4-0.5-6.8-0.6-10-2.6
            s-8.2,0.4-9.7,3.9c-0.5,1.2-1.3,2.4-1.6,3.6c-1.1,4.6-3.4,3.8-5.9,1.4c-1.7-1.6-3.4-3-5.9-3.3c-0.8-0.1-2.3-1.2-2.3-1.6
            c0.6-4.4-2.5-4.6-5.7-5.1c6.2,0.4,9.2-5.6,14.5-6.5c0.9-0.2,1.7-1.5,1.3-2.4c-1.1-2.5-0.7-5.8-2.9-7.8c-2.5-2.3-2.1-4.2-0.3-6.5
            c4.4-5.4,0.5-11.2,0.7-16.8c0-0.2-0.9-0.6-1.2-0.5c-4.8,1.9-10.9-1.6-15.1,4.2c-2.3,3.2-10.7,2.7-13.4-0.7
            c-1.7-2.1-0.6-5.4,1.2-7.8c0.1-0.2-0.1-0.6-0.2-1.2c-0.9,0-2.2-0.4-2.8,0.1c-3.5,2.3-6.1,1.2-8.6-1.7c-1.6-1.9-4.3-3.2-6.2-1.3
            c-2.1,2.1,0.2,4.7,1.7,6c2.7,2.3,2.4,4,0.3,6.1c-3.5,3.6-6.1,7.4-2.3,12.4c0.4,0.5,0.3,1.3,0.4,1.9c1.5,5.6,5.7,7.3,10.7,4.5
            c0.9-0.5,1.8-0.8,2.7-1.2c3.3-1.4,6.8-2.7,9.8,0.1c2.9,2.8,1.9,6.4,1.2,9.9c-0.5,2.9-2.6,3.6-4.7,4.6c-2.3,1-3.7-0.5-5.1-2.1
            c-0.7-0.8-1.4-2.7-2.5-1.6c-4.9,4.8-10.6,2.2-16,1.8c-2.9-0.2-6.1,0.5-8.4-2.3c-3-3.8-6.5-5.4-11.4-3.5c-2.1,0.8-3.9,0.4-2.5-2.6
            c1.1-2.3-0.3-3.3-2-4.3c-6.4-3.9-13.1-4.4-20.5-4c-12.4,0.6-10.9,3.1-7.6,12C1252.8,194.6,1252,200.7,1258.4,202.9z"/>
        <path class="portfolio_main_lines" d="M523.9,621.2c-0.5-2.2-4.8-1.2-3.9-3.6c1-2.6,4.1-1.1,6.3-1c5.6,0.1,9.4,3.9,13.6,6.8
            c1.3,0.9,2.6,2.8,4.3,1.5c1.3-1,1.7-2.7,1.7-4.5c0.1-3.2-0.9-5.7-4.2-6.4c-3.7-0.8-6.2-3.5-9.3-5.3c-2.7-1.6-1.2-2.5,0.5-3.3
            c5.7-2.8,21.1-0.3,26.2,4.3c5.8,5.2,10.8,11.2,12.8,18.7c2.1,8.4,7.6,14.5,12,21.4c1.1,1.8,2.7,2.9,4.6,3.6c1,0.4,2.1,1.4,3.2,0.3
            c0.9-0.9,1-2.3,0.4-3.3c-4.6-8.3-6.3-17.1-5.7-26.6c0.3-4.1,5.5-12.4,9.8-12.6c1.3-0.1,2.7,0.1,3.5,1.4c1.4,2.2,3.4,2.5,5.7,1.9
            c5.6-1.6,11.3-1.2,16.9-0.8c5.4,0.4,11.3,1.1,14.6,6.3c3,4.7,3.7,10.6,7,15.3c1.1,1.6-0.1,3.2-1.1,4.5c-0.6,0.8-1.5,1.6-0.4,2.6
            c1.1,0.9,2.3,0.6,3.1-0.4c0.8-0.9,1.8-1.9,1.9-2.9c0.4-5.6,3.8-6.3,8.3-5.6c1.3,0.2,2.9,0.2,3.1-1.6c0.2-1.4,0.5-3.3-1.5-3.8
            c-3.5-0.8-6.1-3.5-9.4-4.5c-4.1-1.3-6.3-4-7.4-7.9c-1.3-4.5-4.1-8-8.3-10.1c-14.6-7.2-29.2-10.5-44.6-1.2
            c-5.3,3.2-13.9,1-13.2-8.8c0.1-1.9,1.1-3.9-0.7-5.9c-2.4,1.7-1.7,4.2-1.7,6.3c-0.1,6-1.6,7.1-7.1,4.4c-4.3-2.1-8.3-5.1-12.8-6.6
            c-8-2.6-16.4-2.8-24.6-0.7c-3.6,0.9-7.1,1.5-10.8,0.7c-3.6-0.8-6.6,0.1-9.6,2.7c-3.9,3.5-8.2,7-12.9,9.1c-2.4,1-3.8,1.9-4.9,4
            c-1.2,2.3-4.1,3.5-4.8,6.8c9.8-4.9,16.1-14,26.6-17.5c0,3.7-2.2,4.8-4.2,5.8c-7.1,3.2-13.3,7.6-17.6,14.3c-0.6,1-1.9,1.8-1.4,3
            c0.8,1.8,2.3-1.2,3.6,0.6c-1.5,2.5-2,6.2-4.9,7.4c-1.9,0.7-0.7-3.2-2.6-4c-2.7,5.8-1,26.3,2.6,29.2c0.9-3.6,0.8-7.4,2.7-10.6
            c1.3-2.2,1.7-3.8-1.5-4.9c-1.7-0.6-1.9-2.2-1.2-4.1c2.7-6.9,7.4-11.8,14.3-14.4c2.1-0.8,4.3-1.8,6.3,0.1c-0.6,2.3-4,1.6-4,4.8
            C510.4,625.7,518,627.7,523.9,621.2z"/>
        <path class="portfolio_main_lines" d="M1643.2,478c-1.9-2.1-0.4-6.2-3.9-6c-3,0.2-5-0.8-6.8-3c-1.2-1.4-3.1-2.2-5-1.3c-1.9,0.9-2.2,2.8-2,4.7
            c0.2,1.8,0.4,3.7,1,5.4c1.9,5.1,2.7,10.2,2.5,15.6c-0.1,2.9-0.1,5.9,2.5,8.3c4.9,4.5,10.2,8.5,16,11.8c7.3,4.2,10.9,1.9,11.8-6.5
            c0.8-8.6-1-16.5-5.2-23.9c-1-1.8-1.7-3.4-0.9-5.6c2.1-5.6,6.6-9.9,8-15.9c0.5-2.1,2.3-1.7,3.7,0.2c2.5,3.5,4.7,7.4,7.8,10.1
            c4.5,3.8,10.5,4.7,16.2,5.7c2.6,0.4,5.4-0.2,7.2-2.5c2-2.6,4.8-3.7,7.8-4c5.8-0.6,9.5-3.6,11.3-9c0.5-1.5,1.2-2.7,2.8-3.2
            c3.9-1.2,7.9-2.6,11.8-3.7c4.5-1.3,10.2-7.9,9.9-13.2c-0.3-4.7-3.2-8.2-7.9-9.8c-2.3-0.8-4.2-2-5.7-3.8c-3.8-4.5-4.1-4-7.3,1.2
            c-2.9,4.6,0.3,11-5.1,14.6c-0.8,0.5,0,2.2,0.9,3.1c2.7,2.9,1.4,5.3-0.8,7.8c-3.5,3.9-8.2,5.7-13,7.1c-4,1.2-9.1-3-8.6-7.2
            c0.5-4.9-1.3-9.1-2.6-13.6c-0.5-1.6-1.7-2.9-3.2-2.8c-4.9,0.5-10.6-0.8-13.6,5.1c-0.5,1.1-1.2,2.3-2.6,2c-5.8-1.1-6.1,3.4-6,6.8
            c0.1,5.2-2.5,3.2-4.6,2.2c-1.7-0.7-2-2.5-2.3-4.2c-0.2-1.3-0.4-2.8-2.1-3c-3.7-0.4-8.3,3.3-9.9,7.1c-2,4.5-1.6,10.1-6.9,13.4
            C1643.3,467.8,1645.5,472.6,1643.2,478z"/>
        <path class="portfolio_main_lines" d="M1067.4,148c-5,0-8.7-0.3-12.3,0.1c-2.9,0.3-7.8-2.2-8.3,1.5c-0.7,4.7,5.1,3.6,8,4.5c3.3,1.1,7,1.7,7.9,5.1
            c1.1,4.1,3.7,4.1,6.8,4c11.6-0.4,23.2-0.9,34.8-0.6c9.6,0.2,19.3-0.8,28.8,0c9.8,0.8,19.5,0.7,29.2,0c2.2-0.2,4.1,0,4.9,2.3
            c1.7,4.9,4.5,3.4,7.3,1.3c2-1.5,3.5-3.4,6.4-3.3c10.1,0.3,20.2-0.5,30.3-0.4c11.9,0.2,23.8,0.7,35.8-0.2
            c10.3-0.8,20.8,1.3,31.1-1.7c1.5-0.4,3,0.2,4.4,0.7c3.3,1.3,7.4,1,8-2.2c0.6-3-3.4-4.3-6.5-4.8c-18.2-2.8-36.6-4.8-55-5
            c-12.4-0.1-24.9,0.1-37.3,0.6c-14.6,0.6-29.1-1.8-43.7-0.8c-7.4,0.5-15-1.3-22.4-0.7c-8.8,0.8-17.6,0.3-26.3,0.2
            C1088.3,148.3,1077.1,149.1,1067.4,148z"/>
        <path class="portfolio_main_lines" d="M392.4,643.5c5.4,4.5,10.3,8.7,17.9,5.6c2.2-0.9,5-0.6,7.4-0.4c1.6,0.1,4.4,1.6,3.5,2.7
            c-2.7,3.2,0.1,2.7,1.4,2.4c8.1-1.5,15.1,1.8,22,5c3.4,1.6,6,2.8,8-1.7c1.9-4.3,0.1-6.4-3.7-8.2c-5.5-2.5-10.9-5.1-17-5.3
            c-1.3,0-4.2-0.8-3.8-1.3c2.5-3.7,0-8.3,2.8-11.9c5.7-7.1,10.9-14.6,17.8-20.8c5-4.5,6-4.7,8.6,1c5.7-9.5,8-20.6,15.3-29
            c-6.8-3-7.6-3-11.1,3c-2.9,5-6.3,9.2-12.2,11.1c-2.9,0.9-3.7,0.3-3.6-2.4c0-0.9,0.7-2-1-2.8c-1.4,1.1-2.2,2.7-2.4,4.5
            c-0.3,3.1-2.2,5.6-2.9,8.4c-1.3,4.9-3.6,3.6-6.8,2.2c-1.7-0.8-2.2-2.1-1.6-3.1c1.5-2.5,1.6,2.1,3.2,0.7c-0.1-5.6-3.9-4.5-6.8-3.3
            c-5,2.1-9.7,5-15.4,4.1c-5.7-0.8-11,0.5-15.4,4.2c-2.6,2.2-4.5,5.2-7.8,6.7c3.3,2.2,8.1,0.5,10.6-3.4c2.3-3.5,8.8-6.2,12.5-5.1
            c1.3,0.4,2.7,0.6,2.6,2.5c-0.1,1.5-0.8,2.1-2.3,1.7c-0.7-0.2-1.7,0.2-1.4,0.8c1.4,2.6,3,5,4.7,7.4c0.2,0.2,1.1,0.3,1.3,0
            c0.4-0.5,0.8-1.3,0.7-1.7c-1.8-4.8,1.3-6,5-7c-0.2,3.5,3,4.8,4.3,7.5c1.3-1.4,0.8-2.9,1.2-4.1c0.4-1.5,1.1-3,2.7-3.3
            s2.6,0.8,3.6,2.2c1.7,2.4,2,4.5,0.3,7.3c-3.8,6.1-8.7,11.6-10,19.2c-0.5,2.9-4,3.7-6.5,3.3c-2.9-0.4-1.3-3.2-1.5-5
            c0-0.4-0.4-0.8-0.7-1.1c-6.7,4.8-6.7,4.8-8.8-3.4c-0.2-0.6-0.5-1.2-0.9-1.8c-0.6-1,0.9-3.3-1.4-2.9c-2.1,0.3-2.6,3-1.8,4
            c3.3,3.8,0.9,12.1,9.7,11.4c-3,0.8-6,3.7-7.9,0.3c-2.8-4.9-4.9-10.3-7.3-15.5c-0.4-0.9-0.1-1.6,0.5-2.3c1.5-1.6,9.3-2,10.9-0.3
            c0.7,0.8,1.2,1.7,2.4,0.9c1.2-0.8,1.2-2,0.4-3.1c-1.2-1.7-2.9-2.7-5-2.9c-5.4-0.6-10.2,1.5-12,6.2c-3.1,8-8.6,5.4-14,4.7
            c-0.8-0.1-1.8-1.2-2.5,0.1c0.5,1.2,1.6,1.1,2.5,1.4c2.4,0.6,6.3-0.3,6.5,3c0.2,2.7-3.5,2.3-5.6,2.7c-1.3,0.3,0.4,2.4-1.6,2.1
            c-1.2-0.2-2.4,0.2-3.6,0.3c1.4,4.3,4.8,3.5,7.9,3.3c1.7-0.1,3.8-1.8,5.1,0c1.5,2-1.2,3.5-1.6,5.4c-0.3,1.4-2.2,2.2-1.8,3.8
            c1.2,0.8,2-1.3,3.2-0.1c-0.6,1.9-1.4,3.7-1.8,5.7c-0.4,2.1-1.6,3-3.5,2.5c-5.8-1.5-11.6,0.3-17.3-0.2c-14.2-1.1-23.7,7.4-33.7,15
            c-0.1,0.1,0,0.3,0,1.1c7.1-4.5,15.1-0.3,22.3-3.3c0.7-0.3,1.4,0,1.6,0.8c0.8,3.5,4.4,3.8,6.6,5.7c0.9,0.8,2.3,2.6,3.7,0.7
            c1.1-1.4,1-3.1-0.2-4.6c-1.2-1.6-2.6-3-3.7-4.7c-2.8-4.5-2.3-5.5,2.9-6.5c0.8-0.2,1.7-0.2,2.5-0.4c6.8-1.6,10,0.9,9.9,7.9
            c0,2.1-0.7,4-1.6,5.7c-2.3,4.3,2.1,7.3,2,11.1c0,0.1,0.9,0.5,1.2,0.4c5-2.4,6,2.3,7.7,4.8c4.5,6.3,9.9,11.6,16.3,15.9
            c1.6,1.1,3.2,1.7,5.1,1.5c0.9-0.1,1.7-0.5,1.9-1.4c0.1-0.5-0.4-1.3-0.9-1.6c-6.7-4.3-7.5-10.9-7.5-17.9c0-1.1,0.8-2.6-0.7-3.1
            s-1.8,1.1-2.4,2c-0.8,1.2-1.6,2.2-3.2,1.8c-0.9-0.2-1.6-1-1.4-1.8c0.5-2.8-1.7-5.2-1.2-7.8c1.2-6.8-1.7-12-5.7-16.9
            c-0.5-0.6-1.9-0.6-1-2.2c3.5-6.2,7.7-12.4,4.9-20.2C391.9,644,392.1,643.9,392.4,643.5z"/>
        <path class="portfolio_main_lines" d="M445,831.2c-2,1.8-4.7,1.8-6.3,4c-7.7,10.9-19.3,15.3-31.4,18.9c-14.2,4.1-28.4,8.4-41.6,15.3
            c-9.9,5.2-19.9,10.2-28.2,17.8c-3.1,2.8-5.9,5.9-5.8,10.6c0,0.9-0.6,2.5,0.6,2.6c5.2,0.6,2.1,3.6,1.2,5.1
            c-3.4,6.3-7.1,12.4-10.8,18.5c-0.6,1-1.6,2-0.4,3.1c2.8,2.4,5.1,5.9,9.8,3.9c2.5-1.1,5.4-1.3,8.1-2.2c5.8-2.1,8.4-7.6,12.6-11.3
            c1.6-1.3,4.5-4.4,3.1-6.2c-2.3-3.2-2.9-7.3-5.5-9.9c-2.4-2.4-2.3-3.7,0.4-4.7c3.4-1.3,5.6-3.8,7.6-6.3
            c5.5-6.6,12.6-11.7,20.2-14.1c12.2-4,23.1-11,35.7-14.1c5.1-1.2,9.7-4.2,14.5-6.5c1.8-0.9,4-2.3,2.4-4.6c-2.4-3.5-0.4-4.1,2.4-5
            c5.9-2.1,12-4.1,14-11.2c-1.5-0.8-2.9,1.8-4.4,0.2C443.4,833.5,445.4,833.2,445,831.2z"/>
        <path class="portfolio_main_lines" d="M818.1,238.4c6.3-0.9,12-4.2,15.7-8.8c6.9-8.5,5.8-10.7-0.8-17.2c-5.6-5.6-10-12-13.5-19
            c-1.6-3.1-2.3-7.1-7.3-3.6c-1.4,1-3.6-0.2-5.3-0.7c-3.7-1-7.8-1.3-10.6,0.9c-7.5,5.8-14.4,12.3-17.7,21.6
            c-2,5.7,1.5,11.7,7.5,12.3c5.7,0.6,8.2,3.8,9.6,8.5c0.7,2.1,1.8,4,4.1,3.5C806.2,234.6,811.8,238.4,818.1,238.4z"/>
        <path class="portfolio_main_lines" d="M1662.8,550.4c7.3,5.9,7.7,8.7,2.2,16.3c-1.2,1.6-1,3,0,4.6c2.7,4,10.2,5.9,15.2,3.7
            c5.8-2.5,7.8-6.7,6.4-12.8c-0.7-2.9-1.4-5.8-1.9-8.8c-0.7-3.7,0-7.5,3.2-9.3c5.7-3.3,10.9-2.1,14.1,2.3c1.4,2,3.1,3.9,3.9,6.4
            c1,3,4,2.8,6.4,2.7c2.5-0.1,2.1-2.5,2.3-4.3c0.1-1,0.2-2,0.2-3c-0.1-3.6,1.9-6.4,4.1-8.8c2-2.1,3.9,0.6,5.9,1
            c0.6,0.1,1.3,0.1,2,0.1c5.1,0.6,7.6,3.8,6.6,8.9c-0.3,1.5-1,2.8-1.4,4.3c-0.6,2.6-0.2,5.3,2.3,6.6c2.6,1.4,6.4,1.4,7.8-1.1
            c2.5-4.6,6.5-9,5.6-15c-0.5-3.7-1.5-6.6-5.5-8c-3-1-5.6-3.2-7-6.1c-2.7-5.8-6-6.9-10.6-2.7c-1.7,1.5-2.7,2.5-3.6-0.6
            c-1.4-4.8-7.9-4.5-9.5-9.3c-0.2-0.7-2.2-0.2-3.3,0.2c-2.3,1-4.4,2.3-5.9,4.5c-2.3,3.5-5.4,5.3-9.8,4.2c-1.6-0.4-3.3-0.3-5-0.1
            c-6.1,0.7-6.7,1.8-4.7,7.7c0.7,1.9,1.5,3.7,1.9,5.7c0.3,1.5,0.3,3.3-2.3,2.1c-4.5-2-8.2,0.3-11.3,3
            C1668.4,546.7,1665.9,548.6,1662.8,550.4z"/>
        <path class="portfolio_main_lines" d="M735.1,832.7c-2.2-0.6-3.9,0.3-5.3-0.8c-4.7-3.8-10.6-4.3-16-6.1c-8.2-2.8-16.3-0.6-24.4-0.7
            c-4.5,0-9.2,0.6-13.9,2.7c-4.2,1.8-9.5,4.7-14.9,2c-0.7-0.3-2.2,0-2.8,0.6c-3.7,3.7-8.3,6.5-8.9,12.6c-0.3,3.2-1.3,6.7-2.9,9.5
            c-1.6,2.8-0.7,5.4-1.2,8c-1.1,5.1,3.4,4,5.5,5.4c1.6,1.1,2.6,2.6,3.6,4c1.1,1.5,2.9,2.5,4.2,0.8c3.1-4.2,8.7-6,11.1-10.6
            c6.3-12.4,17.4-16.6,30-18.7C711.1,839.6,722.9,837,735.1,832.7z"/>
        <path class="portfolio_main_lines" d="M1404.5,276.7c-0.4,2.3-0.5,3.4-0.7,4.6c-0.9,4-0.9,8.5-7.2,8c-2.4-0.2-3.3,2.5-4,4.6
            c-0.9,2.7-1.7,5.4-2.7,8.1c-0.3,1-0.9,1.9-2,2c-1,0.1-1.5-0.7-1.8-1.5c-1.9-6-4.1-11.9-0.6-18.3c2.7-4.9,4.7-10.3,6.9-15.4
            c0.6-1.5,1.2-3.1-1.1-3.4c-4.9-0.8-9.9-1.5-14.8-2c-3-0.3-4.1,1.2-2.4,4c1,1.7,2.3,3.2,2.8,5.2c2.4,9.9,0.4,19.4-2.8,28.9
            c-2.1-1.5-1.4-5.9-4.4-5.3c-2.5,0.5-1.7,4.1-2.2,6.2c-1,3.9,3.7,1.2,4.9,3.4c-3.3,1.5-6.3,3.4-9.6,4.1c-2.2,0.5-1.1-3.7-3.3-4.3
            c-0.4-0.1-0.9-0.1-1.7-0.1c3,8.9,5.8,17.5,8.8,26.1c0.4,1.2,1.6,2.1,2.2,3.3c0.8,1.6,1.9,3,2,5c0,5.2,0.4,10.3,0.6,15.5
            c0.1,1-0.7,2.8,1.2,2.5c1.5-0.2,1.3-1.7,1.1-2.9c-0.6-3.4-0.1-6.6,0.6-9.9c0.7-3.1,5.6-7.3-2.2-8.1c-0.2,0-0.4-0.5-0.6-0.8
            c-1-2.1-1.9-4.1-2.3-6.5c-0.9-4.8,4.2-6.1,5.4-10.1c0.9-3,2.8,2.1,4.7,2.6c0.5,0.1,1.1,0.3,1.5,0c3.8-2.3,8.1-1.2,12.1-1.5
            c3.5-0.3,7.2-1.7,8.8-4.2c2.2-3.6,5.3-4,8.5-5c5.5-1.6,6.8-4.8,4.5-10.2C1411.2,293.4,1407.3,285.8,1404.5,276.7z"/>
        <path class="portfolio_main_lines" d="M569.3,753c7.9,5.2,24.2,5.3,31.6,0.1c2-1.4,4.2-1.9,6.5-2.1c4.8-0.5,9.4-1.9,13.9-3.7c2.5-1,4.7-2.5,6-5
            c1.9-3.9,3.8-7.8,5.7-11.7c0.4-1,1.4-2.2,0.2-3.2c-1.1-1-2.2,0.1-3.2,0.6c-3.3,1.4-6.8,2.9-10,0.9c-5.6-3.4-12-4.2-18.1-5.9
            c-8.6-2.4-17.5-4-26.3-5.8c-1.2-0.3-2.7,0-3.9,0.3c-5.4,1.4-5.6,2.4-2,6.6c0.4,0.4,0.5,1.1,0.7,1.7c-1.9,1.1-3.1-0.1-4.3-0.8
            c-2.5-1.5-5.1-1.7-7.7-1c-1.6,0.4-4.2,0.2-4.5,2.4c-0.2,1.8,2,2.3,3.3,3.3c7.5,5.7,15.3,11.1,21.9,17.9c1.2,1.2,3.2,2.4,1.8,4.6
            c-1.5,2.2-3.5,1.3-5.4,0.6C573.4,752.1,571.3,750.4,569.3,753z"/>
        <path class="portfolio_main_lines" d="M678.3,677.1c-5.8,4.7-10.1,9.2-13.5,14.1c-3.7,5.3-6.6,11.5-3.9,18.5c0.6,1.7,0,3.6-1.1,4.7
            c-1.8,1.8-1.5,3.8-2,5.9c-1.1,5,2.2,10-0.4,15.2c-2.9,5.7-0.9,11,3.5,15.4c0.7,0.7,1.2,1.6,1.8,2.4c5.2,7.3,12.7,10.4,21.2,9.4
            c8.2-0.9,13.7-6.2,16.8-14c1.2-3,1.2-5.6-1.9-7.3c-1.9-1-2.5-2.5-2.4-4.5c0.2-3.3-1.1-4.5-4.6-3.7c-6.2,1.3-17.7-5.7-19.3-11.8
            c-1.8-6.8-0.4-13.8,3.2-19.3C681.2,694.1,678.3,686.2,678.3,677.1z"/>
        <path class="portfolio_main_lines" d="M697.9,922.2c0.9-1.7,0-3.2-0.5-4.8c-6.4-18.7-16.5-35.6-25.5-53c-0.4-0.8-0.6-2.1-1.8-2.1
            c-1.2-0.1-1.5,1.2-1.9,2c-4.3,9.6-10.1,18.4-15.5,27.4c-0.8,1.4-1.2,3.1-1.8,4.6c-0.5,1.2-0.4,2.6,1,2.8c5.7,1.1,9.1,5.3,12.8,9.1
            c0.8,0.8,2.1,1.8,2.9,0.3c3.5-6.1,5.8-1.1,7.5,1.1c4.5,5.8,9.3,11.3,15,16c1.9,1.6,3.8,3.5,6.7,2.2
            C699.8,926.5,698.1,924.2,697.9,922.2z"/>
        <path class="portfolio_main_lines" d="M1385.2,324.8c0.6,0.6,1,1.1,1.4,1.5c6.2,5.6,12.4,11.1,18.4,16.8c0.8,0.7,4.3,1.9,0.8,4
            c-0.8,0.4-0.1,1.1,0.5,1.5c5.7,4.8,11.3,9.8,17.1,14.4c3.4,2.7,4.8,5.8,3.9,9.9c-0.8,3.9-2.2,7.4-7,8c-2,0.2-3.1,1.2-2.3,3.4
            c0.7,1.7,1.2,3.3,3.9,2.8c8.3-1.4,12.8-9.2,10.1-17c-1-2.8-4.2-4.8-3.8-7.8c0.9-6.1,0.7-12.3,2.5-18.3c2.3-7.4,2.6-14.2-3.4-20.6
            c-2.8-3-5.1-4.4-8.9-2.9c-5.7,2.3-11.7,2.4-17.7,2.2C1395.5,322.7,1390.4,322.8,1385.2,324.8z"/>
        <path class="portfolio_main_lines" d="M1453.8,377.9c0.3-5,0.3-9.9,0.8-14.8c0.4-3.8,2.3-4.5,5.4-1.9c2.8,2.3,5.4,4.9,6.6,8.6
            c0.5,1.6,1.2,4.1,3.7,2.1c1.7-1.4,5.8-0.4,4.3-5.1c-2.1-6.5-6.4-11.6-10-17.2c-1-1.6-2.6-1.7-3.5,0.2c-2.8,6.1-8.2,9.5-13,13.6
            c-2.7,2.3-4.2,5.2-4.6,8.6c-0.8,6.8,1.5,13.8-1.4,20.6c-0.7,1.7,0,3.2-2.7,4.2c-4.6,1.7-5.5,6.5-4.8,10.5c0.6,3.5,4,5.7,8.1,5.6
            c0.4-2.2-3.2-1.7-2.4-4c2.9-1.4,5,0.2,7.2,1.8c1,0.7,1.9,2.6,3.3,1.1c1.2-1.2-0.3-2.5-1-3.5c-1.2-1.6-2.6-3-3.8-4.6
            c-0.8-0.9-1.6-2.1-0.6-3.1c1.4-1.5,2.3,0.1,3.3,0.9c0.5,0.4,0.8,1,1.3,1.5c1.9,2,4,3.4,6.9,3.4c3.4,0.1,2.3-3.4,3.9-4.8
            c1.9,2.4,3.5,4.5,5.3,6.5c1.1,1.3-0.1,4.5,2.6,4.1c2.5-0.4,2.2-3.4,2.7-5.4c0.4-1.6,1.1-3.4,0-5.3c-0.7,0.4-1.2,0.6-1.5,0.9
            c-1,1-1.8,2.9-3.6,1.8c-1.4-0.9-0.3-2.5,0.1-3.6c1.7-5.2,1.3-10.9,3.3-16.1c0.8-2,0-4.2-2.5-4.6c-4-0.7-5.7-3-5.5-6.8
            c-2-0.1-1.1,3-3.4,2.4c-0.3-2-0.6-4.1-1.1-7.6C1456.6,372.2,1456.5,375.4,1453.8,377.9z"/>
        <path class="portfolio_main_lines" d="M1222.1,449.3c-4-0.6-5.1-3.5-5.8-6.7c-0.3-1.3,0-3.1-1.6-3.3c-5-0.7-10.2-0.5-14.8,1
            c-8.1,2.7-14.8,8.9-23.1,11.6c-2.7,0.9-5.6,3-5.6,5.2c0.1,4.1-2.6,4.9-4.9,6.6c-1.6,1.3-4.2,2.7-3.2,5.1c0.8,2.1,3.5,1.2,5.4,1.3
            c20.6,0.8,40-5.6,59.7-10.4c3.5-0.9,6.9-2.6,9.7-5c1.8-1.6,2.1-3.5,0.7-5.3c-1.2-1.7-3.6-1.1-4.5,0c-3.7,4.5-9.2,5-14,6.8
            c-4.9,1.8-10.2,1.9-15.4,1.1c3.8-1.5,7.7-2.3,11.5-3.4c0.7-0.2,2.1,0.1,1.8-1.5c-0.4-1.9-0.4-1.9,1.2-2.3
            C1220.2,449.8,1221.2,449.5,1222.1,449.3z"/>
        <path class="portfolio_main_lines" d="M687.7,283.6c1.2,2.1,2.1,3.6,3,5.1c0.9,1.6,1.8,3.2,2.8,4.7c2.4,4,5.7,4.5,8.5,0.7
            c5.7-7.8,6.9-17.2,8.3-26.4c0.7-4.5-3.2-7.5-4.9-11.2c-0.6-1.2-1.6-2.2-1.9-3.5c-0.9-3.4-2.1-2.9-4.5-1.1
            c-4.9,3.8-7.3,8.8-8.7,14.7c-0.3,1.5-0.5,4.6-3.1,3c-4.2-2.6-3.5,0.7-3.5,2.6c0,2.7,0.1,5.2-2.1,7.8c-1.2-3.3-3.2-3.7-5.9-2.3
            c-1.9,1-3.6,1-4.7-1.1c-1.9-3.8-2.6-7.8-1.3-12c0.4-1.1,2.3-2,0.8-3.4c-1.1-1-2.5-0.3-3.8,0.1c-2.6,0.9-3.5,2.5-2.8,5.4
            c0.8,3.1,2.9,6.2,1.6,9.6c-1.4,3.7-0.2,6.7,2.6,9c3.4,2.8,7.9,4.4,10.5,8.3c0.6,0.9,2.4,1.8,3.2-0.2c0.6-1.4,1.3-1.1,2.3-0.7
            c3.1,1.4,3.4-0.7,3.5-2.9C687.8,288.2,687.7,286.3,687.7,283.6z"/>
        <path class="portfolio_main_lines" d="M991.3,351.2c3,0,5.7-2.4,7,0.7c1.2,2.9,3.5,3.2,5.9,3.8c2,0.5,3.8,0.9,5.1,2.8c2.2,3.4,5.4,5.3,9.5,5.8
            c1.6,0.2,2.7,0.6,2.4,2.8c-0.9,7.2,4.9,16.7,11.9,20.2c1.7-4.7,1.1-9.6,0.9-14.4c-0.2-3.3-1.7-5.8-5.2-6.4c-1.4-0.2-3.3,1-3.9-0.9
            c-0.7-2.4,1.7-2.8,3.2-3.8c7.1-4.7,8.3-11.5,3.4-18.6c-1.2-1.8-2.5-3.6-3.3-5.6c-1.1-2.8-3.1-4.2-6-4.5c-4.7-0.5-4.4-2.3-1.7-5.3
            c3.4-3.9,5.6-8.5,6.9-14.1c-5.5,2.9-6.9,8-9.1,12.6c-3.7,7.7-9.5,13.2-17.2,17.1C997.7,345.2,993.5,346.4,991.3,351.2z"/>
        <path class="portfolio_main_lines" d="M808.3,744.4c8.5-7.1,18.8-9.3,29.1-11.7c0.2,5.2,21.3,19.5,26.8,17.6c-4.2-0.6-5.9-4.2-9-6.3
            c1.3-1.5,3.6,0.9,4-1.4c0.3-1.5-1.6-1.8-2.6-2.6c-1.4-1.1-3-2.1-4.3-3.3c-1.3-1.3-1.3-3.1-0.3-4.6c1.1-1.7,2.5-0.4,3.5,0.2
            c2.2,1.3,4.5,1,6.8,1c1.1,0,2.7-0.1,2.8-1.5s-1.4-1.6-2.5-1.9c-6-1.2-12.2-1.8-18.2-2.8c-16.1-2.8-29.5,5-43.5,10.3
            c-4.5,1.7-8.4,3.3-13.1,2.4c-7.7-1.4-8.2-0.9-6.9,6.8c0.1,0.8,0.3,1.7,0.8,2.3c1.6,2.6,0.8,4.1-2,4.7c-1.8,0.4-4.1,0.5-3.5,3.1
            c0.5,2.3,2.8,1.9,4.6,2c4.8,0.2,9.9-0.4,14.4,0.8c4.4,1.2,6.3-1.6,7.3-3.9c1.3-2.9-1.9-4-4.3-4.9c-1.3-0.5-3.6-0.7-3.1-2.5
            c0.5-1.9,2.6-1.6,4.2-1.1c1.1,0.3,2.3,0.5,3.3,1.1c4.3,2.5,8,1.9,11.5-1.6C812.4,745.1,809.9,746.5,808.3,744.4z"/>
        <path class="portfolio_main_lines" d="M914.3,238.3c1.6,0,3.1-0.1,4.6,0c5.4,0.3,5.9-0.5,5.6-5.8c-0.2-5.1-3.4-8.5-5.6-12.5c-1-1.8-1.9-3.6,1.8-2.8
            c3.6,0.7,6.3-2.4,4.8-5.6c-2.9-6.4-7.5-12-11.5-17.7c-2.7-3.9-7.8-5.4-6.8-11.9c0.5-3-3.4-4.9-6.5-4.9c-3.4-0.1-1.3,3.1-2,4.6
            c-1.9,4.3,0,8.1,1.5,12c2.1,5.6,3.6,11.4,3.1,17.5c-0.4,4.6-0.8,9.3-1,13.9c-0.2,4.4,0.8,8.9,3.1,12.4
            C907.3,240.3,911.4,237.4,914.3,238.3z"/>
        <path class="portfolio_main_lines" d="M1344.4,458.9c-1.1,4.2-4.3,6-7.4,7.9c-3.6,2.3-3.9,5.8-2.5,9.2c1.5,3.7,4.9,2.1,7.4,1.3
            c6-1.9,9.1-6.2,10-12.4c0.9-6.7,4.2-11.8,10.4-14.8c0.9-0.4,1.9-1.3,2.7-0.2c0.7,1-0.2,1.9-0.9,2.5c-1.6,1.3-3.4,2.4-5,3.6
            c4.8-1.1,10.6-0.5,13.2-5.3c2.1-3.9,5.5-7.4,6.6-11.5c2.3-8.3,8.3-13.5,14-19.3c-6.1-2.3-9.4-0.3-11.8,6.8
            c-2.1,6.2-5,8.1-11.2,7.2c-1.3-0.2-2.2,0.3-3.2,1c-5,3.3-10.1,6.5-14.2,11c-1.8,2-3.3,2.9-6.3,2.6c-5.6-0.6-10.4,1.7-14.1,6
            c-2.1,2.4-3.8,5.1-7.8,3.8c-1-0.3-2.4,0.4-2.6,1.8c-0.2,1.6,1.2,1.7,2.4,1.9c1.5,0.2,3.2,0.2,4,1.6c1.9,3.1,3.6,1.7,5.7,0.2
            C1336.9,461.7,1339.5,458.3,1344.4,458.9z"/>
        <path class="portfolio_main_lines" d="M852.5,162.5c0-0.1,0-0.3,0-0.4c6,0,12,0.1,17.9,0c3.2,0,8.7-4.5,8.7-6.8c0-1.9-7.4-10.1-9.2-9.7
            c-5,1-10.1-0.9-14.9,0.1c-12.7,2.7-25.4,0.3-38,0.2c-1.7,0-3.4-0.3-4.1,1.7c-0.9,2.4,0.8,3.4,2.4,4.2c3.5,1.9,6.7,4.2,10,6.5
            c2.9,2,5.8,4.4,9.9,4.2C840.9,162.3,846.7,162.5,852.5,162.5z"/>
        <path class="portfolio_main_lines" d="M1739.7,319c-1.8-2-2.1-6.1-6.6-7.3c-4.1-1.2-7.8-0.6-11.7,0.6c-5.3,1.6-10.3,4.1-16.4,1.4
            c-3.6-1.6-5.6,1.1-4.4,5.1c0.9,2.8,1.6,5.7,1.7,8.7c0.2,8,6.3,15.2,13.8,17c3.7,0.9,8.7-3.5,6.9-6.6c-3.3-5.8,0.9-4.8,3.9-5.1
            C1736.2,331.9,1739.5,328.6,1739.7,319z"/>
        <path class="portfolio_main_lines" d="M656.4,159.5c5.5-0.3,10.3-1.2,15.1-0.8c12.6,0.9,25.2-0.3,37.8,0.5c7.5,0.5,15-0.3,22.5,0
            c10,0.5,10.8,0.2,13.8-8.7c0.4-1.1,0.3-2-0.7-2.6s-1.6,0.2-1.8,1c-1.1,3.2-3.4,2.4-5.8,2c-8-1.3-16-3.5-24,0.4
            c-0.6,0.3-1.3,0.3-2,0.2c-10.7-1-21.5,0.2-32.2-0.1c-2.3-0.1-4.7,1.3-6.8,0.8c-11.8-2.6-23.4-0.7-35.1,0.5
            c-1.8,0.2-4.3,0.2-4.3,2.4c-0.1,2.4,2.5,3,4.4,3.2C643.8,159,650.5,159.3,656.4,159.5z"/>
        <path class="portfolio_main_lines" d="M1220.4,436.2c3.1,3.5,6.1,5.6,10.3,1.5c1.5-1.5,3.4-1.6,5.4-0.3c5.7,3.9,12.3,4.8,19,4.9
            c5.8,0.1,12-0.8,17.3,0.9c8.8,2.9,16.2,1.8,23.8-3.3c-2.8-1.9-4.4,0.3-6.3,0.8c-2.1,0.5-5,0.8-5.7-1.5c-0.8-2.7,1.9-3.5,4.1-3.7
            c2.3-0.2,4.6-0.1,7-0.1c1.9,0,3.9,0.4,6-1.7c-8.3-2.5-16.7-2-24.7-4.2c-1.9-0.5-2.6,1.3-1.5,3c2.3,3.6-0.5,4.3-2.9,5.2
            c-3.8,1.4-7.1,0.7-10.1-2.2c-2.5-2.4-4.7-5.2-7.9-6.8c-2.7-1.4-1.4-3.2,0.3-4.2c4.5-2.5,8.2-7.1,14.5-5.3c1.7,0.5,4-0.5,5.8-1.2
            c2.6-1,3.5-3.7,2.7-5.8c-1-2.8-2.9-0.2-4.4,0.3c-1.2,0.4-2.5,1.5-3.7,0.3c-1.6-1.5-2.7-0.5-3.9,0.3c-4.8,3.3-9.4,6.9-14.5,9.6
            c-7.8,4.2-14.3,11-23.9,11.7C1224.9,434.7,1222.3,434.3,1220.4,436.2z"/>
        <path class="portfolio_main_lines" d="M1037,154.2c-1.9,1.1-3.9,1.9-5.6,3.1c-5.2,3.6-11.8,5.4-15.5,10.7c-5,7.3-8.7,15.5-15.8,21.3
            c-1.6,1.3-3.3,3.4-1.1,5.6c1.9,1.9,4.4,2.6,6.9,1c3.9-2.6,8.2-4.5,12.6-6c10.1-3.4,16-11.7,24-18.3c-2.7-0.7-3.8,2.1-6.4,0.6
            C1040.6,166.6,1038.8,160.6,1037,154.2z"/>
        <path class="portfolio_main_lines" d="M1337.3,379.6c-0.6-1.2-1.5-2.6-2.1-4.1c-0.4-1-1-2,0.2-3.1c4.3-4,3.9-6.2-1.4-9c-6.6-3.6-12.9-7.4-14.9-15.5
            c-0.6-2.4-1.8-2.6-4-1.5c-6.2,3.3-9.1,8.5-9.5,15.2c-0.1,2.3,4.8,12.5,6.2,12.4C1320.9,373.4,1327.5,382.7,1337.3,379.6z"/>
        <path class="portfolio_main_lines" d="M1343.3,320.9c-8.7-0.1-17.2-2.2-25.8-2.8c-2-0.1-4-0.2-5.1-2.4c-2.3-4.5,0.1-12.3,4.6-14.6
            c0.8-0.4,1.7-0.7,1.6-1.6s-1-1.7-1.7-1.4c-3.2,1.1-8.1-6.6-9.9,1.8c0,0.1-0.2,0.3-0.4,0.3c-6.7,0.8-6.7,7.2-8.7,11.3
            c-2.7,5.4-6.1,8-12,6.1c-1.4-0.5-1.9,0.3-2.4,1.1c-1.6,2.3-2.8,4.9-5.1,6.8c-1.1,0.9-0.9,2.3,0.1,3.1c1.2,1,1.5-0.5,2.1-1.2
            c1.9-2.1,12.6-4.9,14.3-3.3c3.9,3.7,9.6,2.5,13.6,5.7c1.1,0.8,2.8,0.9,4.3,1.1c2.1,0.2,3.5-0.6,3.9-2.9c0.4-2.1-0.9-3-2.6-3.7
            c-1.4-0.5-3.5,0.2-4.2-2.4C1321,319.5,1332.3,323.1,1343.3,320.9z"/>
        <path class="portfolio_main_lines" d="M935.8,165.7c2.5-2.8,5.5-2.6,8.5-2.4c3.5,0.2,7.2,0.5,10.3-1c4.9-2.4,9.8-4.7,15.4-5c2.8-0.1,4-2.5,4.4-4.7
            c0.6-2.7,1.6-5.8-0.9-8c-1.9-1.6-4.1-0.3-6.1,0.5c-1.5,0.7-3.1,1.3-4.5,2.1c-3.1,1.8-3.8,0.2-3.7-2.5c0.1-2.2,1.4-4.4,0.1-6.5
            c-1.1-0.1-1.5,0.5-1.7,1.1c-2.9,6.5-4.4,7.3-11.3,5.4c-2.8-0.8-5.5-1-8.4-0.9c-1.1,0-3,0.1-2.5,1.6
            C937.2,152.1,935.3,158.7,935.8,165.7z"/>
        <path class="portfolio_main_lines" d="M1626.8,595.1c6.7,0.2,10.9-4.8,16.2-7.6c1.1-0.6,1.5-2.2,1.5-3.5c0-1.6,0.7-2.9,1.5-4.2
            c1.5-2.6,0.6-12.3-1.5-14.4c-1.9-1.9-4-2.8-6.7-1.6c-3.8,1.7-7.5,3.6-10.3,6.8c-1.2,1.4-2.3,2.3-4.2,1.7c-3.1-1-3.6,1-3.1,3.2
            c0.8,3.5,0.5,6.8,0.3,10.3C1620,592.9,1621.8,595,1626.8,595.1z"/>
        <path class="portfolio_main_lines" d="M892.7,352.4c2.5-4.8,4.2-8.4,6.2-11.9c3.5-6.1,5.8-12.7,7.5-19.5c0.4-1.7,0.7-3.1-1.9-2.2
            c-0.9,0.3-2.2,0.3-2.5-0.9c-0.4-1.5,0.9-1.9,2-2.5c4.9-2.7,6.2-6.6,4.1-12.4c-2.4,2.8-5,4.4-8.6,2.4c-4.3-2.3-7-0.3-8.5,3.7
            c-3,7.9-3.6,16.1-3.7,24.4C887,339.9,890.3,345.4,892.7,352.4z"/>
        <path class="portfolio_main_lines" d="M965.5,290.6c0.5,0-0.8-4.4,2.2-2.4c0.8,0.6,1,2.5,2.5,1.2c0.9-0.8,1.2-2.4,0.6-3.2c-1.9-2.7-2.1-6.5-4.2-8.5
            c-3.3-3-3-6.4-3-10c-0.1-3.4,0.7-7.5,1.7-10.1c2.4-6.3-0.2-10.8-1.7-16.1c-2.5-8.8-2.4-9.8-11.2-3.4c-0.7,0.5-1.4,0.9-2.1,1.4
            c-1.6,1.1-2.1,2.4-1.5,4.3C953.7,259.5,954.9,276.4,965.5,290.6z"/>
        <path class="portfolio_main_lines" d="M1170.6,411.1c2.5-4.6,7.2-3.6,11.1-4.9c10.8-3.5,17.7-13.4,28.2-17.5c0.9-0.4,2.1-1.5,1.2-2.8
            c-0.8-1-2.2-0.8-3.2-0.3c-2.6,1.3-5.7,2.4-8.2,1.5c-7.2-2.8-14.4-0.2-21.6-0.6c-5.4-0.3-8.6,2.8-10.9,7.8
            C1164.5,400.7,1165.9,405.7,1170.6,411.1z"/>
        <path class="portfolio_main_lines" d="M976.7,321.9c0.3,0.3,0.7,0.7,1,1c-2.2,3.4-4.4,6.9-6.7,10.3c-0.9,1.3-1.7,2.5-0.5,4c1.3,1.6,2.6,0.7,4,0.1
            c8.2-3.3,14.8-8.9,20.8-15.2c2.8-2.9,3.2-6.5,2.6-10.3c-0.7-4.1-2.9-4.9-6.1-2.5c-3.2,2.4-6.4,5-10,8c0.6-3.4,2.6-5.8,0.7-9.8
            c-1.1,5-4,7-7.7,8.4c-1.4,0.5-3.1,1.9-3.6,3.3c-1.5,5.1-6.2,7.1-9.6,10.4c-0.7,0.7-2.3,0.9-1.6,2.8
            C966.1,329.6,971.2,325.4,976.7,321.9z"/>
        <path class="portfolio_main_lines" d="M1418.9,392.7c0.1,3.1,1.4,6.5-3.8,5c-1.4-0.4-3.6-1.1-3.9,0.7c-0.5,2.7,2.3,1.5,3.6,1.9
            c2.1,0.7,4.2,0.8,4.3,3.8c0,3.1-1.9,4-4.3,4.5c-1.3,0.3-2.7,0.8-3.9,0.6c-9.1-1.3-18.2,0.5-27.3-0.1c-1.8-0.1-3.9-0.8-4.8,1.4
            c-1,2.4,0.9,4.1,2.1,5.8c1.1,1.5,2.5,2.8,4.7,1.8c11.4-5.4,22.6-3.5,33.7,1.1c1.9,0.8,4.8,1.4,5.2-0.8c0.8-4.8,4.4-7.1,8.5-10.5
            C1424,406,1423.1,398.1,1418.9,392.7z"/>
        <path class="portfolio_main_lines" d="M883.1,243.9c-0.3-1.6-1.2-3.8-0.7-4.2c4.3-3.7,0.6-6.2-1.2-8.7c-6.2-8.7-11.9-17.7-14.9-28
            c-2.1-7.2-3.9-10.8-8.8-15.5c-1.4-1.3-2.8-4.5-5.1-2.4c-1.9,1.7,0.1,4,1.2,5.8c0.5,0.9,1.1,1.8,1.9,2.3c3.2,2,4.4,5.2,5.8,8.4
            c2.8,6.7,4.1,13.6,4.9,20.7c0.7,6.1,2.1,12.3,8.7,15.3c0.9,0.4,2,1.2,2.1,2C877.5,243.3,880.3,243.2,883.1,243.9z"/>
        <path class="portfolio_main_lines" d="M1497,268.1c-1.4,0-2.5-0.4-3,0.9c-0.5,1.2,0.8,1.6,1.3,2.4c3.5,5.2,8.1,10.4,9,16.4c1,6.7,3.6,12.2,6.5,18
            c3.3,6.6,4.6,6.5,9.3,0.9c2-2.4,1.7-3.1-0.4-4.6c-1.4-0.9-1.8-2.7-2.7-4c-4.9-7.4-4.5-16.9-10.2-24.1
            C1503.8,270.2,1500.6,269.1,1497,268.1z"/>
        <path class="portfolio_main_lines" d="M1581.9,376.6c-4.6-0.4-6.2,0.2-7.3,3.3c-0.9,2.5-1.1,4.9,1.3,6.8c6.3,5,14.1,4.2,19.6-1.8
            c1.8-1.9,4-3.4,6.3-4.8c1.9-1.2,3.6-2.6,4.6-4.4c1.3-2.5,4.4-5.5,1.6-8c-2.5-2.2-5.1,0.8-7.3,2.4
            C1595,374,1589,376.9,1581.9,376.6z"/>
        <path class="portfolio_main_lines" d="M1419.1,250.8c2.7,6.4,5.3,12.8,8,19.2c2.5,6,1.3,13.9,8.7,17.6c0.7,0.4,1.1,1.6,1.4,2.6
            c2.2,7,7.5,12.1,10.5,18.6c1.1,2.4,3.3,4.2,6.3,4.2c1.4,0,2.7-0.3,3.3-1.7c0.6-1.5-0.1-2.6-1.5-3.2c-1.7-0.8-3-2.1-4.1-3.6
            c-9.7-14-19.2-28-23.5-44.7C1426.9,254.8,1423.9,252.3,1419.1,250.8z"/>
        <path class="portfolio_main_lines" d="M702.4,306c0.1,1.8,0.1,4.4,0.6,6.8c1.3,6.6,1.6,13.6,8.1,17.9c1.3,0.9,2.5,2.8,4.2,1.5
            c1.6-1.2,0.2-2.7-0.3-4c-3.6-10.7-3.1-20.7,5-29.4c0.6-0.6,1.3-1.2,0.9-2.1c-0.4-0.9-1.4-0.8-2.2-0.7c-3.9,0.6-8,0.8-11.7,2.1
            C704,299,701.7,301.5,702.4,306z"/>
        <path class="portfolio_main_lines" d="M1497.5,185.1c-3.4-1.8-4.9,0.3-6.7,1.5c-2.4,1.6-5.2,4.9-7.8,2.1c-2.1-2.3-4.3-1.6-6.4-2.3
            c-2.4-0.8-4.1,0-4.9,2c-0.6,1.7-2.3,3.6-0.5,5.6c1.7,1.9,3.5,1.6,5.8,0.7c2.6-1.1,5.9-2.9,8.3,0.2c4.5,5.8,10.6,10.7,11.6,18.8
            c0.2,1.9,1.2,4.4,3.7,3.8c2.3-0.5,1.5-3,1.5-4.7c0.3-7.7-4.1-14.1-7.1-20.5C1492.9,187.9,1493.6,186.8,1497.5,185.1z"/>
        <path class="portfolio_main_lines" d="M763.4,294.8c-0.4,16.7-0.5,32.2,4.6,47.2c0.5,1.3,0.7,3.4,3.5,3.1c-0.8-10-1.2-20.1,0.8-29.7
            C774.1,306.4,770.6,300.8,763.4,294.8z"/>
        <path class="portfolio_main_lines" d="M940.4,755.8c-7.8-0.7-15.2-3.4-22.9-4.4c-3-0.4-6.4-1-7.6-3c-3.8-6.3-10.5-8.1-15.9-11.8
            c-1.6-1.1-3.6-1.6-5.7-1.4c-1.5,0.1-2.4,1.1-2.8,2.5c-0.5,1.9,0.9,2.3,2.2,2.6c4.5,1.1,7.6,4.6,12.5,7c-2.5,0-4-0.8-6.4,0.9
            c7.2,0.4,11.4,5.8,18.1,6.6C921.5,755.9,930.6,760,940.4,755.8z"/>
        <path class="portfolio_main_lines" d="M727.3,297c1.4,6.2,0.8,13.4,1.1,20.5c0.2,6.8,5.1,8.7,9.2,11.6c1.2,0.9,3.5,1.1,3-1.3
            c-0.8-4-1.2-9.2-3.9-11.5c-3.9-3.4-3.5-6.7-3.1-10.6c0.5-5.1,3.3-9.4,5.1-14c0.5-1.3,1.4-3.1-0.1-4.1c-1.2-0.8-1.9,0.9-2.9,1.4
            c-1.8,0.9-3.6,1.6-5.4,2.5C728.4,292.5,726.9,293.7,727.3,297z"/>
        <path class="portfolio_main_lines" d="M1363.5,370.2c-0.1,3.7,2.2,6,3.5,8.7c2.6,5.6,15.6,10.4,21.3,7.9c2.2-1,3.6-2.4,1.3-4.4
            c-5.4-4.6-10.2-10-16.1-14.1c-1.2-0.9-1.8-1-2.8,0.1C1368.8,370.4,1366.4,371.1,1363.5,370.2z"/>
        <path class="portfolio_main_lines" d="M981.9,729.1c5.3,1.2,10,0.2,14.6-2.3c0.7-0.4,2.2-1.7,2.3-0.7c0.4,3,0.9,1.5,1.8,0.3c5-6.2,6.2-14.2,10.1-21
            c0.4-0.7,1-2,0.1-2.8c-1.2-1-2,0.2-3,0.8c-8,4.5-12,13.2-19.7,18.1c-1.8,1.1-4.4,2.2-5.1,5.2c1.9,0.1,3.5,0.2,5.1,0.3
            c0,0.2,0.1,0.5,0.1,0.7C986.1,728.1,984,728.6,981.9,729.1z"/>
        <path class="portfolio_main_lines" d="M661,694.2c0-1.4-0.2-2.4-1.1-3c-1.5-1-2,0.6-2.7,1.3c-3.6,3.7-6.4,8.1-12.2,9.5c-2.5,0.6-4.9,2.5-7.5,3.6
            c-1.4,0.6-3.6,1.3-3,3.3c0.9,3.3-0.7,4.6-3.4,5.4c-1.3,0.4-2.1,1-1.8,2.4c0.3,1.7,1.5,1.4,2.7,1.2c12.9-2.5,22.4-9.4,28.1-21.4
            C660.5,695.8,660.8,694.8,661,694.2z"/>
        <path class="portfolio_main_lines" d="M635,332.9c1.8,1,2.7,0.2,3.6,0c4.3-0.7,9.3-2.9,12.7-1.5c5.5,2.3,10-1,15-0.4c0.8,0.1,1.7-0.5,1.8-1.4
            c0.2-1.2-0.9-1.7-1.9-1.9c-2.7-0.6-5.5-1.2-8.3-1.8c-2.1-0.5-4.7-0.7-5.1-3.2c-0.5-3,2.3-3.2,4.3-3.8c2.8-0.8,3.2-2.8,3.1-5.4
            c-0.1-1.8-0.7-2.7-2.4-3.2c-2.6-0.7-4.1,0.5-5.3,2.6c-0.8,1.4-2.1,2.7-2.7,4.2c-2.1,5.2-4.3,10.2-10.5,11.9
            C637.9,329.6,636.7,331.4,635,332.9z"/>
        <path class="portfolio_main_lines" d="M942.3,219.7c-0.1-1-0.1-1.7-0.3-2.3c-1.8-5.3-6.1-8.6-10-12.1c-1.5-1.3-2,0.4-2,1.5c0.2,9.5,0.9,18.9,3.7,28
            c0.5,1.7,1.3,3.8,3.6,3.5c1.8-0.3,2.4-2.4,2.5-3.9C940,229.3,939.5,224.1,942.3,219.7z"/>
        <path class="portfolio_main_lines" d="M931.5,301.7c-1.3-2.1-2.2-5.8-5.7-6s-2.8,3.4-3.1,5.7c-0.7,4.7-0.9,9.6-1.7,14.3c-0.6,3-0.1,4.6,2.8,5.8
            c3.1,1.3,5.1,0.6,6.6-2.2c1.3-2.5,3-4.8,4-7.4c1-2.4,1.7-6-1.5-6.4C929.8,304.9,930.5,304.1,931.5,301.7z"/>
        <path class="portfolio_main_lines" d="M750.7,289.3c-2.8,0.7-2.4,2.7-2,4.8c2.6,15,5.1,30,7.6,45c0.4,2.4,1.3,4.5,3.3,6c0.9,0.6,1.9,0.7,2.8,0
            c0.7-0.5,0.5-1.2,0.3-1.8c-3.5-7.7-2.6-16.3-4.7-24.3c-2.4-9.1-5.5-18.2-5.5-27.8C752.3,290.1,752,289.2,750.7,289.3z"/>
        <path class="portfolio_main_lines" d="M1067.9,696.3c-2.4,1.6-2.4,4.9-5.3,5c-0.1,0-0.4,1.7-0.4,1.7c3,1,1.5,4.2,3,6c1.1,1.3,3.1,2.4,1.5,4.6
            c-1.5,1.9-3.3,3.1-5.6,2.7c-0.8-1.9,1.8-2.7,0.6-4.6c-4.3,3.3-8.6,6.3-14.2,6.7c4.9,2.9,9.9,4.4,15.3,4.8c4.1,0.3,6.8-1.2,6.6-5.5
            c-0.1-1.5-0.8-3.2,0.2-4.4C1075,706.9,1069.8,702.1,1067.9,696.3z"/>
        <path class="portfolio_main_lines" d="M620.4,251.4c-3.3,2.2,6,6.1-1.4,7.2c-7.9,1.1-4.4-5-4.7-9.3c-4.9,5.1-7.7,10-7.7,16.6c0,2.8,0.8,5.1,3,5.5
            c4.2,0.8,6.5,4,9.5,6.5c0.4-1.2,0.6-2.3,1-3.1c1.9-3.2-2.9-4.5-2.1-8c0.4-1.6-3.5,0.3-5.5,0.5c-0.8-5.7,0.3-6.9,5.7-7.1
            c3.4-0.1,6.8,0.2,10.6-1.5C625.6,256.2,623.8,252.7,620.4,251.4z"/>
        <path class="portfolio_main_lines" d="M943.4,347c-6.4,2.7-11.4,4.8-15,9.3c-2.5,3.2-5.7,5.5-9,7.8c-2,1.3-6.1,2.1-5.2,4.4c1.1,2.8,5,1,7.7,1
            c0.9,0,1.9-0.6,2.8-1C934,364.7,938.5,356.7,943.4,347z"/>
        <path class="portfolio_main_lines" d="M1291.7,273.6c0.2-0.1,0.5-0.2,0.7-0.3c-0.5-1.2-1-2.4-1.5-3.6c-2.1-4.8-1.4-6,3.9-6.7c1.5-0.2,3-0.1,4.5-0.1
            c1.2,0,2.7-0.1,2.9-1.4c0.3-1.6-1.4-1.5-2.4-1.8c-0.8-0.3-1.6-0.4-2.4-0.5c-3.4-0.4-3.6-3-1.6-4.3c3.9-2.4,1.1-3.3-0.3-5
            c-2.3-2.7-5.2-3.2-8.5-3.1c-1.9,0.1-4.1-1-5.2,1.6c-0.7,1.8-1.1,3.7,0,5.3c1.4,2.2,3.5,1.2,5.4,0.8c1.2-0.2,2.6-1.5,3.4,0.4
            c0.6,1.4,0,2.9-1.2,3.5c-5.2,2.9-3.6,6-0.8,9.6C1289.9,269.7,1290.7,271.8,1291.7,273.6z"/>
        <path class="portfolio_main_lines" d="M973.1,219.6c0.3,1.9,0.2,4,1.6,4.9c1.7,1,3-0.8,4.3-1.8c0.5-0.4,1-0.9,1.5-1.4c4.9-5,7.2-12,12.7-16.5
            c0.7-0.5,0.9-1.5,0.2-2.2c-0.9-0.9-2.2-0.8-3.3-0.4c-4.7,1.5-8.6,4.4-12.2,7.6C974.9,212.4,973.8,216.1,973.1,219.6z"/>
        <path class="portfolio_main_lines" d="M1512.2,259.6c-0.4,1.7-0.9,2.9-0.8,4c0.8,8.2,1.5,16.5,2.9,24.7c0.8,4.4,4.1,7.6,8.1,9.7
            c2,1.1,4.5,1.2,6-0.4c1.4-1.5,0-3.4-1.3-4.8c-1.2-1.2-1.2-2.7-1.5-4.1c-0.4-1.9-1.2-3.5-3.1-4.2c-2.9-1-1.5,2.9-3.9,3.2
            C1515.1,278.7,1514.9,269.2,1512.2,259.6z"/>
        <path class="portfolio_main_lines" d="M1259.4,357.7c0.8-1.3,0.1-2.3-0.7-3.1c-3.8-3.9-7.5-7.9-10.9-12.2c-2.5-3.2-11.2-3.5-14.4-1.1
            c-1.8,1.3-2.8,2.6,0,3.9C1242,349.3,1250.7,353.5,1259.4,357.7z"/>
        <path class="portfolio_main_lines" d="M1212.2,343.4c-9,3.2-10.9,5.6-8.6,10.1c1.9,3.8,0.7,10.5,8.2,9.6c0.3,0,0.7,0.7,0.9,1.1
            c2.5,4.1,5.7,7,11.1,6.5c1.1-0.1,2.5,0.7,3-0.7c0.5-1.4-1-2.1-2-2.4c-4.9-1.6-8.1-5.4-10.6-9.4c-1.9-3.1-3.5-4.1-7.2-2
            C1208.5,351.6,1209.1,347.6,1212.2,343.4z"/>
        <path class="portfolio_main_lines" d="M1267,237.1c0.5,0,1,0,1.5,0c3.3-0.2,4.1-1.4,3.7-1.5c-2.2-0.5,1-4.8-3.9-4.2c-5,0.7-10.5-0.6-15.6-2.5
            c-3.9-1.5-8.1-1.7-12.3-1.2c-1.1,0.1-2.7-0.3-3,1.1c-0.4,1.7,1.3,2.1,2.5,2.6C1248.5,235.1,1257.9,235.2,1267,237.1z"/>
        <path class="portfolio_main_lines" d="M427.6,555.1c2.7,3.3,5.8,5.4,7.6,8.7c1.8,3.3,3.7,6.6,5.9,10.6c2.4-7.8,1-15.2,1.1-22.5
            c0-1.9-1.9-1.5-3.5-1.4C434.4,550.5,432,554.4,427.6,555.1z"/>
        <path class="portfolio_main_lines" d="M1316.4,460.5c-4.8-0.6-9.3,1.1-13.8,2.8c-0.7,0.3-1.6,1-2.2,0.9c-3.7-1-5.4-0.6-7.2,1.9
            c-1.1,1.5-1.2,2.7,0.6,3.6c5,2.6,21.3-0.5,25.5-4.9c0.1-0.1-0.1-0.6-0.2-0.8c-1.4-0.7-2.8,1.4-4.1,0.1
            C1314.2,462.3,1317.1,462.4,1316.4,460.5z"/>
        <path class="portfolio_main_lines" d="M1237.4,365.5c2.4,0.3,4.8,0.5,7.1,0.9c11,1.8,22,3.5,32.7,6.4c0.9,0.3,2.1,0.8,2.7-0.3
            c0.6-1.3-0.6-1.8-1.5-2.1c-6.1-2.6-12.6-3.5-18.5-7.3C1252.8,358.6,1245.1,364.7,1237.4,365.5z"/>
        <path class="portfolio_main_lines" d="M621,554.8c-3.5,3.5-2.8,6.1-2.2,8.9c1.1,5,6,7.7,7.6,12.6c0.6,1.9,3,1.7,4.9,1.2c2.1-0.6,1.6-2.1,1-3.6
            c-0.6-1.7,0.6-2.3,1.7-3.1c1.6-1.2,1.3-2.7-0.2-3.7c-1.6-1.1-3.4-2.7-5.1-2.8C623.5,563.8,620.5,561.8,621,554.8z"/>
        <path class="portfolio_main_lines" d="M1312.9,229.2c-2.2-2.3-3.3-1.4-4.4-1.2c-3.3,0.7-6.4,6-10.2,0.6c-0.1-0.2-0.9,0.1-1.4,0.2
            c-5.7,0.8-10.7,5.6-11.6,10.9c-0.2,1.5,0.5,2.5,1.6,3.4c1.4,1.1,2.4,0.1,3.5-0.6C1297.7,238,1303,230.6,1312.9,229.2z"/>
        <path class="portfolio_main_lines" d="M1761.6,214.8c-4.9-1.1-7.8,2.9-11.1,6.1c-2.1,2.1-1.9,3.5,0.5,5.1c3.5,2.4,11,0.2,14.7-4.6
            c1.1-1.4,2.6-2.8,1.3-4.9C1765.8,214.9,1764.2,214.2,1761.6,214.8z"/>
        <path class="portfolio_main_lines" d="M880.4,638.6c1.7-1,4,0.1,5.5-1.9c4.6-5.9,9.4-11.6,14.1-17.4c0.5-0.7,0.9-1.5,0.3-2.3
            c-0.9-1.1-1.7,0-2.5,0.3c-6.1,2.3-8.3,8.9-13.5,12.3c-2.2,1.4-3.6,4.2-5.2,6.6C878,637.7,878.4,638.7,880.4,638.6z"/>
        <path class="portfolio_main_lines" d="M1513,159.6c3.8,0.1,8-0.5,12.2-1.6c2.2-0.6,3.5-1.8,1.6-3.8c-2.4-2.5-13.2-3.5-16.1-1.7
            c-1.9,1.2-4.6,2.3-4,5.1C1507.4,161,1510.4,159.3,1513,159.6z"/>
        <path class="portfolio_main_lines" d="M417.9,776.4c-3.3,2.7-6.2,3.7-9.3-0.1c-0.8-1-2.4-1.4-3.5-0.1c-1.2,1.3-3.2,2.4-2.3,4.6
            c0.9,2.1,0.5,3.9-0.6,5.6c-1.7,2.8-0.1,3.4,2.3,3.8c3.1,0.5,4.6-1.2,5.5-3.8c0.5-1.3,0.6-3.5,2.2-3.6
            C416.3,782.6,416.3,779,417.9,776.4z"/>
        <path class="portfolio_main_lines" d="M920.6,331.9c-4.8,2.5-6,6.1-8.1,8.9c-2.9,3.9-2.5,9.3-6.3,12.7c-1.6,1.4-2.9,3.3-0.5,4.8
            c2,1.2,4.8,0.7,5.5-1.6C913.4,348.6,917.5,341.3,920.6,331.9z"/>
        <path class="portfolio_main_lines" d="M1206.9,431.7c3.8-2.3,5.5-4.8,6.8-7.4c2.8-5.8,6.8-10.4,11.9-14.4c2.9-2.2,6-4.9,6.5-9.1
            c0.1-1.2,2.2-2.9-0.2-3.7c-1.7-0.6-3.3,0.6-4.1,2.1c-0.8,1.5-1.2,3.1-1.7,4.6c-0.9,3-2.8,5.7-5.5,6.7c-2.3,0.8-3.6,2.4-4.6,4.1
            C1213,419.9,1210.3,425.2,1206.9,431.7z"/>
        <path class="portfolio_main_lines" d="M1593.4,841.2c0.1-4.2-2.6-10.4-4.6-10.7c-2.9-0.3-6.1,5.5-5.7,11.6c0.2,2.9,3.1,3.8,5.6,3.7
            C1591.3,845.8,1593.7,844.9,1593.4,841.2z"/>
        <path class="portfolio_main_lines" d="M669.6,320.4c4.6,1.3,2,4,1.8,6.2c-0.2,2.1,0.2,5.1,2.8,3.4c4.6-3,7.5-1.3,10.8,1.6c0.8,0.7,1.7,1.2,2.6,0.2
            c0.6-0.7,0.5-1.5,0-2.3C683.2,323.5,678.7,317.4,669.6,320.4z"/>
        <path class="portfolio_main_lines" d="M1153.5,437c1.3-0.7,3-1.7,4.8-2.5c3.9-1.8,6.6-5.5,10.9-6.6c1.7-0.5,2.9-1.5,3.1-3.4c-2.4,1-3.7-3.7-6.2-1.5
            c-4.4,4-9,7.7-14.8,9.7c-1.1,0.4-2,1.4-1.1,2.8C1150.9,436.3,1151.5,437.5,1153.5,437z"/>
        <path class="portfolio_main_lines" d="M818.4,807.4c-0.2-1.7-0.3-3.4-0.5-5c-0.2-1.3-0.4-2.9-2.3-2.3c-1.8,0.5-1.4,1.5-1.1,3.1
            c0.8,5.1-7.3,16.6-12.4,18c-0.7,0.2-1.9-1.6-2.1,0.4c-0.1,1.1,0.3,2.4,1,3.2c0.8,1,1.9,0.1,2.6-0.5c2.4-2.3,4.7-4.7,7-7
            c0.8-0.8,1.7-3,3-1.6c2.2,2.3,2.3-0.2,3.1-1C818.3,812.4,818.1,809.8,818.4,807.4z"/>
        <path class="portfolio_main_lines" d="M1129.1,397.8c2.2-0.1,3.6,1.3,5.3,1.9c1.7,0.6,3.6,3,5,0.1c1.1-2.3,2.3-4.8-0.1-7.3c-4.1-4.1-7.3-1-10.5,1
            c-1.7,1.1-4.9,1.7-4.3,4S1127.7,397.5,1129.1,397.8z"/>
        <path class="portfolio_main_lines" d="M783.7,226.1c-1.9,1.5-1.5,3.1-1.5,4.5c-0.1,6.5,7.3,9.1,8.2,15.1c0.1,0.5,1.1,0.7,1.8,0.3
            c1.9-1,3.2-6.4,1.5-8C789.9,234.6,786.1,231,783.7,226.1z"/>
        <path class="portfolio_main_lines" d="M771.7,380.4c-0.2-0.3-0.3-0.8-0.6-1.2c-1.1-1.9-12.1-7-14.2-6.5c-2,0.4-2,1.7-1,2.9c2.7,3.4,5.7,6.4,9.7,8.3
            C767.9,384.9,771.7,382.6,771.7,380.4z"/>
        <path class="portfolio_main_lines" d="M690.9,318.7c0.8-0.2,2.3,0.4,2.7-1.1c0.6-1.8,0.3-3.8-1.1-5c-2.5-2.1-5.2-4.1-7.9-5.9
            c-1.9-1.2-2.7,0.3-2.9,1.9C681,313.1,686,318.7,690.9,318.7z"/>
        <path class="portfolio_main_lines" d="M981.7,182.4c-3.5,2.8-6.1,5-5.4,9.1c0.3,1.5,0,3.8,2.2,3.9c4.5,0.1,8.5-1.1,11.4-5.7
            C984.7,190.4,980.9,189.4,981.7,182.4z"/>
        <path class="portfolio_main_lines" d="M1435,438.8c-4.4,0.5-7.7-0.4-10.4,1.6c-7,5.3-14.9,3.7-22.6,2.9c-4.8-0.5-9.5-1.9-14.3-0.8
            C1403.1,443.6,1418.8,449.9,1435,438.8z"/>
        <path class="portfolio_main_lines" d="M447.9,555.9c4,0,5.1-2.3,5.8-4.7c0.9-2.9,2.5-5.4,4-8c0.8-1.4,3.8-3,0.7-4.7c-2.3-1.2-4.7-0.4-5.7,2.3
            C451,545.6,449.6,550.5,447.9,555.9z"/>
        <path class="portfolio_main_lines" d="M1003.3,243.7c-3.2,1.9-4.6,4.7-6.2,7c-1.9,2.6-2.9,1.8-4.5-0.1c-1.6-1.9-1.6-4.3-2.5-6.4
            c-0.5-1.1-1.4-2-2.7-1.6s-1.3,1.6-0.8,2.4c2,2.9,2.2,6.2,2.8,9.4c0.3,1.8,1.4,2.8,3.2,1.9C997.8,253.9,1002.6,251,1003.3,243.7z"
            />
        <path class="portfolio_main_lines" d="M1630.6,545.8c0.9,2.5-0.6,6.4,3.5,6.3c2.7-0.1,4.7-2,4.7-5.4c-0.1-3.4-1-6.9-4.7-7.1
            C1629.9,539.4,1632.2,544,1630.6,545.8z"/>
        <path class="portfolio_main_lines" d="M598.6,708.4c1.8-1.1,5.5,1.4,5.1-2.7c-0.3-2.9-0.7-6.3-5.4-6.3c-4.5,0-5.2,2.6-5.2,6.1
            C593.1,709.9,596.8,707.2,598.6,708.4z"/>
        <path class="portfolio_main_lines" d="M972.7,189.1c-0.1-0.8,0-2.2-0.3-3.4c-0.3-1.1-1.1-2.2-2.5-1.8c-1.1,0.3-1.7,1.5-1.2,2.4
            c1.5,2.9,0.6,5-1.3,7.5c-1.3,1.7-0.7,4.5,0.7,6.6c0.6,0.9,1.6,1.2,2.6,0.7c2.5-1.3,2.4-3.7,2.3-6.1
            C973,193.2,971.9,191.5,972.7,189.1z"/>
        <path class="portfolio_main_lines" d="M1353.3,281.7c0,3.2,0.3,5.8,2.6,5.8c2.4,0,0.9-3,1.8-4.4c0.3-0.5,0.1-1.3,0-1.9c-1.3-4.7-0.3-8.8,2.7-12.7
            c0.7-0.9,2.4-2.4,0.2-3.1c-1.6-0.5-4.5-0.2-4.5,1.8C1356,272.6,1354.4,277.6,1353.3,281.7z"/>
        <path class="portfolio_main_lines" d="M1422.7,148.5c-2.7,4.1-3.3,7.7-2.2,10.9c0.7,2.2,3.9,1,5.9,1.1c1.9,0,4.5,0,4.7-2.5c0.2-2.4-2.2-3.4-4.1-3.6
            C1423.9,154.1,1422.5,152.7,1422.7,148.5z"/>
        <path class="portfolio_main_lines" d="M1041.1,279.4c-0.1,2.3-1.5,4-2.8,5.7c-2.2,2.9-4.4,5.8-6.5,8.8c-0.7,1-1.1,2.4,0.2,3.1
            c1.1,0.6,1.7-0.7,2.2-1.5c3.5-5,7.1-10.1,10.5-15.2c0.8-1.2,2-2.6,1.1-4.1c-0.8-1.4-2-0.5-3.1-0.1
            C1041,276.6,1039.3,277.2,1041.1,279.4z"/>
        <path class="portfolio_main_lines" d="M1395.2,370.8c1.1-0.5,3.7,1.9,4-1.2c0.3-2.6-1.7-4.2-4.1-5c-1.7-0.6-2.9,0.5-3.9,1.6c-1.8,2-3.8,3.7-6.1,5.1
            c-1,0.6-3.2,0.7-2.1,2.6c1,1.6,2.7,1.1,3.8-0.2C1389,371.2,1391.7,370.4,1395.2,370.8z"/>
        <path class="portfolio_main_lines" d="M829,257c-2.2,5.4-2.6,11.3-7.2,15.7c-1.3,1.2-0.1,4,1.8,5c1.4,0.8,3.6,1,4.7-0.4c1.8-2.2-0.5-3.1-2-4
            c-2.3-1.5-0.7-2.7,0.2-4.1C829,265.5,831.4,261.7,829,257z"/>
        <path class="portfolio_main_lines" d="M719.8,356c-1.8-5.8-5.8-8.6-7.5-12.9c-0.4-0.9-2.2-1.4-3.4-1.9c-0.9-0.4-2-0.5-2.7,0.5s-0.4,2,0.3,2.7
            C710.2,348.4,714.1,352.3,719.8,356z"/>
        <path class="portfolio_main_lines" d="M788.7,257.5c-2.1,3.5-4.3,6.9-6.3,10.5c-0.7,1.2-0.9,3.1,0.7,4c1.5,0.9,2.4-0.5,3.4-1.3
            C791,267.2,789.7,262.4,788.7,257.5z"/>
        <path class="portfolio_main_lines" d="M425.1,682.2c-4.6-4-10.2-4-15.6-3.4c-2.6,0.3-3.4,3-4,6.9C412.1,681.4,418.6,682.3,425.1,682.2z"/>
        <path class="portfolio_main_lines" d="M623.5,153c-2.9,0.2-5.7,0.3-8.5,0.7c-0.6,0.1-1.4,0.9-1.7,1.5c-0.3,0.7,0.3,1.4,1,1.4c3.4,0,6.4,3,10,1.4
            c1.4-0.6,3.9-0.5,3.7-2.5C627.8,153,625.3,153.2,623.5,153z"/>
        <path class="portfolio_main_lines" d="M1457.6,160.7c0.8-0.1,1.7-0.2,2.4-0.4c2.3-0.8,7,0.5,6.5-2.3c-0.6-3.5-5.1-3.1-8.4-2.7
            c-0.5,0.1-1,0.2-1.4,0.4c-1.4,0.9-4.2,0.7-3.8,3C1453.3,160.7,1455.9,160.2,1457.6,160.7z"/>
        <path class="portfolio_main_lines" d="M1229.3,382.3c2.2-0.7,4.7-1.2,4.3-4c-0.4-4-3.8-4.5-6.9-4.9c-1.6-0.2-3.1,0.8-2.7,2.7
            C1224.8,378.9,1226,381.6,1229.3,382.3z"/>
        <path class="portfolio_main_lines" d="M642.4,264.9c4.2,3.3,8.3,6.7,12.1,10.3c1.4,1.3,3.1,0.9,4.6-0.1c1-0.6,1.4-1.4,0.8-2.5
            c-0.9-1.5-2-2.9-3.9-2.1c-2.7,1.1-4.1-0.9-5.6-2.2C648.1,266.3,645.7,264.8,642.4,264.9z"/>
        <path class="portfolio_main_lines" d="M694.8,354.9c3,7.6,7.2,14.3,13.3,19.8C707.5,365.5,699.1,361.6,694.8,354.9z"/>
        <path class="portfolio_main_lines" d="M850.8,211.3c-1,0-3.4,0.2-2.7,1.9c1.4,3.6,4.8,5.7,8.4,7.1c1.2,0.5,1.8-0.1,1.9-1.4
            C858.8,215.2,855.5,211.8,850.8,211.3z"/>
        <path class="portfolio_main_lines" d="M902,581.5c2.9,3.6,6.3-0.3,9,1.5c1,0.6,2.9,0.2,3.4-1.2c0.8-2.7-2.4-3.9-2.7-6.5c-0.1-0.9-1.6-1.1-2.6-0.6
            c-0.7,0.4-1.8,1.2-1.7,1.6C908.9,581,905.2,581,902,581.5z"/>
        <path class="portfolio_main_lines" d="M360.8,696.3c1-0.4,3.3,1.2,3.6-1.4c0.3-2.1-1.5-3.1-3.4-3.6c-1.4-0.4-2.9-1-4.3-1c-2.6,0-3.2,2.2-3.1,4.2
            c0,2,1.4,2.9,3.5,2.1C358.1,696.1,359.2,695.9,360.8,696.3z"/>
        <path class="portfolio_main_lines" d="M492.9,676.5c1.7-0.2,4.8-0.5,4.4-2.9c-0.4-2.8-3.8-3.5-6.4-3.6c-1.5,0-4.1-0.9-4.3,1.9
            C486.3,675.9,489.5,676.2,492.9,676.5z"/>
        <path class="portfolio_main_lines" d="M1487,295.3c-0.6-3.4-1.7-6.6-5.6-7.7c-1.5-0.4-2,1-1.7,2.2c0.8,3.4,0.7,7.6,4.8,8.9
            C1486.6,299.5,1486.8,297.2,1487,295.3z"/>
        <path class="portfolio_main_lines" d="M435.2,826.1c0.5,2.6-0.1,6.2,3.1,6.1c2.4-0.1,3.6-3.4,5-5.6c-2-1.1-1.7-4.5-5.4-4.4
            C433.7,822.1,436.9,825.5,435.2,826.1z"/>
        <path class="portfolio_main_lines" d="M1318,418.2c1.1-0.2,2.8-0.2,4.3-0.7c1.3-0.5,3.6-0.8,3.3-2.5c-0.3-2.3-2.3-0.8-3.6-0.8c-0.9,0-2-0.5-2.7-1
            c-1.7-1.4-3.1-1.8-4.7,0c-0.9,1.1-2.7,2-1.8,3.7C1313.9,418.6,1315.9,417.8,1318,418.2z"/>
        <path class="portfolio_main_lines" d="M1324.4,454.2c-0.6-3.3,0.3-6.2-3.1-6.1c-3.1,0.1-4.2,2.3-4.3,5.1c-0.1,2.3,1,3.4,3.3,3.3
            C1322.5,456.2,1324.9,456.2,1324.4,454.2z"/>
        <path class="portfolio_main_lines" d="M948.8,734.2c-3.7-2.2-14.3-4.7-18.4-4.3C935.9,734.5,942.4,736,948.8,734.2z"/>
        <path class="portfolio_main_lines" d="M566.5,753.3c-2.3-1.1-3.1-1.7-4.1-2c-4.6-1.2-9.2,1.2-13.8,0.3C553.8,755.5,559.3,755.7,566.5,753.3z"/>
        <path class="portfolio_main_lines" d="M801.8,248c7-1.2,13.4-2.3,19.7-3.3c0.7-0.1,1.4-0.5,1.1-1.3c-0.2-0.5-0.9-1-1.4-1.2
            c-1.8-0.4-3.6-0.1-5.4,0.6c-3.1,1.3-6.1,2.4-9.6,2.2C804.8,244.9,803.2,245.2,801.8,248z"/>
        <path class="portfolio_main_lines" d="M399.4,661.2c-1.9-2.4-3.4-5.6-6-3.7c-2.3,1.7-2.2,5.3-1.7,8.3c0.4,2.6,2.3,3.1,4.6,3.7
            C393.5,662.3,393.5,662.1,399.4,661.2z"/>
        <path class="portfolio_main_lines" d="M941.7,172.1c-0.4,0.3-0.9,0.5-1.2,0.8c-1.4,1.7,1.5,9.6,3.7,10c0.4,0.1,1.1-0.1,1.3-0.4
            c0.9-1.4,1.8-2.9,0.7-4.6C944.8,175.8,944.2,173.3,941.7,172.1z"/>
        <path class="portfolio_main_lines" d="M981.7,291.5c1.8-0.1,3.4,0,4.1-1.7c0.4-0.9,0.5-2-0.3-2.8c-1.2-1.2-2.9-1.4-4.1-0.5
            c-2.3,1.8-7.2-1.5-7.2,2.5C974.2,291,979.3,290.7,981.7,291.5z"/>
        <path class="portfolio_main_lines" d="M981.3,150.9c0.4-6.4,6.5-9.9,8.7-16.3C983.9,137.3,979.9,144.7,981.3,150.9z"/>
        <path class="portfolio_main_lines" d="M583.4,768.1c-3.6,1.1-6.2,2.1-9,2.4c-1.3,0.1-3.9,0-2.7,2.6c0.8,1.8,2.3,3.6,4.7,2.1
            C579,773.6,581.6,771.9,583.4,768.1z"/>
        <path class="portfolio_main_lines" d="M1482.1,466.1c5-2.1,7.2-5.6,10-8.4c0.6-0.7,1.1-1.8,0.2-2.7c-0.9-0.8-2-0.3-2.7,0.4
            C1486.5,458.1,1483.5,460.9,1482.1,466.1z"/>
        <path class="portfolio_main_lines" d="M757.8,153.7c-0.7-2.6-1.5-5.6-4.3-7.5c-0.8-0.6-1.6-0.1-2,0.7c-1.8,3.7,1,5.7,3,8.1c0.2,0.3,0.4,0.5,0.6,0.7
            c0.6,0.6,1.3,1.1,2.2,0.6C758,155.9,757.9,155,757.8,153.7z"/>
        <path class="portfolio_main_lines" d="M897.5,759.3c4.1,6.1,9.5,5.6,15.4,2.8C907.7,761.2,902.6,760.2,897.5,759.3z"/>
        <path class="portfolio_main_lines" d="M1081.5,185.1c-1.9,0.4-4.8-0.5-5.1,2.3c-0.2,1.9,1.8,3.5,4.1,3.4c2.5-0.1,4.8-1.3,4.9-3.9
            C1085.6,184.6,1083.1,185.5,1081.5,185.1z"/>
        <path class="portfolio_main_lines" d="M1241.4,380.2c0,0.4,0,0.9,0,1.3c4.2,0.6,8.3,1.5,12.6,1.3c1.2-0.1,3.1,0.2,2.8-1.8c-0.2-1.5-1.8-1.9-3.2-1.8
            C1249.5,379.5,1245.5,379.9,1241.4,380.2z"/>
        <path class="portfolio_main_lines" d="M882.3,250.9c-1.5,0.1-2.9,0.5-2.4,1.9c0.9,2.5,2.4,4.9,5.4,5c1.4,0,4.2-0.1,3.7-2
            C888.1,252.8,884.7,252.1,882.3,250.9z"/>
        <path class="portfolio_main_lines" d="M786.2,188.9c2.1-1.1,5.1-1.2,6.2-3.6c0.9-1.8,1.3-4.3-0.6-5.3c-1.6-0.8-3.3,1.1-4,2.8
            C787.1,184.7,786.8,186.8,786.2,188.9z"/>
        <path class="portfolio_main_lines" d="M1452.1,186.3c-1.5,4.3-1.4,7.6,1,10.8c0.3,0.5,1.1,0.9,1.6,0.9c0.8,0,1-0.8,0.9-1.5
            C1455,193.2,1454.9,189.7,1452.1,186.3z"/>
        <path class="portfolio_main_lines" d="M346.1,722.3c0.1,1.1-0.3,2.5,0.9,2.8c1.6,0.4,1.9-1.2,2.2-2.1c0.8-2.4,3.3-4.5,1.7-7.3
            c-0.2-0.4-1.4-0.9-1.6-0.7C346.9,716.8,346.6,719.7,346.1,722.3z"/>
        <path class="portfolio_main_lines" d="M441.3,579.8c-6,3.7-2.2,10.4-5.5,14.3C438.6,590,442,586.1,441.3,579.8z"/>
        <path class="portfolio_main_lines" d="M946.5,231.5c1.4-0.5,3.8-1.1,3.3-2.7c-0.5-1.7-1.5-4.3-4-4.4c-2-0.1-2.5,1.9-2.7,3.5
            C943,230.1,944.1,231.3,946.5,231.5z"/>
        <path class="portfolio_main_lines" d="M359.2,740.4c1-0.8,1.6-1,1.9-1.5c1.7-2.8,2.4-6.7,7.3-5.5c0.8,0.2,1.3-0.9,1.3-1.8c0-0.4-0.3-1-0.7-1.2
            c-1.4-0.9-7.9,2.5-8.3,4.2C360.4,736.4,359.9,738.1,359.2,740.4z"/>
        <path class="portfolio_main_lines" d="M918.9,279.3c3.4-1.6,5.9-3.3,5.6-7.1c-0.1-1-0.6-2-1.6-2.3c-1.3-0.3-1.6,0.9-2,1.7
            C919.9,273.9,918.7,276.1,918.9,279.3z"/>
        <path class="portfolio_main_lines" d="M775.8,157.5c-0.5-3.1-0.9-5.9-1.4-8.7c-0.1-0.7-0.5-1.3-1.3-1.2c-1,0.1-1.3,0.9-1.2,1.8
            c0.3,2.8,0.7,5.6,1.2,8.4c0.1,0.9,0.3,2.2,1.7,1.9C776,159.3,775.7,158.1,775.8,157.5z"/>
        <path class="portfolio_main_lines" d="M761.3,143.7c0.1,1-0.3,3.3,1.7,2.2c2.4-1.3,3-4.1,2.9-6.8c-0.1-1.2-0.5-2.6-1.9-2.3
            c-2.6,0.6-1.7,3.1-2.3,4.9C761.5,142.3,761.5,142.9,761.3,143.7z"/>
        <path class="portfolio_main_lines" d="M794.4,265.6c3.2-0.9,6.6-0.2,9.5-2.1c0.8-0.5,1.9-1.1,1.4-2.3c-0.5-1.2-1.7-1.1-2.5-0.7
            C799.9,261.8,796.6,262.7,794.4,265.6z"/>
        <path class="portfolio_main_lines" d="M683.1,688.4c2.4-5.4,2.2-10.5-1-15.4C681.1,678.2,683.7,683.2,683.1,688.4z"/>
        <path class="portfolio_main_lines" d="M601,718.4c4.7,1.4,9.7,2.8,14.2-1.1C610.4,717.7,605.7,718,601,718.4z"/>
        <path class="portfolio_main_lines" d="M434.4,661.7c3.9,1.3,7,2.4,10.1,3.3c0.8,0.2,1.7,0.1,2.2-0.7c0.5-1-0.2-1.7-1-1.9
            C442.3,661.8,439.2,659.4,434.4,661.7z"/>
        <path class="portfolio_main_lines" d="M364,707.6c2.2-2.9,5.4-3.5,8.4-5c-3.3-0.3-5.1-2-6-5.4C368.3,701.6,364.7,704,364,707.6z"/>
        <path class="portfolio_main_lines" d="M1289.2,414.9c-1.8-0.9-3.3-1.6-4.9-2.4c-1-0.5-1.9-0.7-2.6,0.4s-1.3,2.5-0.6,3.7c0.6,1,1.8,0.7,2.7,0.4
            C1285.5,416.5,1287.4,416.7,1289.2,414.9z"/>
        <path class="portfolio_main_lines" d="M1377.1,332.2c1.2-0.6,2.5-1.5,2.5-2.7c-0.1-2.4-2.3-3-4.2-3.3c-1.1-0.2-2.8-0.2-2.5,1.4
            C1373.2,329.8,1375.1,330.9,1377.1,332.2z"/>
        <path class="portfolio_main_lines" d="M847.7,267.7c2.7-1.9,3.2-4.3,4.6-6.1c0.7-1,1-2.3-0.5-2.8c-1.7-0.6-2.9,0.1-3.2,1.9
            C848.2,262.7,848.1,264.8,847.7,267.7z"/>
        <path class="portfolio_main_lines" d="M1043.7,178.6c0-1.1-0.3-1.9-1.4-1.5c-2.3,0.9-4.5,2.2-5.4,4.6c-0.3,0.9,0.8,1.2,1.7,1.1
            C1041.2,182.5,1043.1,181.3,1043.7,178.6z"/>
        <path class="portfolio_main_lines" d="M400.5,778c-4.7,1.7-2.7,8.6-8.6,9.5c2.4,0.6,5.3,2.5,5.8-0.1C398.4,784.2,398,780.8,400.5,778z"/>
        <path class="portfolio_main_lines" d="M1008.4,232.9c-3.5,1.8-5.9,3.5-7.6,6.4C1005.7,239.5,1006.3,239.1,1008.4,232.9z"/>
        <path class="portfolio_main_lines" d="M975,170.4c-1.8,2.2-3,4.7-2.4,7.6c0.2,0.7,1.5,1.1,2.1,0.4C976.8,176,975.9,173.2,975,170.4z"/>
        <path class="portfolio_main_lines" d="M678.2,334.5c-1.8,0.2-4-0.2-4.2,2.3c-0.2,1.7,1.2,2.3,2.7,2.2c2-0.1,4.3-0.5,4.5-2.9
            C681.4,334.2,679.5,334.6,678.2,334.5z"/>
        <path class="portfolio_main_lines" d="M1265.5,427.9c2.3-0.5,4-1.9,4.7-4.3c0.3-0.9-0.3-1.7-1.3-1.7c-2.6,0.1-4.2,1.8-5.1,4
            C1263.4,427.1,1264.1,427.8,1265.5,427.9z"/>
        <path class="portfolio_main_lines" d="M478.8,501.1c-5.1-0.2-9.7,1.1-13.1,5.2C469.9,504,474.8,503.7,478.8,501.1z"/>
        <path class="portfolio_main_lines" d="M962.5,582.3c2-0.2,2.7-1.7,3.1-3.3c0.3-1.2,0-2.6-1.6-2.3c-1.7,0.3-3,1.5-3.4,3.2
            C960.2,581.4,961.1,582,962.5,582.3z"/>
        <path class="portfolio_main_lines" d="M410.4,756.9c3.6-0.6,5.2-4.3,9.3-5.1C412.6,747.8,413.8,756,410.4,756.9z"/>
        <path class="portfolio_main_lines" d="M457.8,625.9c0-4.6,0.3-7.7-1.3-11.5C454.8,618.6,454.4,621.7,457.8,625.9z"/>
        <path class="portfolio_main_lines" d="M1004.7,781.7c-2.6,2.7-4.8,4.5-5.2,7.6c-0.1,0.7,0.9,1.2,1.4,0.8C1003.5,788.4,1005.2,786.1,1004.7,781.7z"
            />
        <path class="portfolio_main_lines" d="M743.3,160.7c-0.3,5.2,1.6,9.9,3.8,14.5C747.3,170,743.8,165.7,743.3,160.7z"/>
        <path class="portfolio_main_lines" d="M1259.1,385.2c-1,0.1-2.6,1.1-2.1,1.3c2,1,2.2,3.7,4.3,4.2c1.3,0.3,2.3-0.6,2.1-2
            C1262.9,386.4,1261.3,385.5,1259.1,385.2z"/>
        <path class="portfolio_main_lines" d="M1335.9,446.3c-0.2-1.3-1.1-1.9-2.1-1.7c-1.7,0.4-3.6,1.1-4.1,3.1c-0.4,1.6,1.1,1.8,2.3,1.7
            C1334,449.4,1334.9,447.8,1335.9,446.3z"/>
        <path class="portfolio_main_lines" d="M875.2,627.5c4.4-0.9,6.7-2.6,8.1-6.1C879.2,621.5,877.3,623.7,875.2,627.5z"/>
        <path class="portfolio_main_lines" d="M1060.8,730.5c5.5,1,7.3-2.1,8.8-5.6C1066.9,726.6,1064.2,728.3,1060.8,730.5z"/>
        <path class="portfolio_main_lines" d="M1001.9,591.6c2-2.5,6-3.3,6.4-7.7C1004.6,585.5,1002.9,588.2,1001.9,591.6z"/>
        <path class="portfolio_main_lines" d="M398.7,671.9c0.3,1.3,1,2,2.1,1.9c2-0.3,3.3-1.5,3.2-3.7c0-1.1-0.3-3-1.6-2.3
            C400.8,668.6,399.9,670.5,398.7,671.9z"/>
        <path class="portfolio_main_lines" d="M962.7,672.3c-1-0.1-2,0.4-1.4,1.3c1.3,1.8-1.2,5.1,1.8,5.5c1.8,0.2-0.8-3.8,2.2-4.2
            C965.1,673.3,963.9,672.7,962.7,672.3z"/>
        <path class="portfolio_main_lines" d="M725.5,267.5c-2.1,3-1.1,5.3,0.5,7.4c0.4,0.6,1.4,0.7,1.7,0.1c1.1-2-0.9-3.6-1.1-5.5
            C726.5,269.1,726.2,268.7,725.5,267.5z"/>
        <path class="portfolio_main_lines" d="M374.7,630.3c-3.4,0-6.9,0-10.3,0c-0.9,0-1.7,0.2-1.4,1.4c0.1,0.3,0.9,0.8,1,0.7
            C367.2,629.9,371.3,632.5,374.7,630.3z"/>
        <path class="portfolio_main_lines" d="M632.2,291.9c0.2,1.6,0.2,3.5,2.2,3.9c1.3,0.2,1.7-1.3,1.7-2.5c0-1.9-1.1-3.2-2.8-3.5
            C631.7,289.5,632.3,291,632.2,291.9z"/>
        <path class="portfolio_main_lines" d="M893.4,752.3c2.5,1.7,4.4,1.7,6.9,0.3C898,750.8,896,750.9,893.4,752.3z"/>
        <path class="portfolio_main_lines" d="M1390.2,336.9c-2.4-2.9-4.5-4.5-7.3-4.8C1384.1,334.9,1386,336.7,1390.2,336.9z"/>
        <path class="portfolio_main_lines" d="M1462.7,449.3c-0.9-2-2-3.9-4.5-3.6c-0.9,0.1-1.7,1-1.2,1.7C1458.2,449.5,1460.5,449,1462.7,449.3z"/>
        <path class="portfolio_main_lines" d="M413.5,555c0.1-1.2-0.5-1.7-1.4-1.8c-1.8-0.1-2.6,1.1-3.2,2.6c-0.4,1,0.1,1.7,1.2,1.4
            C411.6,556.9,413.1,556.6,413.5,555z"/>
        <path class="portfolio_main_lines" d="M369.5,603.6c-2.4-2.8-5-2.8-7.8-0.9C364.3,602.9,366.9,603.2,369.5,603.6z"/>
        <path class="portfolio_main_lines" d="M1324.8,301c1.6,1.3,3.1,3.2,5.4,1.9c0.9-0.5,1.3-1.8,0.5-2.5C1328.8,298.6,1326.9,300.4,1324.8,301z"/>
        <path class="portfolio_main_lines" d="M1336.3,227.4c0.1,3.1-0.2,5.2,1.7,6.8c0.6,0.5,1.5,0.1,1.6-0.5C1340.2,231.3,1339,229.6,1336.3,227.4z"/>
        <path class="portfolio_main_lines" d="M782.6,729.9c-2.1,0.6-4.3,1.1-4.6,3.7c0,0.4,0.9,1.4,1.1,1.3C781.6,734.3,781.7,731.9,782.6,729.9z"/>
        <path class="portfolio_main_lines" d="M1060.3,658.3c-0.3-0.3-0.6-0.5-0.9-0.8c-2,2-4.4,3.7-4,7.2C1057,662.6,1058.6,660.5,1060.3,658.3z"/>
        <path class="portfolio_main_lines" d="M1044.9,608.1c-5.5,1.8-10.9,4.1-16.7,4.7c-4.1,0.4-10.9-5.2-11.1-9c-0.1-1.6,1.5-2.2,2.8-1.9
        c6.3,1.6,11.3-1.6,16.6-4.8c-2.3-0.1-4.6-0.3-8.1-0.5c5.1-3.3,9.8-3.7,14.2-3.3c5.1,0.5,9.8-0.8,14.7-1.3c2.2-0.2,2.5,0.7,2.7,2.4
        c0.1,1.2,1.2,2.8-1,3c-6.1,0.5-7.9,5.3-10.1,9.7C1047.4,606.9,1046.1,607.5,1044.9,608.1z"/>
        <path class="portfolio_main_lines" d="M516.1,458.9c5.3-4.1,10.3-9,17.6-9.5c2.8-0.2,5.3,0.1,7.3,2.2c1.5,1.6,3.4,3.3,2.6,5.6c-0.8,2.4-3.2,0.9-4.8,1
        c-2.7,0.2-5.7,0.6-7.6,2.3c-7,6.1-17.1,6.7-23.3,14.3c-1.6-2.7-1.5-4.3,0.8-6c2.3-1.6,4.1-3.9,4.8-6.8
        C514.3,461,515.2,460,516.1,458.9z"/>
        <path class="portfolio_main_lines" d="M598.5,422.2c0.7,0.9,1.2,2,2.1,2.7c2,1.8,1,2.9-0.9,3.5c-5.2,1.6-10.3,3.2-15.1,6c-2.6,1.5-7.2-2-6.8-4.8
        C583.9,424.9,591.2,423.7,598.5,422.2z"/>
        <path class="portfolio_main_lines" d="M745.3,465.1c3.2,5.3,7.7,2.8,11.8,2.9c3.5,0.1,7.8-0.4,7.6,5.4c0,1.7,1.9,1.6,3.2,1.5c3.3-0.4,4.6,0.8,4.2,4.2
        c-0.1,1.1,0.6,2.2,0.8,3.3c0.7,3.1-1.3,4.9-3.4,3.3c-7.1-5.3-14.1-1.4-21.1-0.5c-3.9,0.5-7.5,0.4-11.3-0.1
        c-10.2-1.4-20.2,1.6-30.3,1.7c-0.9,0-2.2,0-2.2,1.4c-0.1,1.2,0.9,1.7,1.9,1.9c0.8,0.2,1.7,0.2,2.5,0.4c4.8,1.2,9.2,3,11.2,8
        c0.7,1.9,1.9,2.6,3.8,2.6c1,0,2.1-0.2,2.6-1.2c0.6-1.3-0.6-1.6-1.4-2.1c-1-0.6-2.2-1.1-1.7-2.6c0.6-1.5,2.1-1.7,3.4-1.6
        c5.2,0.2,10.4,1.1,14.8,3.9c2.1,1.4,4.3,2.4,6.7,3.3c0.2,2-3.2,0.6-2.4,2.7c0.7,1.9,2.8,1.2,4.7,1.6c-1.7,3.7-3.3,6.8-7,8.4
        c-1.6,0.7-3.1,2.3-2,4c2.4,3.6-0.6,4.6-2.4,6.3c-6.2,5.6-13.4,6.6-21.3,4.5c-4-1.1-7.8-0.6-11.6,1.2c-5.7,2.6-13.1-0.5-15.7-6.2
        c-1.1-2.4-2.4-4.7-3.6-7.1c-2.2-4.7-5.5-6.6-10.6-4.5c-3.2,1.3-4.8-1-6.7-3.5c-1.8,4.4-2.6,8.4,0.1,11.8c3.5,4.3,4.8,9.4,7.5,14
        c1.5,2.5,2.6,5,5.9,6.2c3,1.1,2.1,4.5,1.1,7c-0.8,1.9-1.8,3.4,0.2,5.3c1.6,1.6-0.2,2.4-1.4,2.7c-5.8,1.2-11.6,3.8-18.2,0.8
        c3.4-1.7,5.9-4.4,9.6-4.9c3.1-0.4,3.8-2.7,2.6-5.2c-2-4.3-3.8-9-8.9-10.9c-2.8-1.1-2.9-4.3-4.5-6.3c-4.2-5.4-8.9-6.7-15-3.4
        c-6.6,3.5-13.3,6.9-20,10.1c-2.3,1.1-4.3,2.4-6.1,4.3c-1.8,1.9-3.5,3.9-7.1,4.8c0.7-2.7,1-4.9,1.9-6.7c2.1-3.9,0.1-4.8-3.2-4.8
        c-3.9,0.1-5.8-2.4-6.9-5.5c-3-8.8-10.1-13.3-18-16.5c-6.1-2.5-10.8-6.5-15.4-10.8c-0.7-0.7-1.1-1.7-1.9-2.3
        c-1.5-1.4-2.9-3.4-5.1-3.7c-1-0.1-1.2,1.4-1.3,2.4c-0.2,2.7-0.1,5.5,2.6,7.1c6,3.6,11.8,7.4,18.2,10.2c1.4,0.6,2.8,1.2,4.1,1.8
        c1.5,0.7,2.4,1.9,2.3,3.5c-0.2,2.3-2.2,1.6-3.5,1.8c-5,0.8-10-1-14.9,0.2c-0.8,0.2-1.7,0-2.5,1.7c3.6,0.2,6.1,4.6,10.3,0.6
        c1.4-1.3,10.9,3.2,12.2,5.4c0.6,1.1,1.1,2.3,1.7,3.5c-3.3,0.2-5.8-1.7-8.7-2.3c-5.9-1.3-7.8,0.1-6.1,5.8c1.4,4.4-0.6,4.6-3.7,4.5
        c-0.7,0-1.3,0-2-0.1c-11.1-0.4-15.9-4.4-18.7-14.9c-2.3-8.7-4.4-17.4-2.7-26.5c0.3-1.8-0.4-2.5-2-3.4c-2.5-1.4-1.5-4.3-1.5-6.4
        c0-2.2,2.4-0.6,3.6-0.8c9.4-1.5,10.7-3.8,6.4-12.7c-0.3-0.6-0.5-1.2,0-2.3c1.3,1.9,3,3.6,3.9,5.6c1,2.3,1.5,4.8,4.8,2.7
        c0.5,1.9-0.7,3.2-0.9,4.6c-0.3,2.2-1.9,5.3,2.9,3.5c1.9-0.7,2.6,1.9,4.5,2c0-0.5,0.1-0.9,0-1.3c-0.3-1.7-3.2-2.1-2.3-4
        c1-2.1,3.2-1.2,5-1.1c3.2,0.2,2.9-2.9,4.2-4.4c2.2-2.6,4-3.9,5.8,0c0.7,1.5,1.6,2.4,3.3,2.8c6.4,1.3,9.8,5.5,11.3,12.3
        c-1.6-1.1-2.7-1.9-3.9-2.7c-0.2,0.2-0.5,0.4-0.7,0.6c3.8,5.7,7.6,11.4,11.4,17.1c0.5,0.7,1.2,1.3,2.1,0.8c0.4-0.3,0.6-1.1,0.7-1.7
        c0.4-2.6-1.7-4.6-1.6-7.3c5.2,6.1,7,14,10.4,21.5c3.2-2.7,3.4-6,1.9-9c-5.3-10.4-9.3-21.6-18.7-29.6c-3.2-2.8-6.2-5.8-9.7-8.4
        c-0.8-0.6-2.3-1.2-1.2-2.7c0.9-1.2,1.9-0.7,3.1-0.3c2.6,0.9,4,5.4,7.9,2.5c0.5-0.3,2,0.9,1.9,1.9c-0.5,5,4.1,5.9,6.8,6.6
        c5,1.2,8.7,4.2,12.7,6.8c4.6,2.9,6,8.3,6.5,13.5c0.4,4.3,1.4,8.4,2.6,12.9c2.4-1.2,2.6-3.2,3.1-5.1c2.2-8.5,5-16.7,10.1-24.1
        c1.8-2.7,3.1-5.8,1.1-9.7c-3.8-7.3,0.9-13.6,9.4-13.2c1.3,0.1,2.8,0.6,4,0.2c6.2-2.3,6.3,1.3,6.1,5.7c-0.2,3.4,0.3,6.7,2.7,9.3
        c1.2,1.3,2.9,2.4,3.8-0.1c1.2-3.2,3.6-6.5,2.2-10c-2.8-7.1,1.7-11.9,4.8-17.9c1.7,2.5-0.6,6.3,3.2,7.4c1.8,0.5,3.1,1.4,3.5-1.3
        c0.1-0.6,0.3-1.4,0.7-1.8c1.8-1.8,3.3-5.1,5.9-4.8c2.7,0.3,0.1,3.6,1,5.1c1.9,3.5,2.7,7.6,6.2,10.1c1.3,0.9,2.9,1.1,4.2,0
        c1-0.9,0-1.7-0.6-2.4c-6.9-8.3-4.6-16,5.6-19.4c0.5-0.2,1-0.2,1.5-0.3c2.2-0.4,3.7-1.4,3.8-3.8s-1.8-3-3.5-3.6
        c-1.8-0.6-3.3-0.9-2.7-3.7c0.8-3.5,2.2-6.4,5.7-7.4c2.5-0.7,3.1,1.6,4,3.5c1.9,4.2,4.5,8.2,0.9,12.8c-0.6,0.8-0.9,2.5,0.7,3.8
        c1.2-1.8,2.3-3.6,3.6-5.3c2-2.6,4.3-4.3,7.7-2.5c0.7,0.4,1.7,0.8,2.4,0.6c7.6-2.8,15.5,0.5,23.2-1.5c5.7-1.4,11,4.5,10.4,10.3
        c-0.2,1.9-0.3,2.1-2.4,2.1c-3.4,0-7.7,0.2-9.9,2.2c-5.9,5.4-13.2,5-20.1,6.3c-3.9,0.7-4,5.2-7.5,6.2c-0.9,0.3,0.2,2.1,1.4,2.6
        c1.7,0.7,3.6,0.9,5.2,1.6c2.6,1.1,7.7,0.6,3.9,6.1c-1,1.5,0.5,1.9,1.9,1.5s2.9-0.8,4.3-1.1c1.5-0.4,3.8,1.5,4.5-0.6
        c0.6-1.9-1-3.7-2.7-4.9C746.6,470,746.4,467.5,745.3,465.1z M720.4,513.1c1.2,0,6.6-3.3,6.6-4c0-1.1-0.9-1.5-1.7-1.3
        c-2.6,0.6-5.3,1.2-6.7,3.6C718,512.4,719.3,513,720.4,513.1z M686.2,488.2c-2.9,5.6-2.2,8.6,2.4,11.7
        C687.8,495.9,687.1,492.5,686.2,488.2z"/>
        <path class="portfolio_main_lines" d="M1199.4,628.8c-3.3,3.8-6.7,7.6-9.9,11.5c-0.9,1-1.6,2.5-3,2.4c-5.7-0.4-8.3,2.7-9.5,8.1
        c3.5-13.4-2.9-27.2,3-40.1c-2.1-0.7-2.5,0.8-2.8,1.7c-1.8,5.2-3.9,4.2-6.5,0.6c-1.8-2.4-4.5-3.9-7-5.5c-3.7-2.2-6.6-5-7.3-9.6
        c-0.1-0.8-0.3-2-0.8-2.3c-6.3-3.2-2.1-8.1-0.7-10.8c3.6-7,9.6-12.3,15.9-17c3.4-2.6,9.9-1.7,13.5,1.2c5.3,4.2,10.7,8.4,16.1,12.6
        c1.3,1,2.6,2.7,4.8,1.1c1.7-1.2,1.8,0.8,2.3,1.6c2.9,5.7,7.3,10.2,12.2,14.3c2.9,2.4,1.9,3.5-0.8,5c-8.6,4.6-16.5,10.4-25.8,13.8
        c1.1,2.1,2.1,1.2,2.8,0.9c3.2-1.4,3.9,0.2,3.6,3C1199.1,623.7,1197.5,626.3,1199.4,628.8C1199.4,628.9,1199.4,628.8,1199.4,628.8z"
        />
        <path class="portfolio_main_lines" d="M721.3,565.4c2.7,0.8,4.7,2.4,6.6,4.3c1.6,1.5,3.1,3,4.7,4.5c2.1,2,4.4,3.8,7.5,2.2c1.1-0.6,1.6-1.9,1.1-3
        c-1.3-3.1-3.9-4.7-7.1-4.5c-3.8,0.3-5.6-1.3-5.1-4.8c0.6-3.9-1.6-5.4-4.5-6.6c-1.7-0.7-3.5-1.5-3.2-3.8s1.9-3.6,4-3.9
        c2.7-0.5,5.3-2,8.4-1.4c-0.4,2.4-3.4,2.7-3.9,5.4c1.7,0.2,2.8,1.6,4.2,2.7c2.7,2.2,5.7,3.3,9.6,3c9.3-0.8,17.6,1.5,22.6,11
        c3.3-4,1.1-7.1-1-10.8c5.3,2,5,7.7,9.4,10.7c-1.3-4.4,2.6-7.2,0.7-11c6.1,1.5,6.2,1.6,3.3,6.7c-4.7,8.4-11.3,14.9-19.1,20.8
        c-6,4.5-12.7,7.9-18.3,12.9c-2.3,2.1-5.1,3.8-7.8,5.4c-5.7,3.3-5.9,3.3-7.2-3.1c-0.6-2.8-1.7-3.3-4.6-3.7c-3.6-0.6-7-3.2-10.4-4.9
        c-0.1-0.1-0.3-0.2-0.4-0.3c-1.7-2.4-6.3-1.1-6.5-5.2c-0.2-3.3,2.6-5.2,4.2-7.5c1.9-2.8,1.2-4.9-0.3-7.3c-1.7-2.8-3.8-5.5-1.6-9.1
        c1.4-2.2-1.9-3.8-1-7c5.2,7.9,12,13.4,18.1,20.7C723.7,572.6,718.8,569.9,721.3,565.4z M737.1,583.9c-0.2-3.3-1.9-5.7-5-6
        c-2.2-0.2-1.9,2.7-2.1,4.2c-0.3,3.6,3.2,3.3,5.3,4.1C736.9,586.6,737.1,585.1,737.1,583.9z"/>
        <path class="portfolio_main_lines" d="M582.4,453c-2.5,0.8-3,2.6-4,4.1c-1.5,2.5-6.8,4-8.6,2.2c-2.1-2.1-1.8-5.4,0-7.3c5.5-5.7,10.4-12.1,17-16.7
        c1.4-0.9,3.4-2.8,4.9-1.1c1.9,2.2-1,3.3-2,4.8c-2.1,3.2-6.2,5.2-6,9.8c1,0.9,1.6,0.2,2.2-0.4c13.2-12.8,28.4-12.8,44.5-7.1
        c5.7,2,11.3,4.2,14.5,10c0.6,1.2,1.5,2.3,1.6,3.6c0.3,2.4,1.3,5.4-0.9,7.1c-2.5,1.9-4.6-0.5-6.5-2c-1.6-1.3-3.3-2.5-5.3-2.5
        c-7.7-0.1-14.3-4.5-21.7-5.2c-10.4-1.1-21.1-1.6-29,7.6c-1,1.2-2.2,2.6-3.7,1.4c-1.5-1.3,0.4-2.3,1.1-3.3
        C581.4,456.7,582.2,455.2,582.4,453z"/>
        <path class="portfolio_main_lines" d="M907.9,411.3c-4.6,4.3-6.2,11-11.4,14.9c-0.8,0.6-2.2,1.7-0.7,2.8c1.9,1.4,4,3.6,6.6,1.7c2.4-1.7,4.8-3,7.8-2.9
        c1,0,2.3-0.3,2.7,0.7c0.6,1.3-0.8,2-1.6,2.7c-0.6,0.5-1.4,1-2.1,1.3c-15.2,6.6-29.4,15.4-45,21.1c-2.6,1-5.2,1.4-7.8,0.8
        c-4.1-1-8.3-0.3-12.4-0.4c-1,0-2.6,0.7-2.9-0.9c-0.3-1.4,1.2-2,2.2-2.4c5.3-2,9.6-5.4,15.5-6.8c6.4-1.5,12.3-6.3,17.5-11.1
        c3.3-3,6.4-6.2,11.6-5.7c2.3,0.2,4.7-1.4,6.2-3.7C897.4,418,902.1,414.1,907.9,411.3z"/>
        <path class="portfolio_main_lines" d="M797.5,523.2c-0.2-2.9,2.2-4,3.5-6.9c-3.5,2-4.2,4.6-5.5,6.8c-1.5,2.5-3,3.8-4.6,0c-0.6-1.4-1.5-2.6-2.1-3.9
        c-0.8-1.9-3.2-3.9-1.4-5.7c2.2-2.3,3.9-6,8.5-4.5c3.7,1.2,7.4,1,8.8-3.7c0.2-0.7,0.6-1.8,0.3-2.3c-0.5-1-1.7-0.7-2.7-0.5
        c-2.1,0.6-5,2.8-5.7-0.7c-0.7-3.3,2.7-3.9,5.2-3.7c9.1,0.4,17.5-1.7,25.3-6.6c3.4-2.2,7.3-3.5,11.2-5.3c0.8,2.7-0.4,4.1-2.1,4.8
        c-8.9,4.1-15.2,11.1-21.1,18.6c-3.5,4.5-7.6,8.3-13.2,10.3C800.4,520.5,799.1,522,797.5,523.2z"/>
        <path class="portfolio_main_lines" d="M979.3,439.9c3.6,1,5.6-1.9,7.6-3.7c2.2-2,3.9-1.6,5.7-0.4c3.9,2.8,7.5,5.9,10.4,9.8c1,1.3,0.6,2.5-0.1,3.1
        c-1.2,1.1-2.1,3.1-4.3,2.4c-2.9-1-6.1-1.1-8.4-3.7c-1.1-1.2-3.1-2.1-4.4-0.6c-1.7,2,1.2,2.1,1.9,3.2c1.6,2.4,2.7,3-1.6,4.7
        c-7.9,3.1-16.4,2.1-24.2,5c-1,0.4-2.3,0.2-3.5,0.2c-1,0-2.2-0.1-2.2-1.4c0-1,1.1-1.6,1.8-1.3c3.6,1.3,3.8-5.5,7.8-3.1
        c0.4-1.2,1.1-2.5,1.2-3.7c0.3-4.8,2.8-7.9,7.3-8.6c1-0.2,2.9,0.7,1.8-1.7C977.1,440,978.2,440,979.3,439.9z"/>
        <path class="portfolio_main_lines" d="M831.3,475.7c-7,3.7-10.3,13.7-19.8,12.1c-6.4-1.1-12.9,0.8-19.3-2.6c-2.6-1.4-6.5,1.6-8.8,4.5
        c-0.9,1.1-1.5,3.1-3.3,2.3c-2.5-1.1,0.2-1.9,0.4-2.8c0.3-1.8-3.3-0.5-2.6-3.3c0.7-2.9,0.5-5.6,3-8.2c3.2-3.3,1.7-8.8,3.6-13
        c0.5-1.1,0.2-2.4,1.9-2c1.3,0.3,2.9,0,3.5,1.4c0.7,1.5-1,2-1.9,2.4c-2.8,1.3-4.1,3.7-3.9,6.4c0.2,2.7,3.3,2.1,5.2,3
        c0.9,0.4,2.2,0.4,2.7,1c4.7,5.4,10.7,3.4,16.5,3.4C816.5,480.4,823.7,477.9,831.3,475.7z"/>
        <path class="portfolio_main_lines" d="M737.2,650.3c-0.1-6,5.7-9.4,11.3-6.5c1.9,1,4.1,2.2,5.1,3.9c1.2,2.1-1.6,3.2-2.5,4.9c-0.6,1.2-1.5,2.4,0.2,3.4
        c4.1,2.5,0.8,3.8-1,4.8C742.7,664.8,737.5,660.6,737.2,650.3z"/>
        <path class="portfolio_main_lines" d="M1035.2,447.9c-2.9-0.2-5.7-0.7-6.7-4c-0.6-2-2.2-3.3-3.3-4.9c-1.2-1.8-3.3-3.8-0.8-5.5
        c2.4-1.6,5.3-4.3,8.4-2.1c3.4,2.4,6.2,5.5,7.4,9.7C1041.4,445.1,1039.4,447.8,1035.2,447.9z"/>
        <path class="portfolio_main_lines" d="M580.2,468.8c11.9-13.3,23.6-12.1,37.4-6.1c1.7,0.8,3.4,2.4,2.2,4.3c-1.1,1.8-3,0.9-4.3-0.3
        c-3.3-3-7.1-3-11.2-2.2C596.3,466.1,587.9,465,580.2,468.8z"/>
        <path class="portfolio_main_lines" d="M1206.4,659.3c-4.9,2.3-7.6,5.9-9.5,10.3c-1.3,3.1-3.4,5.7-6,7.9c-1.8,1.5-3.2,1.8-3.9-1.1
        c-1.7-7.3,2.4-14.5,9.5-16.5C1199.5,659.1,1202.3,656.2,1206.4,659.3z"/>
        <path class="portfolio_main_lines" d="M803.2,638.6c-2.3,3.4-1.2,7.2-1.5,10.7c-0.3,4.1-2.9,6-7.8,5.3c-3.9-0.6-3.4-3-2.5-5.9
        c0.5-1.7-0.3-3.7,0.3-5.4c0.7-2.2,0.3-6.4,5.2-3.7C798.3,640.6,800.5,638.1,803.2,638.6z"/>
        <path class="portfolio_main_lines" d="M786.4,567.5c-1-4.1,1.5-6.7,2.6-9.7c1.5-3.9,5.3-6.5,6.5-10.7c0.3-0.9,1.3-1,2.1-0.6c4.2,2.3,4.7,10.7,0.7,14
        C794.9,563.3,791,565.4,786.4,567.5z"/>
        <path class="portfolio_main_lines" d="M937.8,522.4c0.8,2-0.8,2.8-1.1,4.7c2.6-1.2,4.2-3.7,7.8-2.8c-9.2,5.4-9,18.1-19.8,23.6
        c2.6-6.5,5.4-12.1,6.9-17.9C932.5,526.2,933.7,523.5,937.8,522.4z"/>
        <path class="portfolio_main_lines" d="M849.3,521.9c0.3-0.7,1-1.4,0.9-2c-1.8-9.6,5.9-12.2,11.8-15.9c2.4-1.5,4.6-3,7.6-5.9
        C867.2,510.9,854.5,513.2,849.3,521.9z"/>
        <path class="portfolio_main_lines" d="M790,599.3c-0.6-3.4-1.2-6.7-1.6-9.9c-0.2-1.5,0.4-3.7,2.1-3.3c3.3,0.8,5.8-0.5,8.6-1.5
        c1.7-0.6,3.5-0.5,4.5,1.1c1.3,2.2-1.1,2.3-2.1,3.1C797.5,591.9,795.7,597.3,790,599.3z"/>
        <path class="portfolio_main_lines" d="M976,491.8c-2.5,6.6-3.4,12.4-8.6,16.1c-2.1,1.5-4,1.1-6,0.1c2.1-2.1-0.7-4.6,0.9-7
        C965.6,496.4,970.9,495.5,976,491.8z"/>
        <path class="portfolio_main_lines" d="M547.3,530.5c2.1,7,7,11,12.8,14.3c1,0.6,2.4,1,2,2.4c-0.5,1.5-2,1.6-3.3,1.6c-6.9,0.2-13-6.6-12.1-13.5
        C546.9,533.7,547.1,532.1,547.3,530.5z"/>
        <path class="portfolio_main_lines" d="M764.2,651.5c0.9-4,3-4.9,4.4-6.5c1.9-2.3,4-4.6,3.5-7.9c-0.3-1.9,0.4-2.3,2-2.5c2-0.3,4-3,5.9-1.1
        c1.8,1.9-0.4,4-1,6C776.8,646.2,770.3,647.4,764.2,651.5z"/>
        <path class="portfolio_main_lines" d="M971.1,514.6c3.1-8.9,8.4-15.7,13-22.9C983.9,502.2,978.5,512.2,971.1,514.6z"/>
        <path class="portfolio_main_lines" d="M731.7,533.7c-7.1,2.3-13.2,6.2-19.3,10c-1.7,1.1-2.8,0.9-2.7-1.5c0.2-3.8,0.7-6.4,5.9-6.4
        C720.9,535.7,726.4,535.7,731.7,533.7z"/>
        <path class="portfolio_main_lines" d="M912,534.2c0.8-2.3,0.3-5.1,3-6.5c0.8-0.4,1.5-1.2,1.1-2c-2.2-3.7,1.5-5.3,2.8-7.6c0.5-1,1.4-2.6,3-1.4
        c1.1,0.8,0.9,2.1,0.2,3.2c-2.9,4.8-5.1,10-7.1,15.2c-0.3,0.9-0.6,2.1-1.9,1.9C911.6,536.6,911.7,535.3,912,534.2z"/>
        <path class="portfolio_main_lines" d="M1065.2,573.1c2-7,9.2-11.5,17.6-11.5C1076.5,564.2,1072.4,570.2,1065.2,573.1z"/>
        <path class="portfolio_main_lines" d="M891.5,487.9c-1.5,0-3.5,0-3.9-1.5c-0.5-1.7,1.5-2.2,2.8-2.8c1.6-0.8,3.3-1.6,4.9-2.3c1.4-0.6,3.3-2.2,4.2,0
        c0.6,1.5-0.1,3.6-2,4.6C895.7,487,893.7,487.9,891.5,487.9z"/>
        <path class="portfolio_main_lines" d="M1117.5,472.3c3.4,1.4,7.5,1.2,10.4,4.1c1,1,1.8,2.2,0.5,3.5c-1.2,1.3-2.7,0.6-3.4-0.6
        c-1.7-2.9-3.8-3.7-7.1-2.9c-1.4,0.3-3.3-0.6-3.5-2.2C1114.2,472.4,1116.2,472.7,1117.5,472.3z"/>
        <path class="portfolio_main_lines" d="M752.1,514.7c1.3-3.6,3.6-6.3,2.7-9.9c-0.4-1.7,1.1-2.3,2.4-2.6c1.7-0.4,1.8,1,2.1,2.1
        C760.8,508.5,759.1,511.1,752.1,514.7z"/>
        <path class="portfolio_main_lines" d="M559.9,461.8c-3.8,0.2-7.6,0.4-11.4,0.6c-1.3,0.1-3.1,0.2-3.4-1.3c-0.4-2.1,1.8-1.9,3.1-2.3
        C552.7,457.2,556.4,459,559.9,461.8z"/>
        <path class="portfolio_main_lines" d="M925,523c-1.8,5.4-2.2,11.3-6.4,15.6C920.3,533.3,919.9,527,925,523z"/>
        <path class="portfolio_main_lines" d="M691,542.3c2.9,4.2,5.6,8.5,9.6,12.4C695.9,554.2,692,549,691,542.3z"/>
        <path class="portfolio_main_lines" d="M1147.2,602.5c0.6,6.9,0.4,7.1-8.1,9.4C1142,609.3,1145.2,606.9,1147.2,602.5z"/>
        <path class="portfolio_main_lines" d="M677.1,437.1c-3.3,0.7-5.8,1.1-8.3,1.6C669.9,434.2,671.9,433.7,677.1,437.1z"/>
        <path class="portfolio_main_lines" d="M679.5,524.6c4,2.7,4,2.7,4.3,7.6C680.6,530.5,679.5,528.1,679.5,524.6z"/>
        <path class="portfolio_main_lines" d="M1052.4,452.3c-0.6-3.9-1.2-7.3-0.5-11.2C1055,446.9,1055.1,447.5,1052.4,452.3z"/>
        <path class="portfolio_main_lines" d="M841,473.9c-1.5,3.7-5.3,4.6-7.9,7C834.7,477.3,837.8,475.5,841,473.9z"/>
        <path class="portfolio_main_lines" d="M1014.1,445.6c-1.8-2.7-3.6-5.5-5.3-8.2c3.4,1.6,6.1,3.7,6.5,7.4C1014.9,445,1014.5,445.3,1014.1,445.6z"/>
        <path class="portfolio_main_lines" d="M562.8,440.7c0.9,1.9-0.7,3-0.7,4.4c-0.1,1.5,0.2,4.1-2.2,3.4c-2-0.6,0-2.5,0.3-3.8
        C560.5,443.1,561.1,441.6,562.8,440.7z"/>
        <path class="portfolio_main_lines" d="M783.6,625.3c-0.1,1.1-0.7,2-1.9,1.8c-1.3-0.2-2.1-1-2-2.4c0-1,0.8-1.4,1.7-1.4
        C782.7,623.3,783.5,623.9,783.6,625.3z"/>
        <path class="portfolio_main_lines" d="M1489.1,634.4c0.1-0.4,0.2-2,0.7-2.2c4.5-1.6,4.8-6.6,7.8-9.2c4.2-3.6,7.1-8.1,8.1-13.4
        c1.1-5.8,5.8-8.6,9.1-12.5c2.7-3.2,5.6-2,6.9,2.1c2.4,7.8,1.2,10.4-6.4,14c-0.9,0.4-2,1-2.3,1.8c-3.8,8.6-12.6,13.8-15.7,22.8
        c-0.6,1.6-1.6,4.3-4.2,3.1C1490.7,639.9,1489.2,637.8,1489.1,634.4z"/>
        <path class="portfolio_main_lines" d="M1172,799.3c1.9,2.3,2.8,3.4,4,4.7c-1.8,0.4-2.7-0.6-3.8-1.4c-6.3-4.3-13-5.9-20.6-3.7
        c-2.8,0.8-5.8,1.6-7.9-2.3c-0.5-0.9-4.4-1.5-6.8-2.1c-1.1-0.3-2.7-1.9-1.1-2.1c3.1-0.5,3.8-3,5.5-4.6c0.6-0.6,2.1-0.8,2.2-0.1
        c1.4,5.9,5.9,2.4,9,3c0.6,0.1,1.2,0.4,1.8,0.6c0,1.9-3.6,3-1.5,4.6c1.5,1.2,4.7,2.3,6.3,0.2c6.6-9,16.3-8.4,25.8-9
        c-1.5,2.6-1.5,2.6-10.8,3.4c4.8,1.5,8.4,3.7,12.1,6C1182.5,800.7,1178,800.3,1172,799.3z"/>
        <path class="portfolio_main_lines" d="M1181.9,757.8c-2-2.6-3.8-4.8-1.9-7.9c0.5-0.8,0.4-1.9-0.3-2.3c-1.8-0.8-3.6-1.6-2.5-3.8c1.6-3,3.4-6,5.5-8.7
        c0.9-1.2,2.3-0.2,2.7,1c0.3,0.9-0.2,1.9-0.2,2.9c0.1,1.9-4.9,2.9-1.5,4.9c3.3,2,6.2-0.2,8-3.4c1.3,1.9-0.9,3.5-0.3,5.3
        c0.8,2.8-1,3.2-3.1,2.9c-3.7-0.6-5.7,0.3-4.8,4.6C1183.7,754.6,1183.4,756.3,1181.9,757.8z"/>
        <path class="portfolio_main_lines" d="M1144.1,783.9c3.4-6.8,7.3-11.8,13-15.2c1.4-0.8,3.1-1.1,4.7-1.7c3.2-1.2,1.6,2.9,3.8,3.4
        c1.1,0.2-0.1,1.8-0.9,2.1c-6.3,2.2-11.4,6.5-16.8,10.2C1147,783.2,1145.9,783.3,1144.1,783.9z"/>
        <path class="portfolio_main_lines" d="M1213.4,757.4c3.7-0.2,5.8,1.7,6.6,4.6c0.8,2.9-2.3,3.1-4,4.1c-0.7,0.4-1.5,0.8-2.3,0.8c-2.2,0.1-4.9,2-6.6,0.3
        c-1.9-1.9,1.8-2.8,2.1-4.7C1209.7,760,1209.8,756.9,1213.4,757.4z"/>
        <path class="portfolio_main_lines" d="M1534.6,632.4c-2.5,1.6,0.8,5.5-2.7,6.7c-1.7,0.6-7.6-5.5-7.6-7.7c0-2.6,1.4-4.3,4-4
        C1531.1,627.8,1533.7,628.9,1534.6,632.4z"/>
        <path class="portfolio_main_lines" d="M1228.6,760.3c-0.3,4-0.7,8,5.9,6c-4,4.2-8.8,7.1-14.7,9.1c-2.2-4.2,2.6-5.1,3.6-7.7
        C1224.6,764.8,1228.6,764.1,1228.6,760.3z"/>
        <path class="portfolio_main_lines" d="M1541.4,226.2c-1.1,4.8-5.3,4.3-7.2,6.5c-1.9,2.1-2.8,0.7-3.9-0.4c-1.8-1.7-1.5-3.3,0.8-4.1
        c2.8-1,5.7-1.6,8.5-2.4C1540.7,225.4,1541.4,225.7,1541.4,226.2z"/>
        <path class="portfolio_main_lines" d="M1287.5,884.4c-0.2,1.3-0.9,2.1-2.2,2.2c-1.4,0-2.1-0.9-2-2.2c0-1.3,0.9-2.1,2.2-2.1
        C1286.8,882.2,1287.5,883.1,1287.5,884.4z"/>
        <path class="portfolio_main_lines" d="M765.6,227.8c0.1,1.4,0,2.9-1.5,3.2c-1.9,0.4-2.6-1.4-2.8-2.8c-0.5-2.8-1.4-5.4-3.6-7.3c-2-1.8-1.4-3.2,0.5-4.3
        c1.8-1,4-2.4,5.4,0.6C765,220.5,765.3,224.2,765.6,227.8z"/>
        <path class="portfolio_main_lines" d="M1666.1,268.4c5.1,5.1,4.9,4.8-0.2,7c-3.7,1.6-7.5,3.3-8.5,8.2c-0.6,3-5.4,4.3-7.2,2c-3.1-4-7.7-5.1-11.9-6.8
        c-2.2-0.9-3-1.4-2.1-3.8c1.2-3.2-0.5-4.7-3.6-5c-0.8-0.1-1.7-0.1-2.5-0.1c-3.1,0-6.2-0.8-6.5-4.2c-0.3-3.4,1.8-6,5.6-6.2
        c2-0.1,2.8-1.5,3.8-2.9c2-3.1,5.1-4.5,8.5-4.3c5.8,0.4,11.6,1.3,17.3,2c1,0.1,2.1,0.4,2.8,0.9c3.9,2.8,7.8,5.3,12.4,6.7
        c2.2,0.7,3.2,2.8,0.5,3.7C1672.1,266.6,1670.3,269.3,1666.1,268.4z"/>
        <path class="portfolio_main_lines" d="M1613.8,274.4c-1,6.2-4,11.2-9.6,14.2c-5.2,2.8-10.3,1.2-13.9-3.7c-5.2-7.1-5.8-14-1.4-18.6
        c0.9-1,1.9-2.3,3.2-2.1c6,0.8,12.3-0.2,17.9,3.2C1612.8,269.1,1614.2,271.2,1613.8,274.4z"/>
        <path class="portfolio_main_lines" d="M322.2,779.9c-5.7,5.4-12.5,3.2-19.1,4.6C307.3,779.6,315.4,777.7,322.2,779.9z"/>
        <path class="portfolio_main_lines" d="M1387.6,308.4c-2,1.8-0.5,6.4-4.7,6.1c-2.2-0.1-6.6-1.2-5.5-3.3c1.3-2.4,3.3-5.8,6.4-6.8
        C1385.5,303.8,1387.2,306.1,1387.6,308.4z"/>
        <path class="portfolio_main_lines" d="M612.5,747c-3.9,1-7.7,2.7-12,2.1c-2.2-0.3-3.6,1.3-4.9,3c-1.1,1.4-2.6,3.7-4.2,1.5c-1.3-1.9-2.2-5.4,0.2-6.9
        c2.8-1.7,4.5-3.1,3.3-6.6c-0.4-1.2,0.5-2.3,1.5-2.9c2.4-1.5,1.7-2.8-1.2-4.4C603.5,734.2,609.5,739.4,612.5,747z"/>
        <path class="portfolio_main_lines" d="M667.2,721.7c1.8,4.8,2.5,9.4,6.3,12.4c4.4,3.5,9,5.7,14.7,4.1c1.5-0.4,2.9-1.6,2.9,1.7c0,1.4,3.8,2.7,5.6,4.4
        c2.7,2.5,2,5.6,0.2,8.2c-1.4,2.1-4.5,3.1-6.1,1.5c-2.8-2.8-6.3-2.7-9.4-4c-0.9-0.4-1.9-0.5-2.9-0.7
        C665,746.8,659.5,734.1,667.2,721.7z"/>
        <path class="portfolio_main_lines" d="M1439.8,204.6c1.1,3.6-0.3,4.2-2.8,4.1c-2-0.1-4.1,0.7-4-2.6c0.1-3.1,1.1-6.2,4.2-6.4
        C1441.1,199.4,1439.1,203.4,1439.8,204.6z"/>
        <path class="portfolio_main_lines" d="M1420,228.2c1.7,1.9,3.5,3.8,5.1,5.7c0.6,0.7,1.7,1.5,0.7,2.4c-0.9,0.8-1.9-0.1-2.4-0.9c-1.4-2.2-2.7-4.5-4-6.7
        C1419.7,228.6,1419.8,228.4,1420,228.2z"/>
        <path class="portfolio_main_lines" d="M1370.1,436.1c-3.2,4.4-6.3,6.9-9.7,9.7C1361,441,1363.1,438.7,1370.1,436.1z"/>
        <path class="portfolio_main_lines" d="M1024.9,165.2c-1.1,1.5-2.1,3-3.2,4.4c-0.7,0.9-1.6,2.3-2.7,1.3c-1.4-1.3,0-2.4,1-3.2c1.4-1.1,2.9-2.1,4.4-3.2
        C1024.5,164.8,1024.7,165,1024.9,165.2z"/>
        <path class="portfolio_main_lines" d="M894.5,313.9c-1.4,0-2-0.7-1.9-1.8c0.3-2,1.1-4.2,3.1-4.7c1.8-0.4,2.4,1.7,2.4,3.1
        C898.1,312.6,895.9,313,894.5,313.9z"/>
        <path class="portfolio_main_lines" d="M1374.7,169.9c1.8,1.9,2,4.2,2.6,6.4c0.5,2.1-1.2,2.5-2.7,2.6c-1.6,0.1-2.7-0.6-2.3-2.4
        C1372.7,174.3,1372.8,171.9,1374.7,169.9z"/>
        <path class="portfolio_main_lines" d="M1172.1,403c4.3-5.9,9.7-9.8,16.8-11.1c1.2-0.2,2.8-1,3.3,0.8c0.4,1.3-0.9,2.1-2,2.4
        C1183.8,396.8,1178.8,401.6,1172.1,403z"/>
        <path class="portfolio_main_lines" d="M1069.1,640.8c0.8-3.3,3.6-4.6,7.2-6.9C1074.2,637.7,1071.4,638.9,1069.1,640.8z"/>
    </svg>
</template>
<script>
export default {
    name: 'PreloaderDrawPortfolio'
}
</script>
<style scoped>
.portfolio_main_svg {
  fill: none;
  z-index: 1;
}
.portfolio_main_lines {
    stroke: rgba(255,255,255,1);
    stroke-width: 1;
    stroke-miterlimit: 10;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation-name: draw_portfolio;
    animation-duration: 7s;
    animation-fill-mode: forwards;
    animation-play-state: running;
    animation-timing-function: ease;
    animation-iteration-count: 1;
}
@keyframes draw_portfolio {
    from { 
        stroke: rgba(255,255,255,0.25);
        stroke-dashoffset: 1000;
    }
    to { 
        stroke: rgba(255,255,255,1);
        stroke-dashoffset: 0;
    }
}
</style>