export default class Category {
  constructor(id, url, title, description, articles) {
    this.id = id;
    this.url = url;
    this.title = title;
    this.description = description;
    this.articles = articles;
  }
  static createFrom(data) {
    const {id, url, title, description, articles} = data;
    return new this(id, url, title, description, articles);
  }
}