export default [
    { 
        id: 126,
        url: 'flos',
        name: 'Flos', 
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/flos/flos_1.jpg',
                '../assets/img/pics/flos/flos_2.jpg',
                '../assets/img/pics/flos/flos_3.jpg',
                '../assets/img/pics/flos/flos_4.jpg',
                '../assets/img/pics/flos/flos_5.jpg',
                '../assets/img/pics/flos/flos_6.jpg',
                '../assets/img/pics/flos/flos_7.jpg',
                '../assets/img/pics/flos/flos_8.jpg',
                '../assets/img/pics/flos/flos_9.jpg',
            ],
            title: 'Компания Flos – современна философия освещения',
            about: 'Итальянский бренд является законодателем моды в сфере современного освещения. Дизайнеры компании смогли создать неповторимую «философию» света, выражая в форме изделий черты разных эпох и направлений.  Продукция этого бренда способна преобразить любое помещение.',
            website: 'https://flos.com'
        }
    },
    { 
        id: 127,
        url: 'italamp',
        name: 'Italamp', 
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/italamp/italamp_1.jpg',
                '../assets/img/pics/italamp/italamp_2.jpg',
                '../assets/img/pics/italamp/italamp_3.jpg',
            ],
            title: 'Бренд Italamp – необычный свет во имя жизни',
            about: 'Продукция этого итальянского бренда популярна во всем мире. Изделия компании выгодно отличаются особым дизайнерским почерком, благородными натуральными материалами. Люстры и светильники фабрики  достойно украшают именитые дома знаменитостей, крупнейшие мировые офисы и даже правительственные резиденции.',
            website: 'http://www.italamp.com'
        }
    },
    { 
        id: 128,
        url: 'masiero',
        name: 'Masiero', 
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/masiero/masiero_1.jpg',
                '../assets/img/pics/masiero/masiero_2.jpg',
                '../assets/img/pics/masiero/masiero_3.jpg',
                '../assets/img/pics/masiero/masiero_4.jpg',
                '../assets/img/pics/masiero/masiero_5.jpg',
                '../assets/img/pics/masiero/masiero_6.jpg',
                '../assets/img/pics/masiero/masiero_7.jpg',
                '../assets/img/pics/masiero/masiero_8.jpg',
            ],
            title: 'Бренд Masiero – поистине элитное освещение',
            about: 'Идеальный способ дополнить интерьер эффектными акцентами – это итальянские изделия Масиеро. Они выгодно отличаются своими нестандартными формами, а также нежным мягким светом. Данный итальянский бренд удачно сформировал разные стилевые направления (классика, хай-тек, минимализм).',
            website: 'http://www.masierogroup.com'
        }
    },
    { 
        id: 129,
        url: 'patriziagarganti',
        name: 'Patrizia Garganti', 
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/patriziagarganti/patriziagarganti_1.jpg',
                '../assets/img/pics/patriziagarganti/patriziagarganti_2.jpg',
                '../assets/img/pics/patriziagarganti/patriziagarganti_3.jpg',
                '../assets/img/pics/patriziagarganti/patriziagarganti_4.jpg',
                '../assets/img/pics/patriziagarganti/patriziagarganti_5.jpg',
                '../assets/img/pics/patriziagarganti/patriziagarganti_6.jpg',
                '../assets/img/pics/patriziagarganti/patriziagarganti_7.jpg',
                '../assets/img/pics/patriziagarganti/patriziagarganti_8.jpg',
            ],
            title: '',
            about: '',
            website: 'https://patriziagarganti.com'
        }
    },
    { 
        id: 130,
        url: 'jiso',
        name: 'Jiso', 
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/jiso/jiso_1.jpg',
            ],
            title: 'JISO ILUMINACION - эксперт в области светодиодного освещения',
            about: 'Обладая почти 20-летним коммерческим опытом в секторе технического освещения, JISO ILUMINACION обеспечивает всех своих клиентов страстью к освещению, дизайну внутренних и наружных светильников, светильников и источников света, качеству, а также новейшие светодиодные системы регулирования и управления. Всегда с оригинальным дизайном, который оживит все ваши проекты освещения в домах, коммерческих помещениях, на предприятиях или в общественных местах.',
            website: 'https://jisoiluminacion.es'
        }
    },
    { 
        id: 131,
        url: 'lodes',
        name: 'Lodes', 
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/lodes/lodes_1.jpg',
                '../assets/img/pics/lodes/lodes_2.jpg',
                '../assets/img/pics/lodes/lodes_3.jpg',
                '../assets/img/pics/lodes/lodes_4.jpg',
                '../assets/img/pics/lodes/lodes_5.jpg',
                '../assets/img/pics/lodes/lodes_6.jpg',
                '../assets/img/pics/lodes/lodes_7.jpg',
                '../assets/img/pics/lodes/lodes_8.jpg',
            ],
            title: 'Lodes - приверженность отличительному декоративному освещению.',
            about: 'Продукция Lodes представляет собой идеальный баланс между техническими ноу-хау и изысканной эстетикой. С 1950 года наша компания создает осветительные приборы, уделяя особое внимание лучшим технологиям производства и современным языкам дизайна, чтобы воплотить в жизнь настоящие декоративные элементы.',
            website: 'http://www.lodes.com'
        }
    },
    { 
        id: 132,
        url: 'jung',
        name: 'Jung', 
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/jung/jung_1.jpg',
                '../assets/img/pics/jung/jung_2.jpg',
                '../assets/img/pics/jung/jung_3.jpg',
                '../assets/img/pics/jung/jung_4.jpg',
                '../assets/img/pics/jung/jung_5.jpg',
                '../assets/img/pics/jung/jung_6.jpg',
            ],
            title: 'Бренд JUNG – совершенный мост между будущим и прошлым',
            about: '«Сделано в Германии» - и этим все сказано! Выберите освещение, идеальное для вашего жилья! С помощью инновационных решений можно легко выбрать удобный метод управления освещением: ручной, автоматический, с помощью радиосвязи и т.д. Виртуозные специалисты компании с ювелирной точностью творят неординарный дизайн каждого изделия.',
            website: 'https://www.jung.de'
        }
    },
    { 
        id: 133,
        url: 'fede',
        name: 'Fede', 
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/fede/fede_1.jpg',
            ],
            title: 'Бренд FEDE – великое искусство света',
            about: 'Изделия FEDE узнаваемы благодаря неординарным цветам и их удачным сочетанием. Продукция фабрики – самый настоящий эталон неоклассики в ее современном видении. В ассортименте представлены модели, достойные украсить самые именитые дворцы мира. Мастера компании гордятся тем, что их изделия украшают дома клиентов, ценящих уникальный стиль.',
            website: 'http://www.fedebcn.com'
        }
    },
    { 
        id: 134,
        url: 'legrand',
        name: 'Legrand', 
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/legrand/legrand_1.jpg',
                '../assets/img/pics/legrand/legrand_2.jpg',
                '../assets/img/pics/legrand/legrand_3.jpg',
                '../assets/img/pics/legrand/legrand_4.jpg',
                '../assets/img/pics/legrand/legrand_5.jpg',
            ],
            title: 'Legrand – идеальное воплощение лучших декоративных приемов',
            about: 'Говоря о стиле, мы подразумеваем Италию. Именно итальянские мастера создают высококачественное эксклюзивное освещение, придающее особый шарм любому интерьеру. В изысканной продукции компании улавливаются линии старинного барокко и современного модерна. Применение в интерьере изделий фабрики гарантирует дому изысканность и шик.',
            website: 'https://www.legrand.com'
        }
    },
    { 
        id: 135,
        url: 'faro',
        name: 'Faro', 
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/faro/faro_1.jpg',
                '../assets/img/pics/faro/faro_2.jpg',
                '../assets/img/pics/faro/faro_3.jpg',
                '../assets/img/pics/faro/faro_4.jpg',
                '../assets/img/pics/faro/faro_5.jpg',
                '../assets/img/pics/faro/faro_6.jpg',
                '../assets/img/pics/faro/faro_7.jpg',
            ],
            title: 'FARO Barcelona – яркая история успеха по-испански',
            about: 'Бренд Faro предлагает эксклюзивную продукцию качества люкс. Изделия в каталоге отличаются утонченным стилем и полным отсутствием избитых шаблонов. Инновационная эволюция света, безупречность абсолютно каждой детали – вот основные принципы этой легендарной компании.',
            website: 'https://faro.es'
        }
    },
    { 
        id: 136,
        url: 'deltalight',
        name: 'Delta Light',
        parent: 'lighting',
        content: {
            pics: [
                '../assets/img/pics/deltalight/deltalight_1.jpg',
                '../assets/img/pics/deltalight/deltalight_2.jpg',
                '../assets/img/pics/deltalight/deltalight_3.jpg',
                '../assets/img/pics/deltalight/deltalight_4.jpg',
                '../assets/img/pics/deltalight/deltalight_5.jpg',
                '../assets/img/pics/deltalight/deltalight_6.jpg',
                '../assets/img/pics/deltalight/deltalight_7.jpg',
                '../assets/img/pics/deltalight/deltalight_8.jpg',
                '../assets/img/pics/deltalight/deltalight_9.jpg',
                '../assets/img/pics/deltalight/deltalight_10.jpg',
            ],
            title: 'Бренд Delta Light – изящная бельгийская современность',
            about: 'Современный рынок светотехники нельзя представить без продукции Дельта Лайт. Изделия в стиле хай-тек активно используются в домашних и офисных интерьерах, а также в ландшафтном дизайне. Они создают уникальную атмосферу, удачно сочетающую легкость и максимальную практичность.',
            website: 'https://www.deltalight.com'
        }
    }
]