export default [
    { 
        id: 51,
        url: 'sicis',
        name: 'SICIS', 
        parent: 'tiles',
        content: {
            pics: [
                '../assets/img/pics/sicis/sicis_1.jpg',
                '../assets/img/pics/sicis/sicis_2.jpg',
                '../assets/img/pics/sicis/sicis_3.jpg',
                '../assets/img/pics/sicis/sicis_4.jpg',
                '../assets/img/pics/sicis/sicis_5.jpg',
                '../assets/img/pics/sicis/sicis_6.jpg',
                '../assets/img/pics/sicis/sicis_7.jpg',
            ],
            title: 'Коллекции бренда – неповторимый творческий проект',
            about: 'Они столь же прекрасны, сколь разнообразны: природа, абстракция, сюжетные мозаики. Яркие, монохромные, пастельные и контрастные – цветовое разнообразие почти безгранично, как и форма, размеры. Но каждый кусочек всегда наполнен совершенством, цветом и светом.',
            website: 'http://www.sicis.com'
        }
    },
    { 
        id: 52,
        url: 'mutaforma',
        name: 'Mutaforma', 
        parent: 'tiles',
        content: {
            pics: [
                '../assets/img/pics/mutaforma/mutaforma_1.jpg',
                '../assets/img/pics/mutaforma/mutaforma_2.jpg',
                '../assets/img/pics/mutaforma/mutaforma_3.jpg',
                '../assets/img/pics/mutaforma/mutaforma_4.jpg',
                '../assets/img/pics/mutaforma/mutaforma_5.jpg',
                '../assets/img/pics/mutaforma/mutaforma_6.jpg',
                '../assets/img/pics/mutaforma/mutaforma_7.jpg',
                '../assets/img/pics/mutaforma/mutaforma_8.jpg',
                '../assets/img/pics/mutaforma/mutaforma_9.jpg',
                '../assets/img/pics/mutaforma/mutaforma_10.jpg',
                '../assets/img/pics/mutaforma/mutaforma_11.jpg',
            ],
            title: 'MUTAFORMA – древняя магия стекла в современном прочтении',
            about: 'Несмотря на использование инноваций мозаики компании MUTAFORMA созданы человеческой рукой. Это не машинный продукт, а произведение искусства, обладающее душой, но выполненное с применением инноваций и достижений.',
            website: 'https://www.mutaforma.com'
        }
    },
    { 
        id: 53,
        url: 'antolini',
        name: 'Antolini', 
        parent: 'tiles',
        content: {
            pics: [
                '../assets/img/pics/antolini/antolini_1.jpg',
                '../assets/img/pics/antolini/antolini_2.jpg',
                '../assets/img/pics/antolini/antolini_3.jpg',
                '../assets/img/pics/antolini/antolini_4.jpg',
                '../assets/img/pics/antolini/antolini_5.jpg',
                '../assets/img/pics/antolini/antolini_6.jpg',
                '../assets/img/pics/antolini/antolini_7.jpg',
                '../assets/img/pics/antolini/antolini_8.jpg',
            ],
            title: 'Antolini – создано природой, доведено до совершенства в Италии',
            about: 'Бренд Antolini работает с природным камнем, создавая неповторимые отделочные материалы для помещений. Сочетание природного совершенства с новыми технологиями позволяет добиться потрясающего эффекта, как в цветовом, стилистическом, так и практическом плане.',
            website: 'http://www.antolini.com'
        }
    },
    { 
        id: 54,
        url: 'mathiosstone',
        name: 'Mathios Stone', 
        parent: 'tiles',
        content: {
            pics: [
                '../assets/img/pics/mathiosstone/mathiosstone_1.jpg',
            ],
            title: 'Mathios Stone – только природа может нас повторить',
            about: 'Камень, кирпич и всевозможные сочетания этих материалов для лучших архитектурных проектов сделают интерьер и экстерьер незабываемым. Элегантность и роскошь с природным очарованием смотрится великолепно.',
            website: 'http://mathiostone.mathios.com'
        }
    },
    { 
        id: 55,
        url: 'petraantiqua',
        name: 'Petra Antiqua', 
        parent: 'tiles',
        content: {
            pics: [
                '../assets/img/pics/petraantiqua/petraantiqua_1.jpg',
                '../assets/img/pics/petraantiqua/petraantiqua_2.jpg',
                '../assets/img/pics/petraantiqua/petraantiqua_3.jpg',
                '../assets/img/pics/petraantiqua/petraantiqua_4.jpg',
                '../assets/img/pics/petraantiqua/petraantiqua_5.jpg',
            ],
            title: 'Petra Antiqua – совершенство в каждой детали',
            about: 'Бренд Petra Antiqua появился на рынке в 1990 году, это семейное предприятие, пронизанное духом итальянского совершенства и исторической красоты. В коллекциях компании заключено то же очарование, что и в отделке Колизея, Пантеона. Такое стремление к исторической красоте быстро сделало продукцию компании популярной.',
            website: 'http://www.petraantiqua.com'
        }
    },
    { 
        id: 56,
        url: 'imolaceramica',
        name: 'IMOLA Ceramica', 
        parent: 'tiles',
        content: {
            pics: [
                '../assets/img/pics/imolaceramica/imolaceramica_1.jpg',
                '../assets/img/pics/imolaceramica/imolaceramica_2.jpg',
                '../assets/img/pics/imolaceramica/imolaceramica_3.jpg',
                '../assets/img/pics/imolaceramica/imolaceramica_4.jpg',
                '../assets/img/pics/imolaceramica/imolaceramica_5.jpg',
                '../assets/img/pics/imolaceramica/imolaceramica_6.jpg',
                '../assets/img/pics/imolaceramica/imolaceramica_7.jpg',
                '../assets/img/pics/imolaceramica/imolaceramica_8.jpg',
            ],
            title: 'IMOLA Ceramica – на страже традиций',
            about: 'Плитка бренда не подвержена изменчивым веяниям моды. Такая отделка остается актуальной через 5 и 10 лет, сохраняя свой особенный шарм. Благодаря разнообразию оттенков, можно подобрать плитку практически под каждый интерьер с разной текстурой, матовую или глянцевую',
            website: 'http://www.imolaceramica.com'
        }
    },
    { 
        id: 57,
        url: 'irisfmg',
        name: 'Iris FMG', 
        parent: 'tiles',
        content: {
            pics: [
                '../assets/img/pics/irisfmg/irisfmg_1.jpg',
                '../assets/img/pics/irisfmg/irisfmg_2.jpg',
                '../assets/img/pics/irisfmg/irisfmg_3.jpg',
                '../assets/img/pics/irisfmg/irisfmg_4.jpg',
                '../assets/img/pics/irisfmg/irisfmg_5.jpg',
                '../assets/img/pics/irisfmg/irisfmg_6.jpg',
                '../assets/img/pics/irisfmg/irisfmg_7.jpg',
                '../assets/img/pics/irisfmg/irisfmg_8.jpg',
            ],
            title: 'Iris FMG: мы воссоздаем природу',
            about: 'Вдохновение специалисты черпают из природной красоты камня, переосмысливая его внешний вид и «разбавляя» графическими эффектами. Благодаря современным технологиям, полученный в результате переосмысления камня материал превосходит его по качественным характеристикам!',
            website: 'http://www.irisfmg.com'
        }
    },
    { 
        id: 58,
        url: 'atlasconcorde',
        name: 'Atlas Concorde', 
        parent: 'tiles',
        content: {
            pics: [
                '../assets/img/pics/atlasconcorde/atlasconcorde_1.jpg',
                '../assets/img/pics/atlasconcorde/atlasconcorde_2.jpg',
                '../assets/img/pics/atlasconcorde/atlasconcorde_3.jpg',
                '../assets/img/pics/atlasconcorde/atlasconcorde_4.jpg',
                '../assets/img/pics/atlasconcorde/atlasconcorde_5.jpg',
                '../assets/img/pics/atlasconcorde/atlasconcorde_6.jpg',
                '../assets/img/pics/atlasconcorde/atlasconcorde_7.jpg',
                '../assets/img/pics/atlasconcorde/atlasconcorde_8.jpg',
                '../assets/img/pics/atlasconcorde/atlasconcorde_9.jpg',
                '../assets/img/pics/atlasconcorde/atlasconcorde_10.jpg',
            ],
            title: 'Atlas Concorde – совершенная керамическая плитка',
            about: 'Компания была создана в 1969 году с амбициозной целью – стать одним из лидеров профильного рынка и эта задача была досингута! Из профильного бренда с небольшим объемом производства за 50 лет компания превратилась в родоначальника ведущей группы компании Италии в сфере керамических покрытий – Gruppo Concorde.',
            website: 'https://www.atlasconcorde.com'
        }
    },
    { 
        id: 61,
        url: 'petracers',
        name: 'Petracers', 
        parent: 'tiles',
        content: {
            pics: [
                '../assets/img/pics/petracers/petracers_1.jpg',
                '../assets/img/pics/petracers/petracers_2.jpg',
                '../assets/img/pics/petracers/petracers_3.jpg',
                '../assets/img/pics/petracers/petracers_4.jpg',
                '../assets/img/pics/petracers/petracers_5.jpg',
                '../assets/img/pics/petracers/petracers_6.jpg',
                '../assets/img/pics/petracers/petracers_7.jpg',
            ],
            title: 'Керамика, которая задает тон, но оставляет место для импровизации!',
            about: 'Каждая из предлагаемых брендом коллекций – это модели керамической плитки, которые находятся за гранью привычного и обыденного. Эта продукция вне времени и вне конкуренции трендов. Она - дань истинной итальянской страсти и ностальгии по прекрасным временам роскоши и королей.',
            website: 'https://petracer.it'
        }
    }
]