<template>
  <div>
    <svg-morphing></svg-morphing>
  </div>
</template>
<script>
import SvgMorphing from '@/components/SvgMorphing'
export default {
  components: {
    SvgMorphing
  }
}
</script>
<style lang="scss">
</style>
