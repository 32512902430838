<template>
  <div class="navbar">
    <nav class="container" role="navigation">
      <div class="navbar_content">
        <div class="header_nav">

          <locale-changer></locale-changer>
          <social-icons></social-icons>

          <div class="button_burger" @click="toggleCurtain">
            <span class="burger-line" :class="{ active: toggleMenu }"></span>
          </div>
          
        </div>
        <div class="navbar_wrapper" :class="{ active: toggleMenu }">
          <div class="navbar-list">
            <div 
              class="navbar-item"
              @click='toggleCurtain'
              v-for="(link, index) in links"
              :key="index"
              v-show="link.name != 'PageNotFound'">
                <router-link :to="link.to" class="navbar-link">{{ link.name }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import LocaleChanger from '@/components/LocaleChanger'
import SocialIcons from '@/components/SocialIcons'
export default {
  props: {
    preloaderComplete: Boolean
  },
  components: {
    SocialIcons,
    LocaleChanger
  },
  
  data: () => ({
    langs: ['ru', 'en'],
    links: [
      { name: 'Home', to: '/' },
      { name: 'About', to: '/about' },
      { name: 'Portfolio', to: '/portfolio' },
      { name: 'Categories', to: '/categories' },
      { name: 'Partners', to: '/partners' },
      { name: 'Contact', to: '/contact' },
      { name: 'PageNotFound', to: '/page-not-found' }
    ],
    toggleMenu: false
  }),
  methods: {
    toggleCurtain() {
      this.toggleMenu == false ? this.toggleMenu = true : this.toggleMenu = false;
    }
  }
}
</script>

<style lang="scss">
</style>