import Brand from '@/models/Brand'
import Category from '@/models/Category'

// api
import bathrooms from './brands/bathrooms'
import spawellness from './brands/spawellness'
import tiles from './brands/tiles'
import furniture from './brands/furniture'
import kitchen from './brands/kitchen'
import lighting from './brands/lighting'
import floorcoverings from './brands/floorcoverings'
import windowsdoors from './brands/windowsdoors'
import decor from './brands/decor'
import heatingcooling from './brands/heatingcooling'
import buildingmaterials from './brands/buildingmaterials'
import fireplaces from './brands/fireplaces'

export default {
  getArticles() {
    const items = [
      ...furniture,
      ...lighting,
      ...kitchen,
      ...decor,
      ...fireplaces,
      ...floorcoverings,
      ...bathrooms,
      ...tiles,
      ...spawellness,
      ...heatingcooling,
      ...buildingmaterials,
      ...windowsdoors
    ];
    return items.map((item) => {
      const brand = Brand.createFrom(item);
      return brand;
    });
  },
  getCategories() {
    const items = [
      {
        id: 1, 
        url: 'bathrooms',
        title: 'Bathrooms', 
        description: 'Hermitage represents and supplies some of the best Italian sanitary ware brands.',
        articles: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]
      },
      {
        id: 2, 
        url: 'spa-and-wellness',
        title: 'SPA & Wellness', 
        description: 'Hermitage represents and supplies some of the best Italian spa and wellness brands.',
        articles: [26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50]
      },
      {
        id: 3, 
        url: 'tiles',
        title: 'Tiles', 
        description: 'Hermitage represents and supplies some of the best Italian tile brands.',
        articles: [51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75]
      },
      {
        id: 4, 
        url: 'furniture',
        title: 'Furniture', 
        description: 'Hermitage represents and supplies some of the best Italian furnishing brands.',
        articles: [76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100]
      },
      {
        id: 5, 
        url: 'kitchen',
        title: 'Kitchen', 
        description: 'Hermitage represents and supplies some of the best Italian kitchen brands.',
        articles: [101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125]
      },
      {
        id: 6, 
        url: 'lighting',
        title: 'Lighting', 
        description: 'Hermitage represents and supplies some of the best Italian lighting brands.',
        articles: [126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150]
      },
      {
        id: 7, 
        url: 'floor-coverings',
        title: 'Floor Coverings', 
        description: 'Hermitage represents and supplies some of the best Italian flooring brands.',
        articles: [151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175]
      },
      {
        id: 8, 
        url: 'windows-doors',
        title: 'Windows / Doors', 
        description: 'Hermitage represents and supplies some of the best Italian brands of windows and doors.',
        articles: [176,177,178,179,180,181,182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200]
      },
      {
        id: 9, 
        url: 'decor-and-accessories',
        title: 'Decor and Accessories', 
        description: 'Hermitage represents and supplies some of the finest Italian decor and accessories brands.',
        articles: [201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225]
      },
      {
        id: 10, 
        url: 'heating-cooling-and-ventilation',
        title: 'Heating, Cooling and Ventilation', 
        description: 'Hermitage represents and supplies some of the best Italian heating, cooling and ventilation brands.',
        articles: [226,227,228,229,230,231,232,233,234,235,236,237,238,239,240,241,242,243,244,245,246,247,248,249,250]
      },
      {
        id: 11, 
        url: 'building-materials',
        title: 'Building Materials', 
        description: 'Hermitage represents and supplies some of the best Italian brands of building materials.',
        articles: [251,252,253,254,255,256,257,258,259,260,261,262,263,264,265,266,267,268,269,270,271,272,273,274,275]
      },
      {
        id: 12, 
        url: 'fireplaces',
        title: 'Fireplaces', 
        description: 'Hermitage represents and supplies some of the finest Italian fireplace brands.',
        articles: [276,277,278,279,280,281,282,283,284,285,286,287,288,289,290,291,292,293,294,295,296,297,298,299,300]
      }
    ];
    return items.map((item) => Category.createFrom(item))
  }
}