<template>
  <progressive-background class="category_bg" :src="bgCat" :placeholder="bgCat | jpg2svg" :blur="3">
    <div slot="content">
      <ListItems 
        class="category_list"
        v-slot="props"
        :items="categories">
        <div class="category_item transition_bg" @mouseover="mouseOver(props.item.id)">
          <router-link :to="getCategoryRoute(props.item)" class="ui-text-medium category_link">
            {{ props.item.title }}
            <span class="cat_border_box">
              <span class="cat_border cat_border_top"></span>
              <span class="cat_border cat_border_right"></span>
              <span class="cat_border cat_border_bottom"></span>
              <span class="cat_border cat_border_left"></span>
            </span>
          </router-link>
        </div>
      </ListItems>
    </div>
  </progressive-background>
</template>
<script>
import ListItems from '@/components/ListItems.vue'
import ArticleItems from '@/components/ArticleItems.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Brands',
  components: {
    ListItems,
    ArticleItems,
  },
  filters: {
    jpg2svg(ext) {
      return ext = ext.replace(/(png|jpg)$/, 'svg');
    }
  },
  data() {
    return {
      bgCat: '../assets/img/home/cat_background.jpg',
      activeCat: [
        '../assets/img/home/cat_background.jpg',
        '../assets/img/home/cat_bathrooms.jpg',
        '../assets/img/home/cat_spawellness.jpg',
        '../assets/img/home/cat_tiles.jpg',
        '../assets/img/home/cat_furniture.jpg',
        '../assets/img/home/cat_kitchen.jpg',
        '../assets/img/home/cat_lighting.jpg',
        '../assets/img/home/cat_floorcoverings.jpg',
        '../assets/img/home/cat_windowsdoors.jpg',
        '../assets/img/home/cat_decor.jpg',
        '../assets/img/home/cat_heatingcooling.jpg',
        '../assets/img/home/cat_building.jpg',
        '../assets/img/home/cat_fireplaces.jpg'
      ]
    };
  },
  methods: {
    getCategoryRoute(item) {
      return {
        name: 'Brands',
        params: {
          category_url: item.url
        }
      };
    },
    mouseOver(id) {
      this.bgCat = this.activeCat[id];
    },
    mouseLeave(id) {
      this.bgCat = this.activeCat[0];
    }
  },
  computed: {
    ...mapGetters([
      'articles',
      'categories'
    ])
  },
  created() {
    this.$store.dispatch('loadArticles');
    this.$store.dispatch('loadCategories');
  }
  // mounted() {
  //   this.$emit('hide-hg-logo');
  // }
}
</script>
<style lang="scss" scoped>
.category_bg {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: 1s;
  z-index: 0;
}
.category_list {
  position: relative;
  background: rgba(0,0,0,.5);
  padding: 10% 5% 10% 5%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 0;
}
.category_item {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: 1s;
}
.category_link {
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 2px;
  color: #fff;
  transition: .5s;
  &:hover {
    text-shadow: 0 0 5px #000;
    transition-duration: 1.5s;
    transition-timing-function: ease;
    .cat_border { transition-duration: .25s; transition-timing-function: ease; }
    .cat_border_top { width: 100%; transition-delay: 0s; }
    .cat_border_right { height: 100%; transition-delay: .1s; }
    .cat_border_bottom { width: 100%; transition-delay: .2s; }
    .cat_border_left { height: 100%; transition-delay: .3s; }
    // .cat_border { transition-duration: 1s; transition-timing-function: ease; }
    // .cat_border_top { width: calc(100% - 10px); }
    // .cat_border_right { height: calc(100% - 10px); }
    // .cat_border_bottom { width: calc(100% - 10px); }
    // .cat_border_left { height: calc(100% - 10px); }
  }
}
.cat_border { position: absolute; background: rgba(255,255,255,.5); }

.cat_border_top, .cat_border_bottom { height: 1px; width: 0; }
.cat_border_left, .cat_border_right { width: 1px; height: 0; }
.cat_border_top { top: 0; left: 0; }
.cat_border_right { top: 0; right: 0; }
.cat_border_bottom { bottom: 0; right: 0; }
.cat_border_left { bottom: 0; left: 0; }
</style>