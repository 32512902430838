<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 300 45" xml:space="preserve">
	<line class="hermitage_logo he_1" x1="10.6" y1="2.6" x2="10.6" y2="25.1"/>
	<line class="hermitage_logo he_2" x1="24.9" y1="2.6" x2="24.9" y2="25.1"/>
	<polyline class="hermitage_logo he_3" points="60.6,2.6 45.6,2.6 45.6,25.1 60.6,25.1 "/>
	<path class="hermitage_logo he_4" d="M80.5,25.1V2.6h8.7c9,0,12.7,16.7-1.6,15.5l10.9,7"/>
	<polyline class="hermitage_logo he_5" points="116.3,25.1 116.3,2.6 125,13.9 133.7,2.6 133.7,25.1 "/>
	<line class="hermitage_logo he_6" x1="154.9" y1="2.6" x2="154.9" y2="25.1"/>
	<line class="hermitage_logo he_7" x1="181.8" y1="25.1" x2="181.8" y2="2.6"/>
	<line class="hermitage_logo he_8" x1="174" y1="2.6" x2="189.7" y2="2.6"/>
	<polyline class="hermitage_logo he_9" points="203.6,25.1 215.5,2.6 227.4,25.1 "/>
	<path class="hermitage_logo he_10" d="M260.7,5.3c-6-5.7-18.2-1.8-18.2,8.5c0,11.3,15.4,16.6,21.6,4.3"/>
	<polyline class="hermitage_logo he_11" points="297.8,2.6 282.8,2.6 282.8,25.1 297.8,25.1 "/>
	<line class="hermitage_logo he_12" x1="2.5" y1="18.1" x2="57.5" y2="18.1"/>
	<line class="hermitage_logo he_13" x1="65.3" y1="18.1" x2="293.4" y2="18.1"/>
	<polygon class="home_int hi_1" points="9.9,43.3 9.9,38.6 11.2,38.6 11.2,40.5 13.4,40.5 13.4,38.6 14.7,38.6 14.7,43.3 13.4,43.3 13.4,41.4 11.2,41.4 
		11.2,43.3 "/>
	<path class="home_int hi_2" d="M36.3,38.5c-1.9,0-3.3,1.1-3.3,2.4s1.5,2.4,3.3,2.4s3.3-1.1,3.3-2.4S38.1,38.5,36.3,38.5z M36.3,42.4c-1.1,0-2-0.7-2-1.5
		s0.9-1.5,2-1.5s2,0.7,2,1.5S37.4,42.4,36.3,42.4z"/>
	<polygon class="home_int hi_3" points="57.7,43.3 57.7,38.6 59.5,38.6 61.2,42 62.9,38.6 64.6,38.6 64.6,43.3 63.3,43.3 63.3,39.9 61.7,43.3 60.7,43.3 
		59,39.9 59,43.3 "/>
	<polygon class="home_int hi_4" points="86.6,43.3 82.9,43.3 82.9,38.6 86.6,38.6 86.6,39.4 84.2,39.4 84.2,40.5 86.6,40.5 86.6,41.3 84.2,41.3 84.2,42.5 
		86.6,42.5 "/>
	<rect class="home_int hi_5" x="118.6" y="38.6" width="1.3" height="4.7"/>
	<polygon class="home_int hi_6" points="138.3,43.3 138.3,38.6 139.5,38.6 142.3,41.6 142.3,38.6 143.6,38.6 143.6,43.3 142.4,43.3 139.6,40.2 139.6,43.3 
		"/>
	<polygon class="home_int hi_7" points="161.5,38.6 165.3,38.6 165.3,39.4 164,39.4 164,43.3 162.7,43.3 162.7,39.4 161.5,39.4 "/>
	<polygon class="home_int hi_8" points="183.2,38.6 186.8,38.6 186.8,39.4 184.5,39.4 184.5,40.5 186.8,40.5 186.8,41.3 184.5,41.3 184.5,42.5 186.8,42.5 
		186.8,43.3 183.2,43.3 "/>
	<path class="home_int hi_9" d="M208,41.5c2.2,0,2.2-2.9-0.2-2.9h-2.7v4.7h1.3v-1.8l1.7,1.8h1.6L208,41.5z M206.4,40.8v-1.5c0.9,0,1.9-0.1,1.9,0.8
		C208.3,41,207.3,40.8,206.4,40.8z"/>
	<rect class="home_int hi_10" x="227.7" y="38.6" width="1.3" height="4.7"/>
	<path class="home_int hi_11" d="M250.6,38.5c-1.9,0-3.3,1.1-3.3,2.4s1.5,2.4,3.3,2.4s3.3-1.1,3.3-2.4S252.5,38.5,250.6,38.5z M250.6,42.4
		c-1.1,0-2-0.7-2-1.5s0.9-1.5,2-1.5s2,0.7,2,1.5S251.8,42.4,250.6,42.4z"/>
	<path class="home_int hi_12" d="M274.9,41.5c2.2,0,2.2-2.9-0.2-2.9H272v4.7h1.3v-1.8l1.7,1.8h1.6L274.9,41.5z M273.3,40.8v-1.5c0.9,0,1.9-0.1,1.9,0.8
		C275.2,41,274.2,40.8,273.3,40.8z"/>
	<path class="home_int hi_13" d="M294.5,41.9h1.2c0,1,1.6,0.7,1.6,0.2c0-1-2.7-0.3-2.7-2.1c0-0.8,0.6-1.3,1.9-1.3c1.1,0,1.8,0.6,1.8,1.3h-1.2
		c0-0.7-1.4-0.7-1.4,0c0,0.8,2.9,0.6,2.9,1.9c0,1.3-1,1.5-2,1.5C295.2,43.3,294.5,42.7,294.5,41.9z"/>
</svg>
</template>
<script>
export default {
    name: 'HermitageLogo'
}
</script>
<style>
.hermitage_logo {
	fill: none;
	stroke: #fff;
	stroke-width: 1.4;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
}
.home_int {
	fill: #fff;
}
</style>