<template>
  <div class="wrapper">
    <preloader-draw-logo :v-show="isHomePage" @preloder-complete="preloaderComplete"></preloader-draw-logo>
    <header>
      <router-link to="/">
        <hg-logo class="header_logo" v-show="hgLogoActive" alt="HERMITAGE HOME INTERIORS"></hg-logo>
      </router-link>

      <section-header/>
    </header>
    <main>
      <transition name="fade">
        <router-view :preloader-complete="preloaderDone"></router-view>
      </transition>
    </main>
    <footer>
      <section-footer/>
    </footer>
  </div>
</template>

<script>
import PreloaderDrawLogo from '@/components/PreloaderDrawLogo'
import HgLogo from '@/components/logo/HgLogo'
import Header from './Header.vue'
import Footer from './Footer.vue'
export default {
  data() {
    return {
      isHomePage: false,
      preloaderDone: false,
      hgLogoActive: true,
      languages: [
        { flag: 'us', lang: 'en', title: 'English' },
        { flag: 'ru', lang: 'ru', title: 'Русский' }
      ]
    }
  },
  components: {
    HgLogo,
    'section-header': Header,
    'section-footer': Footer,
    PreloaderDrawLogo
  },
  computed: {
    is404() {
      return this.$route.name === '404';
    }
  },
  created() {
    window.addEventListener('beforeunload', function(){
      return this.$route.path === '/' ? this.isHomePage = true : this.isHomePage = false
    })
  },
  methods: {
    preloaderComplete() {
      this.preloaderDone = true;
    }
  }
}
</script>

<style lang="scss">
.header_logo {
  width: 100px;
  transition: 1s;
}
</style>