<i18n>
{
  "en": {
    "banner": {
      "slide_0": { 
        "title": "Welcome to",
        "desc": "Hermitage Home Interiors"
      },
      "slide_1": { 
        "title": "We are",
        "desc": "Home Interiors"
      },
      "slide_2": { 
        "title": "We create",
        "desc": "Unique Projects"
      },
      "slide_3": { 
        "title": "We work with",
        "desc": "Italian Architects"
      },
      "slide_4": { 
        "title": "Develop",
        "desc": "Fantastic Design"
      }
    },
    "activities": {
      "activity_0": {
        "title": "Projects",
        "desc": "We are the ones who create luxury in every element and every detail. Touching the thin strings of the soul, reaching out to the beautiful. Having won the approval of the most discerning connoisseurs of luxury life."
      },
      "activity_1": {
        "title": "Contracts",
        "desc": "We are the ones who have two large show rooms that will gladly open the doors for you to demonstrate all our capabilities and perspectives in creating your interior as unique as you are!"
      },
      "activity_2": {
        "title": "Retails",
        "desc": "We are the ones who took as their main concept - offering only those products that meet the highest quality and design requirements, embodying the skill of the best manufacturers and the elegance of lines."
      }
    }
  },
  "ru": {
    "banner": {
      "slide_0": { 
        "title": "Добро пожаловать в",
        "desc": "Hermitage Home Interiors"
      },
      "slide_1": { 
        "title": "Мы это",
        "desc": "Интерьеры для дома"
      },
      "slide_2": { 
        "title": "Мы создаем",
        "desc": "Уникальные проекты"
      },
      "slide_3": { 
        "title": "Мы работаем",
        "desc": "С итальянскими архитекторами"
      },
      "slide_4": { 
        "title": "Разрабатываем",
        "desc": "Фантастический дизайн"
      }
    },
    "activities": {
      "activity_0": {
        "title": "Проекты",
        "desc": "Мы те, кто создает роскошь в каждом элементе и каждой детали. Задевая тонкие струны души, тянущейся к прекрасному. Снискав одобрение самых взыскательных ценителей жизни в стиле лакшери."
      },
      "activity_1": {
        "title": "Контракты",
        "desc": "Мы те, у кого есть два больших шоу-рума, которые с радостью распахнут перед вами двери, чтобы продемонстрировать все наши возможности и перспективы в создании вашего настолько же уникального интерьера, как и вы сами!"
      },
      "activity_2": {
        "title": "Розница",
        "desc": "Мы те, кто взял за основную свою концепцию - предложение только той продукции, которая соответствует самым высоким требованиям качества и дизайна, воплощая в себе мастерство лучших производителей и изящность линий."
      }
    }
  }
}
</i18n>
<template>
  <div class="home_page">
    <div class="home_slider">
      <vue-slick v-bind="settingsMainSlide" ref="mainSlide" v-show="preloaderComplete">
        <template v-for="(slide, i) in mainSlide">
          <div class="slide_item" :key="i" :id="'slide_' + i">
            <progressive-background class="slide_image" :key="i" :src="slide.img" :placeholder="slide.img | toSvg" :blur="3">
              <div slot="content">
                <div class="container_slide" :class="'slide_title_' + i">
                  <div class="text_slide">
                    <h1 class="ui-title-3 slide_title">{{ $t('banner.slide_'+[i]+'.title') }}</h1>
                    <h2 class="ui-title-3 slide_desc">{{ $t('banner.slide_'+[i]+'.desc') }}</h2>
                  </div>
                </div>
              </div>
            </progressive-background>
          </div>
        </template>
      </vue-slick>
    </div>

    <div class="home_activity">
      <template v-for="(activity, i) in activities">
        <div class="activity_section" :key="i" :class="'activity_section_'+i">
          <div class="activity_image elem_animate" 
            @mouseover="activityOver(i)"
            @mouseleave="activityLeave(i)">
            <progressive-background class="activity_pic" 
              :id="'activity_pic_' + i"
              :src="activity.img" 
              :placeholder="activity.img | toSvg" 
              :aspect-ratio="0.66"
              :blur="0">
              <!-- <div slot="content">
                <img :src="activity.img" :id="'activity_svg_' + i" class="activity_svg">
              </div> -->
            </progressive-background>
          </div>
          <div class="activity_desc">
            <hg-stroke class="elem_animate">
              <template v-slot:title>
                <h3 class="ui-title-1">{{ $t('activities.activity_'+[i]+'.title') }}</h3>
              </template>
              <template v-slot:desc>
                <p class="ui-text-regular">{{ $t('activities.activity_'+[i]+'.desc') }}</p>
              </template>
            </hg-stroke>
          </div>
        </div>
      </template>
    </div>
    <div class="home_categories">
      <main-categories></main-categories>
    </div>

  </div>
</template>

<script>
import VueSlick from 'vue-slick-carousel'
import FlowmapImage from '@/components/FlowmapImage'
import HgStroke from '@/components/icons/HgStroke'
import MainCategories from '@/pages/MainCategories'
export default {
  props: {
    preloaderComplete: Boolean
  },
  components: { VueSlick, FlowmapImage, HgStroke, MainCategories },
  data() {
    return {
      isScrolling: false,
      hgLogoActive: true,
      settingsMainSlide: {
        arrows: false,
        dots: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 0,
        pauseOnHover: false,
        cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)'
      },
      mainSlide: [
        { img: '../assets/img/home/slide_showroom_1.jpg' },
        { img: '../assets/img/home/slide_showroom_2.jpg' },
        { img: '../assets/img/home/slide_showroom_3.jpg' },
        { img: '../assets/img/home/slide_showroom_4.jpg' },
        { img: '../assets/img/home/slide_showroom_5.jpg' }
      ],
      activities: [
        { img: '../assets/img/home/hermitage_projects.jpg' },
        { img: '../assets/img/home/hermitage_contracts.jpg' },
        { img: '../assets/img/home/hermitage_retails.jpg' }
      ],
    }
  },
  mounted() {
    window.addEventListener("scroll", this.throttleScroll, false);
    document.addEventListener("DOMContentLoaded", this.scrolling, false);
  },
  methods: {
    activityOver(id) {
      let bgPic = document.querySelector(`#activity_pic_${id} .progressive-background-image`);
      bgPic.classList.add("activity_bg_image");
    },
    activityLeave(id) {
      let bgPic = document.querySelector(`#activity_pic_${id} .progressive-background-image`);
      bgPic.classList.remove("activity_bg_image");
    },
    throttleScroll(e) {
      if (this.isScrolling == false) {
        window.requestAnimationFrame(() => { this.scrolling(e); this.isScrolling = false; });
      }
      this.isScrolling = true;
    },
    scrolling(e) {
      var listItems = document.querySelectorAll(".elem_animate");
      var isSliderVisible = document.querySelector(".home_slider");
      var svgItems = document.querySelectorAll(".svg_items");
      for (var i = 0; i < listItems.length; i++) {
        var listItem = listItems[i];
        if (this.isPartiallyVisible(listItem)) {
          listItem.classList.add("elem_toggle");
        } else {
          listItem.classList.remove("elem_toggle");
        }
      }
      for (var i = 0; i < svgItems.length; i++) {
        var svgItem = svgItems[i];
        if (this.isPartiallyVisible(isSliderVisible)) {
          svgItem.classList.remove("svg_animate");
        } else {
          svgItem.classList.add("svg_animate");
        }
      }
    },
    isPartiallyVisible(el) {
      var elementBoundary = el.getBoundingClientRect();
      var top = elementBoundary.top;
      var bottom = elementBoundary.bottom;
      var height = elementBoundary.height;
      return ((top + height >= 0) && (height + window.innerHeight >= bottom));
    },
    isFullyVisible(el) {
      var elementBoundary = el.getBoundingClientRect();
      var top = elementBoundary.top;
      var bottom = elementBoundary.bottom;
      return ((top >= 0) && (bottom <= window.innerHeight));
    }
  }
}
</script>

<style scoped>
.ui-title-3 {
  letter-spacing: 10px;
}
.slide_item {
  height: 100vh;
}
.slide_image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.slick-slide .slide_image {
  animation-name: backImage;
  animation-duration: 2s;
  animation-iteration-count: 1;
}
@keyframes backImage {
  0% { transform: scale(1); }
  50% { transform: scale(1); }
  100% { transform: scale(1.1); }
}
.slick-slide.slick-current .slide_image {
  animation-name: zoomImage;
  animation-duration: 10s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
@keyframes zoomImage {
  0% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.container_slide {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 20px 5%;
  align-items: center;
  height: 100vh;
  z-index: 1;
}
.text_slide {
  display: block;
  max-width: 100%;
}
.slide_title, .slide_desc {
  display: block;
  width: 100%;
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
}
.slide_title {
  font-size: 0.8rem;
  line-height: 1rem;
}
.slide_desc {
  font-weight: 400;
  font-size: 1.8rem;
  text-shadow: 0 0 0 #fff;
}
.slick-current .container_slide {
  animation: 10s bgAnimate;
  background: rgba(0,0,0,.25);
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
@keyframes bgAnimate {
  0% { background: rgba(0,0,0,.5); }
  70% { background: rgba(0,0,0,.25); }
  100% { background: rgba(0,0,0,.5); }
}
.slick-current .slide_title, .slick-current .slide_desc {
  height: 0;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-direction: alternate;
}
.slick-current .slide_title {
  max-height: 100%;
  animation: 10s titleAnimate;
  animation-delay: 0s;
}
.slick-current .slide_desc {
  max-height: 100%;
  padding-left: 20px;
  animation: 10s descAnimate, 10s titleAnimate;
  animation-delay: 0s;
}
@keyframes titleAnimate {
  0% { height: 0; }
  5% { height: 0; }
  15% { height: 100%; }
  85% { height: 100%; }
  95% { height: 0; }
  100% { height: 0; }
}
@keyframes descAnimate {
  from { transform: translateX(0); text-shadow: 0 0 0 #fff; }
  35% { text-shadow: 0 0 5px #fff, 0 0 10px #fff; }
  50% {
    transform: translateX(30px);
    text-shadow: 0 0 10px #fff, 0 0 15px #fff;
  }
  65% { text-shadow: 0 0 5px #fff, 0 0 10px #fff; }
  to { transform: translateX(0); text-shadow: 0 0 0 #fff; }
}

/* activity */
.home_activity {
  position: relative;
  display: block;
}
.activity_section {
  width: 100%;
  padding: 2% 10%;
  margin: 40px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.activity_desc { width: 40%; margin-left: 5%; }
.activity_section:nth-child(even) .activity_image { order: 2; }
.activity_section:nth-child(even) .activity_desc { order: 1; margin-right: 5%; }

.hg_desc .ui-text-regular {
  transform: translateX(50px);
  opacity: 0;
  transition: 3s ease;
}
.elem_toggle .hg_desc .ui-text-regular {
  transform: translateX(0);
  opacity: 1;
  transition: 3s ease;
}
.activity_image {
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0;
  transition: .5s;
}
.activity_pic {
  height: 100vh;
  z-index: 0;
}

.activity_image.elem_toggle {
  animation-name: imageMoving;
  animation-iteration-count: 1;
  animation-delay: .1s;
  animation-duration: 5s;
  animation-timing-function: ease;
}
.elem_toggle .activity_pic {
  animation-name: imageWidthGrow;
  animation-iteration-count: 1;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.29, 1);
}
@keyframes imageMoving {
  0% { transform: translateY(50px); }
  100% { transform: translateY(0); }
}
@keyframes imageWidthGrow {
  0% { 
    height: 100vh; 
    max-width: 0;
    opacity: 0;
  }
  100% {
    height: 100vh;
    max-width: 100%;
    opacity: 1;
  }
}

/* categories */
.home_categories {
  position: relative;
  display: flex;
  justify-content: center;
}
</style>