export default [
    { 
        id: 26,
        url: 'effegibi',
        name: 'Effegibi', 
        parent: 'spa-and-wellness',
        content: {
            pics: [
                '../assets/img/pics/effegibi/effegibi_1.jpg',
                '../assets/img/pics/effegibi/effegibi_2.jpg',
                '../assets/img/pics/effegibi/effegibi_3.jpg',
                '../assets/img/pics/effegibi/effegibi_4.jpg',
                '../assets/img/pics/effegibi/effegibi_5.jpg',
                '../assets/img/pics/effegibi/effegibi_6.jpg',
                '../assets/img/pics/effegibi/effegibi_7.jpg',
                '../assets/img/pics/effegibi/effegibi_8.jpg',
                '../assets/img/pics/effegibi/effegibi_9.jpg',
            ],
            title: 'Сауна или хаммам прямо у вас дома больше не миф, а ваша реальность!',
            about: 'Поскольку идея оформления в загородном коттедже или даже городской квартире собственной велнес-зоны набирает все большую популярность, продукция бренда Effegibi заслуженно пользуется спросом во всем мире. Сегодня ее легко можно встретить в самых роскошных домах и апартаментах.',
            website: 'https://www.effegibi.it'
        }
    },
    { 
        id: 27,
        url: 'treesse',
        name: 'Treesse', 
        parent: 'spa-and-wellness',
        content: {
            pics: [
                '../assets/img/pics/treesse/treesse_1.jpg',
                '../assets/img/pics/treesse/treesse_2.jpg',
                '../assets/img/pics/treesse/treesse_3.jpg',
                '../assets/img/pics/treesse/treesse_4.jpg',
                '../assets/img/pics/treesse/treesse_5.jpg',
                '../assets/img/pics/treesse/treesse_6.jpg',
            ],
            title: 'Инновации по соседству с продуманным дизайном!',
            about: 'Концепция бренда заключается в постоянных экспериментах с формами и материалами, в миксе разных стилей и отсутствии границ возможного, в поиске новаторских решений и уникальных идей. Такой подход позволяет успешно реализовать самые смелые идеи о собственной велнес-зоне в доме.',
            website: 'http://www.gruppotres.it'
        }
    },
    { 
        id: 28,
        url: 'klafs',
        name: 'Klafs', 
        parent: 'spa-and-wellness',
        content: {
            pics: [
                '../assets/img/pics/klafs/klafs_1.jpg',
                '../assets/img/pics/klafs/klafs_2.jpg',
                '../assets/img/pics/klafs/klafs_3.jpg',
                '../assets/img/pics/klafs/klafs_4.jpg',
                '../assets/img/pics/klafs/klafs_5.jpg',
                '../assets/img/pics/klafs/klafs_6.jpg',
                '../assets/img/pics/klafs/klafs_7.jpg',
                '../assets/img/pics/klafs/klafs_8.jpg',
                '../assets/img/pics/klafs/klafs_9.jpg',
                '../assets/img/pics/klafs/klafs_10.jpg',
                '../assets/img/pics/klafs/klafs_11.jpg',
                '../assets/img/pics/klafs/klafs_12.jpg',
            ],
            title: 'Klafs – ничего невозможного не существует!',
            about: 'Бренд под названием Klafs является одним из ключевых лидеров рынка по производству банных комплексов и саун, соляриев и хамамм. Продукция этой компании считается настоящим образцом безупречного качества и непревзойденного стиля.',
            website: 'http://www.klafs.com'
        }
    },
    { 
        id: 29,
        url: 'tylo',
        name: 'TYLO', 
        parent: 'spa-and-wellness',
        content: {
            pics: [
                '../assets/img/pics/tylo/tylo_1.jpg',
                '../assets/img/pics/tylo/tylo_2.jpg',
                '../assets/img/pics/tylo/tylo_3.jpg',
                '../assets/img/pics/tylo/tylo_4.jpg',
                '../assets/img/pics/tylo/tylo_5.jpg',
                '../assets/img/pics/tylo/tylo_6.jpg',
            ],
            title: 'Tylo – мир СПА прямо в вашем доме!',
            about: 'Бренд Tylo предлагает прямо сейчас превратить ваши фантазии о собственной велнес-зоне в доме в реальность, представляя большой выбор уникальных архитектурно-технических комплексов и решений для ванны или СПА-пространства. Современные швейцарские сауны и бани превратят ваши мечты о релаксе – в действительность.',
            website: 'https://www.tylohelo.com'
        }
    },
    { 
        id: 30,
        url: 'jacuzzi',
        name: 'Jacuzzi', 
        parent: 'spa-and-wellness',
        content: {
            pics: [
                '../assets/img/pics/jacuzzi/jacuzzi_1.jpg',
                '../assets/img/pics/jacuzzi/jacuzzi_2.jpg',
                '../assets/img/pics/jacuzzi/jacuzzi_3.jpg',
                '../assets/img/pics/jacuzzi/jacuzzi_4.jpg',
                '../assets/img/pics/jacuzzi/jacuzzi_5.jpg',
                '../assets/img/pics/jacuzzi/jacuzzi_6.jpg',
                '../assets/img/pics/jacuzzi/jacuzzi_7.jpg',
                '../assets/img/pics/jacuzzi/jacuzzi_8.jpg',
                '../assets/img/pics/jacuzzi/jacuzzi_9.jpg',
            ],
            title: 'Jacuzzi – название, которое давно уже стало легендой!',
            about: 'Американский бренд с итальянскими корнями под названием Jacuzzi стал эталоном гидромассажных ванн, а его название превратилось в нарицательное. И это неспроста, ведь компания зарекомендовала себя, как один из самых надежных и ответственных производителей, завоевав мировую популярность.',
            website: 'https://www.jacuzzi.ru'
        }
    },
    { 
        id: 31,
        url: 'technogym',
        name: 'Technogym', 
        parent: 'spa-and-wellness',
        content: {
            pics: [
                '../assets/img/pics/technogym/technogym_1.jpg',
                '../assets/img/pics/technogym/technogym_2.jpg',
                '../assets/img/pics/technogym/technogym_3.jpg',
                '../assets/img/pics/technogym/technogym_4.jpg',
                '../assets/img/pics/technogym/technogym_5.jpg',
                '../assets/img/pics/technogym/technogym_6.jpg',
                '../assets/img/pics/technogym/technogym_7.jpg',
                '../assets/img/pics/technogym/technogym_8.jpg',
                '../assets/img/pics/technogym/technogym_9.jpg',
                '../assets/img/pics/technogym/technogym_10.jpg',
            ],
            video: [
                '/assets/img/pics/technogym/technogym.mp4'
            ],
            title: 'Technogym - оборудование для тренажерного зала, фитнеса и домашних тренировок',
            about: 'Technogym известна во всем мире как «The Wellness Company» - компания, которая помогла превратить гедонистическую концепцию «фитнеса» в настоящий образ жизни: Wellness. «Велнес» образ жизни означает регулярную физическую активность, правильное питание и позитивный настрой.',
            website: 'https://www.technogym.com'
        }
    }
]