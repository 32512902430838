import { render, staticRenderFns } from "./VeluxLogo.vue?vue&type=template&id=01284c80&scoped=true&"
var script = {}
import style0 from "./VeluxLogo.vue?vue&type=style&index=0&id=01284c80&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01284c80",
  null
  
)

export default component.exports