export default [
    { 
        id: 251, 
        url: 'fontanagrande',
        name: 'Fontana Grande', 
        parent: 'building-materials',
        content: {
            pics: '',
            title: '',
            about: '',
            website: 'https://www.fontanagrande.it'
        }
    },
    { 
        id: 252, 
        url: 'mapei',
        name: 'Mapei', 
        parent: 'building-materials',
        content: {
            pics: '',
            title: 'Mapei – ценности из прошлого, которые актуальные и сегодня!',
            about: 'Благодаря скрупулезному труду, инновационным технологиям и современным новаторским решениям, компания превратилась в ключевого игрока с итальянскими корнями лидера на рынке строительных материалов. Залог успеха – безупречные материалы и забота об окружающей среде.',
            website: 'http://www.mapei.com'
        }
    },
    { 
        id: 253, 
        url: 'tegola',
        name: 'Tegola', 
        parent: 'building-materials',
        content: {
            pics: [
                '../assets/img/pics/tegola/tegola_1.jpg',
                '../assets/img/pics/tegola/tegola_2.jpg',
                '../assets/img/pics/tegola/tegola_3.jpg',
                '../assets/img/pics/tegola/tegola_4.jpg',
                '../assets/img/pics/tegola/tegola_5.jpg',
                '../assets/img/pics/tegola/tegola_6.jpg',
                '../assets/img/pics/tegola/tegola_7.jpg',
            ],
            title: 'TEGOLA – сочетание безупречного качества, надежности и изящества!',
            about: 'Битумная черепица данного бренда является примером инновационных технологий и традиционного итальянского мастерства. Многообразие цветов, изысканность форм, большой выбор конфигураций – у этой компании вы сможете найти идеальный кровельный материал для дома в любом стиле.',
            website: 'http://www.tegola.ru'
        }
    },
    { 
        id: 254, 
        url: 'profilpas',
        name: 'Profilpas', 
        parent: 'building-materials',
        content: {
            pics: [
                '../assets/img/pics/profilpas/profilpas_1.jpg',
                '../assets/img/pics/profilpas/profilpas_2.jpg',
                '../assets/img/pics/profilpas/profilpas_3.jpg',
                '../assets/img/pics/profilpas/profilpas_4.jpg',
                '../assets/img/pics/profilpas/profilpas_5.jpg',
                '../assets/img/pics/profilpas/profilpas_6.jpg',
            ],
            title: '',
            about: '',
            website: 'https://www.profilpas.com'
        }
    },
    {
        id: 255,
        url: 'progress',
        name: 'Progress Profiles',
        parent: 'building-materials',
        content: {
            pics: [
                '../assets/img/pics/progress/progress_1.jpg',
                '../assets/img/pics/progress/progress_2.jpg',
                '../assets/img/pics/progress/progress_3.jpg',
                '../assets/img/pics/progress/progress_4.jpg',
                '../assets/img/pics/progress/progress_5.jpg',
                '../assets/img/pics/progress/progress_6.jpg',
                '../assets/img/pics/progress/progress_7.jpg',
                '../assets/img/pics/progress/progress_8.jpg',
                '../assets/img/pics/progress/progress_9.jpg',
            ],
            title: '',
            about: '',
            website: 'https://www.progressprofiles.com'
        }
    }
]