export default [
    { 
        id: 176,
        url: 'rimadesio',
        name: 'Rimadesio', 
        parent: 'windows-doors',
        content: {
            pics: [
                '../assets/img/pics/rimadesio/rimadesio_1.jpg',
                '../assets/img/pics/rimadesio/rimadesio_2.jpg',
                '../assets/img/pics/rimadesio/rimadesio_3.jpg',
                '../assets/img/pics/rimadesio/rimadesio_4.jpg',
                '../assets/img/pics/rimadesio/rimadesio_5.jpg',
                '../assets/img/pics/rimadesio/rimadesio_6.jpg',
                '../assets/img/pics/rimadesio/rimadesio_7.jpg',
                '../assets/img/pics/rimadesio/rimadesio_8.jpg',
                '../assets/img/pics/rimadesio/rimadesio_9.jpg',
            ],
            video: [
                '/assets/img/pics/rimadesio/rimadesio.mp4'
            ],
            title: 'Образ и стиль, выражение сущности Rimadesio',
            about: 'Технологические инновации, стилистические исследования и экологическое сознание - являются составляющими эксклюзивного видения Rimadesio, воплощенного в предложении меблировки и функционального разделения помещений: модульные системы для гостиной, книжные шкафы, раздвижные двери, гардеробные и богатая коллекция аксессуаров.',
            website: 'https://www.rimadesio.it'
        }
    },
    { 
        id: 177,
        url: 'adlporte',
        name: 'ADL Porte', 
        parent: 'windows-doors',
        content: {
            pics: [
                '../assets/img/pics/adlporte/adlporte_1.jpg',
                '../assets/img/pics/adlporte/adlporte_2.jpg',
                '../assets/img/pics/adlporte/adlporte_3.jpg',
                '../assets/img/pics/adlporte/adlporte_4.jpg',
                '../assets/img/pics/adlporte/adlporte_5.jpg',
                '../assets/img/pics/adlporte/adlporte_6.jpg',
            ],
            title: 'ADL Porte – уникальные архитектурные конструкции внутри жилых помещений!',
            about: 'Умение гармонично соединить высокие технологии с эстетическими качествами, которое соединилось с профессиональным опытом, позволило создать коллекцию уникальных дверей, эффектно выделяющихся на фоне других. Они не разделяют пространство, а органично дополняют, становясь его неотъемлемой частью.',
            website: 'http://www.adielleporte.it'
        }
    },
    { 
        id: 178,
        url: 'venusdesign',
        name: 'Venus design', 
        parent: 'windows-doors',
        content: {
            pics: [
                '../assets/img/pics/venusdesign/venusdesign_1.jpg',
                '../assets/img/pics/venusdesign/venusdesign_2.jpg',
                '../assets/img/pics/venusdesign/venusdesign_3.jpg',
                '../assets/img/pics/venusdesign/venusdesign_4.jpg',
                '../assets/img/pics/venusdesign/venusdesign_5.jpg',
            ],
            title: 'Venus design – оптимальное и практичное решение для интерьера!',
            about: 'Горизонтальные модели жалюзей Venus были разработаны специально для современных окон. Они отличаются особым дизайном и монтируются отдельно на каждую створку или проем. Новое направление – системы из бамбуковых или деревянных ламелей, которые привнесут в любой интерьер атмосферу респектабельности и солидности.',
            website: 'https://www.venusdesign.eu'
        }
    },
    { 
        id: 179,
        url: 'barausse',
        name: 'Barausse', 
        parent: 'windows-doors',
        content: {
            pics: [
                '../assets/img/pics/barausse/barausse_1.jpg',
                '../assets/img/pics/barausse/barausse_2.jpg',
            ],
            title: 'Barausse - классические двери для помещений современного стиля',
            about: 'Все решения для разделения пространств от Barausse вы можете создавать дизайн проекты, в которых эстетика, комфорт и функциональность сосуществуют в идеальном балансе.',
            website: 'https://www.barausse.com'
        }
    },
    { 
        id: 180,
        url: 'fasserramenti',
        name: 'FAS Serramenti', 
        parent: 'windows-doors',
        content: {
            pics: [
                '../assets/img/pics/fasserramenti/fasserramenti_1.jpg',
                '../assets/img/pics/fasserramenti/fasserramenti_2.jpg',
                '../assets/img/pics/fasserramenti/fasserramenti_3.jpg',
                '../assets/img/pics/fasserramenti/fasserramenti_4.jpg',
                '../assets/img/pics/fasserramenti/fasserramenti_5.jpg',
            ],
            title: 'FAS Serramenti – элитные деревянные окна из Италии!',
            about: 'Компания занимается производством качественных окон уже больше 60-ти лет и прекрасно знает, какими потрясающими свойствами обладает натуральное дерево. Предлагаемая продукция – это уникальное сочетание итальянского дизайна, безукоризненного качества и долговечности, наряду с индивидуальностью.',
            website: 'https://www.fasserramenti.com'
        }
    },
    { 
        id: 181,
        url: 'velux',
        name: 'Velux', 
        parent: 'windows-doors',
        content: {
            pics: [
                '../assets/img/pics/velux/velux_1.jpg',
                '../assets/img/pics/velux/velux_2.jpg',
                '../assets/img/pics/velux/velux_3.jpg',
            ],
            title: 'Мансардные окна VELUX - больше дневного света в ваш дом',
            about: 'Мансардные окна VELUX уже более 80 лет приносят дневной свет и свежий воздух в дома по всему миру. Окна от VELUX помогают создавать яркие и энергоэффективные места для жизни, работы, учебы и игр.',
            website: 'https://www.velux.com'
        }
    },
    { 
        id: '',
        url: 'newdesignporte',
        name: 'New Design Porte', 
        parent: 'hidden',
        content: {
            pics: [
                '../assets/img/pics/newdesignporte/newdesignporte_1.jpg',
                '../assets/img/pics/newdesignporte/newdesignporte_2.jpg',
                '../assets/img/pics/newdesignporte/newdesignporte_3.jpg',
                '../assets/img/pics/newdesignporte/newdesignporte_4.jpg',
                '../assets/img/pics/newdesignporte/newdesignporte_5.jpg',
                '../assets/img/pics/newdesignporte/newdesignporte_6.jpg',
                '../assets/img/pics/newdesignporte/newdesignporte_7.jpg',
            ],
            title: 'New Design Porte – двери, стиль которых не подвластен времени!',
            about: 'Естественная фактура натуральной древесины, многообразие форм и дизайнов, неповторимые техники декорирования и интересные цветовые комбинации – эти факторы превращают изделия бренда NDP в яркий элемент интерьера. Эти элитные двери станут изысканным украшением вашего дома.',
            website: 'http://www.newdesignporte.com'
        }
    },
    { 
        id: 183,
        url: 'glassdesign',
        name: 'Glass Design', 
        parent: 'windows-doors',
        content: {
            pics: [
                '../assets/img/pics/glassdesign/glassdesign_1.jpg',
                '../assets/img/pics/glassdesign/glassdesign_2.jpg',
                '../assets/img/pics/glassdesign/glassdesign_3.jpg',
            ],
            title: '',
            about: '',
            website: 'https://www.glassdesign.it'
        }
    },
    { 
        id: 184,
        url: 'salicepaolo',
        name: 'Salice Paolo',
        parent: 'windows-doors',
        content: {
            pics: [
                '../assets/img/pics/salicepaolo/salicepaolo_1.jpg',
                '../assets/img/pics/salicepaolo/salicepaolo_2.jpg',
                '../assets/img/pics/salicepaolo/salicepaolo_3.jpg',
                '../assets/img/pics/salicepaolo/salicepaolo_4.jpg',
                '../assets/img/pics/salicepaolo/salicepaolo_5.jpg',
                '../assets/img/pics/salicepaolo/salicepaolo_6.jpg',
            ],
            title: '',
            about: '',
            website: 'https://www.salicepaolo.it'
        }
    }
]