<template>
  <div>
    <template v-if='items && items.length'>
      
      <template v-for='(item, index) in items'>
        <slot :item="item" :index="index">
          Element {{ index }}
        </slot>
      </template>

    </template>
    <template v-else>

      <slot name="empty">
        No brands...
      </slot>

    </template>
  </div>
</template>

<script>
export default {
  name: 'ListItems',
  props: {
    items: Array
  }
}
</script>
<style lang="scss" scoped>
.listbrands_title {
  margin-bottom: 20px;
  width: 75%;
}
</style>