export default [
    { 
        id: 226,
        url: 'jaga',
        name: 'Jaga Climate Design', 
        parent: 'heating-cooling-and-ventilation',
        content: {
            pics: [
                '../assets/img/pics/jaga/jaga_1.jpg',
                '../assets/img/pics/jaga/jaga_2.jpg',
                '../assets/img/pics/jaga/jaga_3.jpg',
                '../assets/img/pics/jaga/jaga_4.jpg',
            ],
            title: 'Jaga Climate Design – легкие и чуткие климатические системы!',
            about: 'Эволюция строительных технологий требует концептуально новых экологических решений для отопления, охлаждения и вентиляции. Конвекторы Jaga Climate Design  никогда не были обычными радиаторами. Продукция бренда – это результат стремления создать максимальный комфорт в помещениях, сохранить окружающую среду и должное внимание уделить эстетической стороне изделия.',
            website: 'https://www.jaga.co.uk'
        }
    },
    { 
        id: 227,
        url: 'zehnder',
        name: 'Zehnder', 
        parent: 'heating-cooling-and-ventilation',
        content: {
            pics: [
                '../assets/img/pics/zehnder/zehnder_1.jpg',
                '../assets/img/pics/zehnder/zehnder_2.jpg',
                '../assets/img/pics/zehnder/zehnder_3.jpg',
                '../assets/img/pics/zehnder/zehnder_4.jpg',
                '../assets/img/pics/zehnder/zehnder_5.jpg',
                '../assets/img/pics/zehnder/zehnder_6.jpg',
                '../assets/img/pics/zehnder/zehnder_7.jpg',
                '../assets/img/pics/zehnder/zehnder_8.jpg',
                '../assets/img/pics/zehnder/zehnder_9.jpg',
            ],
            title: 'Zehnder – всегда вокруг вас!',
            about: 'Продукция Zehnder предлагает самые инновационные и энергоэффективные решения для создания здорового микроклимата в помещениях. Изделия данного бренда помимо многофункциональных систем представляют собой еще и утонченные объекты дизайна, которые органично вписываются в интерьер и становятся неотъемлемой частью пространства.',
            website: 'https://www.zehndergroup.com'
        }
    }
]