<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve">
	<line class="hg hg_h hg_h1" x1="82.1" y1="87.7" x2="82.1" y2="237.7"/>
	<line class="hg hg_h hg_h2" x1="178.4" y1="87.7" x2="178.4" y2="237.7"/>
	<line class="hg hg_h hg_h3" x1="44.9" y1="191.2" x2="216.9" y2="191.2"/>
	<path class="hg hg_g" d="M236.9,82.8c-22.3-22.3-71.7-32.2-103.9,0c-32.5,32.5-34.2,77.9,0,112.2s101.9,24.7,121.9-26.9h-89.1"/>
	<path class="hg hg_circle" d="M51,51L51,51c-54.7,54.7-54.7,143.3,0,198l0,0c54.7,54.7,143.3,54.7,198,0l0,0c54.7-54.7,54.7-143.3,0-198l0,0
	C194.3-3.7,105.7-3.7,51,51z"/>
</svg>
</template>
<script>
export default {
    name: 'HgLogo'
}
</script>
<style>
.hg { 
	fill: none; 
	stroke:rgba(255,255,255,.75); 
	stroke-width: 4.5; 
	stroke-linecap: round; 
	stroke-linejoin: round; 
	stroke-miterlimit: 10;
}
</style>