<template>
  <div>
    <hermitage-logo id="logo_side" class="svg_items"></hermitage-logo>
    <!-- <div class="contact_email">
      <social-icons></social-icons>
      <div class="ui-text-regular">
        <a href="mailto:info@hermitage.tm">info@hermitage.tm</a>
      </div>
    </div> -->
  </div>
</template>

<script>
import HermitageLogo from '@/components/logo/HermitageLogo'
import SocialIcons from '@/components/SocialIcons'
export default {
  components: {
    HermitageLogo,
    SocialIcons
  }
}
</script>
<style>
#logo_side {
  position: fixed;
  opacity: 0;
  bottom: 100px;
  right: -50px;
  width: 202px;
  transform: rotateZ(-90deg);
  transition: .5s;
}
#logo_side.svg_animate {
	opacity: 1;
	transition: .5s;
}
.contact_email {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 40px 5%;
  width: 100%;
  background: rgba(0,0,0,.5);
}
.contact_email .social_icons {
  margin-right: 0;
  margin-bottom: 10px;
  justify-content: center;
}
.contact_email .social_icons a svg {
  fill: rgba(255,255,255,.25);
}
.contact_email a {
  font-size: 0.6rem;
  color: rgba(255,255,255,.25);
  flex-shrink: 0;
}
</style>
