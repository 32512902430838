<template>
    <div class="locale_changer">
        <div class="lang_item" v-for="(entry, i) in languages" :key="i" @click="changeLocale(entry.lang)">
            {{ entry.lang }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'locale-changer',
    data() {
        return {
            languages: [
                { flag: 'us', lang: 'en', title: 'English' },
                { flag: 'ru', lang: 'ru', title: 'Русский' }
            ]
        }
    },
    methods: {
        changeLocale(val) {
            this.$root.$i18n.locale = val;
        }
    }
}
</script>

<style scoped>
.locale_changer {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-right: 20px;
    z-index: 4;
}
.lang_item {
    cursor: pointer;
    padding: 10px;
    font-size: .5rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: 'Konkord-Retro';
}
</style>