export default [
    { 
        id: 276, 
        url: 'biokamino',
        name: 'Biokamino', 
        parent: 'fireplaces',
        content: {
            pics: [
                '../assets/img/pics/biokamino/biokamino_1.jpg',
                '../assets/img/pics/biokamino/biokamino_2.jpg',
                '../assets/img/pics/biokamino/biokamino_3.jpg',
                '../assets/img/pics/biokamino/biokamino_4.jpg',
                '../assets/img/pics/biokamino/biokamino_5.jpg',
                '../assets/img/pics/biokamino/biokamino_6.jpg',
                '../assets/img/pics/biokamino/biokamino_7.jpg',
            ],
            title: '',
            about: '',
            website: 'https://biokamino.com'
        }
    },
    { 
        id: 277, 
        url: 'dimplex',
        name: 'Dimplex', 
        parent: 'fireplaces',
        content: {
            pics: [
                '../assets/img/pics/dimplex/dimplex_1.jpg',
                '../assets/img/pics/dimplex/dimplex_2.jpg',
                '../assets/img/pics/dimplex/dimplex_3.jpg',
                '../assets/img/pics/dimplex/dimplex_4.jpg',
            ],
            title: '',
            about: '',
            website: 'https://www.dimplex.com'
        }
    }
]